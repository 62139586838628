import React from "react";
import { Translation } from "react-i18next";
import i18next from "i18next";
import {
  Section,
  Container,
  Loading,
  CenterName,
} from "./components/Components.js";
import { NavTop, NavBar } from "./components/Nav.js";
import { TopContent } from "./components/TopContent.js";
import { api_get, handleError } from "./API.js";
import PersonContext from "./PersonContext.js";
import { CardLink } from "./components/Components.js";

export class PageLog extends React.Component {
  static contextType = PersonContext;

  constructor(props) {
    super();
    this.state = {
      loading: true,
      log: false,
    };
  }

  componentDidMount() {
    document.title = i18next.t("Headline.Log") + " - " + i18next.t("SiteName");
    api_get(
      "/persons/" + this.context.person.personId.externalId + "/myactic/log"
    )
      .then((resp) => {
        this.setState({
          loading: false,
          log: Object.values(resp),
        });
      })
      .catch((resp) => {
        handleError(resp);
      });
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <React.Fragment>
            <NavTop />
            <TopContent className="color-light-sand" h2={t("Headline.Log")} />

            <Container className="wrapper-log">
              {this.state.loading ? (
                <div className="d-flex justify-content-center">
                  <Loading />
                </div>
              ) : null}

              {this.state.log && Array.isArray(this.state.log) ? (
                <React.Fragment>
                  {this.state.log.map((item, index) => {
                    return <LogItem item={item} key={index} />;
                  })}
                </React.Fragment>
              ) : null}
            </Container>

            <NavBar />
          </React.Fragment>
        )}
      </Translation>
    );
  }
}

const LogItem = (props) => {
  return (
    <Translation>
    {(t) => (
    <CardLink>
      <div>
        <p className="body-text-regular text-black d-block">
          <LogTitle item={props.item} />
        </p>
        <p className="body-text-small d-block mb-0">
          Gym
        </p>
        <p className="body-text-small d-block mb-0">
          <LogInfo item={props.item} />
        </p>
        <p className="body-text-small d-block mb-0">
        {t("Log.Points")}
        </p>
      </div>
      <div className="text-end">

          <p className="body-text text-black">{props.item.startDate}</p>
          <p className="body-text-small text-black mb-0">
          <CenterName centerId={props.item.centerId} />
        </p>
          <p className="body-text-small text-black mb-0">
            {props.item.startTime}
          </p>

        <p className="body-text-small mb-0 d-flex text-black justify-content-end mb-0">
          {props.item.points}
        </p>
      </div>
    </CardLink>
        )}
        </Translation>
  );
};

const LogInfo = (props) => {
  let item = props.item;
  if (typeof item.title !== "undefined" && item.title) {
    return item.title;
  } else if (item.name === "InviteOnce") {
    return "Bjud en vän en gång";
  } else if (item.name === "InviteWeek") {
    return "Bjud en vän en vecka";
  } else if (item.name === "InviteMonth") {
    return "Bjud en vän en månad";
  } else if (item.name === "BringFriend") {
    return "Ta med en vän";
  } else if (item.name === "achievement") {
    return "Achievements";
  } else if (item.name === "challenge") {
    return "Utmaning";
  } else if (item.name === "swimaward") {
    return "Simmärke";
  } else if (item.name === "share") {
    return "Delning";
  } else if (item.name === "invite") {
    return "Värvning av medlem";
  } else if (item.name === "invited") {
    return "Medlemskap via värvning";
  } else {
    return item.name;
  }
};

const LogTitle = (props) => {
  let item = props.item;

  if (item.type === "subscription-start") {
    return "Startbonus för medlemskap";
  } else if (item.type === "subscription") {
    return "Medlemskap";
  } else if (item.type === "checkin") {
    return "Träning hos Actic";
    //if (item.points === LOYALTY_POINTS_CHECKINS*2) {
    //  return 'Dubbla poäng';
    //}
  } else if (item.type === "participation") {
    return "Gruppträning";
    //if (item.points === LOYALTY_POINTS_PARTICIPATIONS*2) {
    //  return 'Dubbla poäng';
    //}
  } else if (
    item.type === "clipcard" ||
    item.type === "startpt" ||
    item.type === "pt"
  ) {
    return "PT-träning";
  } else if (item.name === "achievement") {
    return "Achievement";
  } else if (item.name === "course") {
    return "PTgrupp";
  } else if (item.type === "other") {
    return "Övrigt";
  } else {
    return "";
  }
};
