import React, { useState } from "react";
import { Translation } from "react-i18next";
import { Loading, CenterName } from "../Components.js";
import bookingRulesUrls from "../../config/bookingRulesUrls.json";
import { getDomainCountry } from "../../config.js";

export const ClassModal = (props) => {
  let [loading, setLoading] = useState(false);

  const createParticipation = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    props
      .createParticipation(props.item.bookingId)
      .then((resp) => {
        setLoading(false);
      })
      .catch((resp) => {
        setLoading(false);
      });
  };

  const cancelParticipation = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    props
      .cancelParticipation(props.participation)
      .then((resp) => {
        setLoading(false);
      })
      .catch((resp) => {
        setLoading(false);
      });
  };

  let classStart = new Date(props.item.date + " " + props.item.startTime);
  let now = new Date();
  let classStartTimeHasPassed = classStart < now;
  let bookingState =
    typeof props.item.bookingState !== "undefined"
      ? props.item.bookingState
      : "";
  let isOutsideNoLimit =
    props.item.secondsRemainingToStart >
    props.booking_no_limit_hours_before_class * 3600
      ? true
      : false;
  let notBookableOutsideNoLimit =
    props.participations_count >= props.participations_max &&
    isOutsideNoLimit &&
    !props.participation
      ? true
      : false;

  let classCanBeBooked = true;
  if (bookingState === "NOT_BOOKABLE_OUTSIDE_HOURS") {
    classCanBeBooked = false;
  } else if (bookingState === "NOT_BOOKABLE_DATE") {
    classCanBeBooked = false;
  } else if (classStartTimeHasPassed) {
    classCanBeBooked = false;
  }

  if (
    isOutsideNoLimit &&
    props.participations_count >= props.participations_max
  ) {
    classCanBeBooked = false;
  }

  return (
    <Translation>
      {(t) => (
        <React.Fragment>
          <div className="top-content">
            <h1>{t("Headline.Booking")}</h1>
            <h2>{props.item.name}</h2>
          </div>
          <div className="modal-content">
            <h6>{t("Booking.Overiew")}</h6>

            <ul className="list small">
              <li>
                <div>
                  <div className="text-start">
                    <span>{t("Booking.Class")}</span>
                  </div>
                  <div className="text-end">
                    <span className="color-rock">{props.item.name}</span>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div className="text-start">
                    <span>{t("Booking.CenterName")}</span>
                  </div>
                  <div className="text-end">
                    <span className="color-rock">
                      <CenterName centerId={props.item.centerId} />
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div className="text-start">
                    <span>{t("Booking.Date")}</span>
                  </div>
                  <div className="text-end">
                    <span className="color-rock">{props.item.date}</span>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div className="text-start">
                    <span>{t("Booking.Time")}</span>
                  </div>
                  <div className="text-end">
                    <span className="color-rock">
                      {props.item.startTime} - {props.item.endTime}
                    </span>
                  </div>
                </div>
              </li>

              <li>
                <div>
                  <div className="text-start">
                    <span>{t("Booking.Room")}</span>
                  </div>
                  <div className="text-end">
                    <span className="color-rock">{props.item.roomNames}</span>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div className="text-start">
                    <span>{t("Booking.Instructor")}</span>
                  </div>
                  <div className="text-end">
                    <span className="color-rock">
                      {props.item.instructorNames}
                    </span>
                  </div>
                </div>
              </li>
              {props.participation ? (
                <li>
                  <div>
                    <div className="text-start">
                      <span>
                        {props.participation.waitingListIndex > 0
                          ? t("Booking.Spot")
                          : t("Booking.YourBooking")}
                      </span>
                    </div>
                    <div className="text-end">
                      <span className="color-rock">
                        {props.participation.waitingListIndex > 0
                          ? props.participation.waitingListIndex
                          : t("Booking.Booked")}
                      </span>
                    </div>
                  </div>
                </li>
              ) : (
                <li>
                  <div>
                    <div className="text-start">
                      <span>{t("Booking.NumBooked")}</span>
                    </div>
                    <div className="text-end">
                      <span className="color-rock">
                        {props.item.bookedCount +
                          " / " +
                          props.item.classCapacity}
                      </span>
                    </div>
                  </div>
                </li>
              )}
              {props.item.waitingListCount && !props.participation ? (
                <li>
                  <div>
                    <div className="text-start">
                      <span>{t("Booking.BookingStatus")}</span>
                    </div>
                    <div className="text-end">
                      <span className="color-rock">
                        {props.t("Booking.Queue")}:{" "}
                        {props.item.waitingListCount}
                      </span>
                    </div>
                  </div>
                </li>
              ) : null}
              {props.item.limitedBookingFull && !props.participation ? (
                <li>
                  <div>
                    <div className="text-start">
                      <span>{t("Booking.BookingStatus")}</span>
                    </div>
                    <div className="text-end">
                      <span className="color-rock">
                        {props.t("Booking.PrebookingFullLong")}
                      </span>
                    </div>
                  </div>
                </li>
              ) : null}
            </ul>

            {!props.participation &&
            bookingState === "NOT_BOOKABLE_OUTSIDE_HOURS" ? (
              <div className="holder-booking-text">
                <div className="icon-booking closed"></div>
                <h5 className="mb-0">{props.t("Booking.BookingClosed")}</h5>
              </div>
            ) : null}

            {/*
                            !props.participation && bookingState === 'NOT_BOOKABLE_DATE'
                            ? (
                            <div className="holder-booking-text">
                                <div className="icon-booking locked"></div>
                                <h5 className="mb-0">{props.t("Booking.PreBookingInfo")}</h5>
                            </div>
                            ) : null
                        */}

            {/*props.item.limitedBookingFull &&
                        !props.participation ? (
                            <div className="d-flex justify-content-center mt-small">
                                <div className="col-10">
                                    <span className="body-text-small color-rock">
                                        {props.t("Booking.LimitedBookingInfo", {
                                            booking_limited_days:
                                                props.booking_limited_days,
                                            booking_limited_quota:
                                                props.booking_limited_quota,
                                            max_days_vip: props.max_days_vip,
                                            max_num_vip: props.max_num_vip,
                                        })}
                                    </span>
                                </div>
                            </div>
                            ) : null*/}

            {notBookableOutsideNoLimit && !props.participation ? (
              <div className="holder-booking-text">
                <div className="icon-booking"></div>
                <p className="mb-0">{props.t("Booking.IsOutsideNoLimit")}</p>
                <p className="mb-4">
                  <a
                    href={bookingRulesUrls[getDomainCountry()]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.t("Booking.IsOutsideNoLimitReadMore")}
                  </a>
                </p>
              </div>
            ) : null}

            {!classStartTimeHasPassed ? (
              <div className="text-center mt-small mb-small">
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <Loading />
                  </div>
                ) : (
                  <BookingButtons
                    {...props}
                    classCanBeBooked={classCanBeBooked}
                    createParticipation={createParticipation}
                    cancelParticipation={cancelParticipation}
                    t={t}
                  />
                )}
              </div>
            ) : null}

            <h6>{t("Booking.Description")}</h6>

            <div className="d-flex justify-content-center mt-4">
              <div className="col-12">
                <p>{props.item.description}</p>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </Translation>
  );
};

const BookingButtons = (props) => {
  if (props.item.limitedBookingFull) {
    return null;
  }
  if (props.participation) {
    return (
      <button
        className="btn secondary-btn color-secondary"
        onClick={props.cancelParticipation}
      >
        {props.t("Booking.CancelTheClass")}
      </button>
    );
  } else if (props.classCanBeBooked) {
    return (
      <button
        className="btn secondary-btn color-secondary"
        onClick={props.createParticipation}
      >
        {props.item.bookedCount >= props.item.classCapacity
          ? props.t("Booking.BookTheClassQueued")
          : props.t("Booking.BookTheClass")}
      </button>
    );
  } else {
    return null;
  }
};

/*
const YourBooking = (props) => {
  if (props.participation) {
    if (props.participation.waitingListIndex > 0) {
      return (
          <p>{props.t("Booking.Spot")}: {props.participation.waitingListIndex}</p>
      );
    } else {
      return (
          <p>{props.t("Booking.Booked")}</p>
      );
    }
  } else {
    if (props.item.waitingListCount) {
      return (
          <p>{props.t("Booking.Queue")}: {props.item.waitingListCount}</p>
      );
    } else {
      if (props.item.limitedBookingFull) {
        return (
            <p>{props.t("Booking.PrebookingFull")}</p>
        );
      } else {
        return (
            <p>{props.t("Booking.Book")}</p>
        );
      }
    }
  }
};
*/
