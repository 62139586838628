import React from 'react'
const PersonContext = React.createContext({
    accessToken: '',
    countrycode: 'SE',
    person: {},
    personCommunication: {},
    center: {},
    hasMyactic: false,
    myactic: false,
    achievements: false,
    achievementsCompleted: false,
    achievementsCategories: false,
    loggedIn: false,
    currentlyOpenModal: false,
    displayMyActicNavItem: true,
    displayScheduleNavItem: true,
    nomenu: false,
    openModal: () => {},
    signOut: () => {},
    setSubscriptions: () => {},
    setMyActic: () => {},
    setAchievements: () => {},
    setPersonData: () => {},
    getMyActic: () => {}
})
export default PersonContext;