import React, { useState, useContext, useEffect, useRef } from "react";
import { Translation } from "react-i18next";
import { api_get, handleError } from "../../API.js";
import PersonContext from "../../PersonContext.js";
import bookingSliderSettings from "../../config/bookingSliderSettings.json";
import {Loading} from "../Components.js";
import { DayParticipations } from './DayParticipations.js';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const MyParticipations = (props) => {

  let context = useContext(PersonContext);
  let [isLoading, setLoading] = useState(true);
  let [classes, setClasses] = useState(false);
  let prevClassesUpdated = usePrevious(props.classesUpdated);
  
  useEffect(() => {
    setLoading(true);
    
    if (typeof prevClassesUpdated === 'undefined' || prevClassesUpdated !== props.classesUpdated) {

      api_get('/persons/' + context.person.personId.externalId + '/participations/classes')
        .then((participations) => {
          let classesWithParticipations = {};
          for (let participation of Object.values(participations)) {

            if (typeof classesWithParticipations[participation.booking.date] === 'undefined') {
              classesWithParticipations[participation.booking.date] = [];
            }

            let classdata = participation.classdata;
            // delete participation.classdata;
            classdata.participation = participation;
            classesWithParticipations[participation.booking.date].push(classdata);
          }
          setClasses(classesWithParticipations);
          setLoading(false);
        })
        .catch((resp) => {
          handleError(resp);
        });

    }

  }, [props.classesUpdated]); // eslint-disable-line
  // , context.person.personId.externalId,  prevClassesUpdated

  const cancelParticipation = (participation) => {
    return new Promise((resolve, reject) => {
      props.cancelParticipation(participation)
        .then(() => {
          for (let date in classes) {
            for (let i in classes[date]) {
              if (participation.bookingId.id === classes[date][i].bookingId.id
                  && participation.bookingId.center === classes[date][i].bookingId.center) {
                    classes[date].splice(i, 1);
                }
            }
          }
          setClasses(classes);
          resolve();
        })
        .catch(() => {
          reject();
        })
    })
  }

  return (
    <Translation>
      {(t) => (
        isLoading
        ? (
          <div className="d-flex justify-content-center mt-4"><Loading /></div> 
        ) : (
          
          Object.keys(classes).length ? (
            <div className="schedule my-bookings mb-4">
              <Slider {...bookingSliderSettings}>
                {Object.keys(classes).map((date, daynum) => {
                  return (
                    <DayParticipations 
                      classes={classes}
                      date={date} 
                      daynum={daynum} 
                      key={daynum} 
                      cancelParticipation={cancelParticipation} 
                      />
                  );
                })}
              </Slider>
            </div>
          ) : (
            <div className="d-flex justify-content-center mt-4"><p>{t('Booking.NoClassesBooked')}</p></div>
          )

        )
      )}
    </Translation>
  );
};
