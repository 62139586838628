import React from 'react';
import { Translation } from 'react-i18next';
import { ClassCard } from './ClassCard.js';

export const Day = (props) => {
  let currentDayStartTime = props.startDay + 86400 * props.daynum;
  let date = new Date(currentDayStartTime * 1000);
  let dateIso = date.toISOString().split("T")[0];
  let hasClasses = (typeof props.classes[dateIso] !== 'undefined' && props.classes[dateIso].length) ? true : false;


  if (hasClasses) {
    props.classes[dateIso].sort((a, b) => {
        let sortValueA = a.timestamp + a.name;
        let sortValueB = b.timestamp + b.name;
        return sortValueA > sortValueB ? 1 : -1;
      }
    );
  }
  

  return (
    <Translation>
      {(t) => {
        return (
          <div className="day-container">
            <div className="day-header">
              <span className="mb-0 d-block h4 color-black">{t('DayNames.' + date.getDay())}</span>
              <span className="caption-text-regular d-block color-rock mb-2">
                {date.getDate()} {t('MonthNames.' + date.getMonth())}
              </span>
            </div>
            {
            hasClasses
            ? props.classes[dateIso].map((item, index) => {
                if (props.filterName && props.filterName !== item.name ) {
                  return null;
                }
                if (props.filterInstructor && props.filterInstructor !== item.instructorNames ) {
                  return null;
                }
                if (props.filterRoom) {
                  if (item.roomNames.indexOf(props.filterRoom) >= 0) {
                    return null;
                  }
                }

                if (item.roomNames !== 'Outdoor Box' && typeof props.centersSelected[item.centerId] === 'undefined') {
                  return null; //
                }
                //if (item.roomNames === 'Outdoor Box' && typeof props.centersSelected[item.centerId + 'outdoor'] === 'undefined') {
                //  return null; // remove box classes from regular center
                //}

                return <ClassCard {...props} item={item} key={index} t={t} />;
              })
            : <div>{/*t("Booking.NoClassesDay")*/}</div>
          }
          </div>
        );
      }}
    </Translation>
  );
};
