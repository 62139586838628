import React from 'react';
import { Translation } from 'react-i18next';
import i18next from 'i18next';
import { TopContent } from './components/TopContent.js';
import { NavTop, NavBar } from './components/Nav.js';
import { Section, Container, Loading } from './components/Components.js';
import { Dropdown } from './components/Dropdown.js';
import PersonContext from './PersonContext.js';
import { api_get, api_post, api_delete, handleError } from './API.js';
import { Day } from './components/booking/Day.js';
import bookingSliderSettings from "./config/bookingSliderSettings.json";
//import { DayParticipations } from './components/booking/DayParticipations.js';
import { MyParticipations } from './components/booking/MyParticipations.js';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
export class PageBooking extends React.Component {
  static contextType = PersonContext;

  centersAll = [];
  numberOfClasses = 0;
  classList = [];
  instructorList = [];
  localStorageKey = '';

  constructor(props) {
    super();

    this.state = {
      closeDropdown: 0,
      filterCenter: '',
      filterInstructor: false,
      filterName: false,
      centers: [],
      centersSelected: {},
      from: '',
      to: '',
      loading: true,
      classes: {},
      participations: {},
      booking_no_limit_hours_before_class: 36,
      booking_starts_days_before: 10,
      participations_count: 0,
      participations_max: 0,
      updated: 0,
      booking_limited_days: '',
      booking_limited_quota: '',
      max_days_vip: '',
      max_num_vip: '',
    };
  }

  componentDidMount() {

    document.title = i18next.t("Headline.Booking") + ' - ' + i18next.t("SiteName");
    if (typeof this.context.person.personId === 'undefined') {
      return;
    }

    this.localStorageKey = "centersSelected"+this.context.person.personId.externalId;

    this.getMyActicThenClasses();
  }

  getMyActicThenClasses() {

    this.context.getMyActic()
      .then((loadingDone) => { // done
        if (loadingDone) {

          let centersSelected = this.getDefaultSelectedCenters();

          this.setState({ 
            centersSelected: centersSelected
           }, () => {
            this.getClassesAndParticipations()
              .then(() => {
                this.popuplateCenterList();
              });
          });

        } else {
          setTimeout(() => { 
            this.getMyActicThenClasses();
          }, 2000);
        }
      })
      .catch(() => { });
  }

  getDefaultSelectedCenters() {
    let centersSelected = false;

    let savedSelectedCenters = [];
    let savedSelectedCentersData = localStorage.getItem(this.localStorageKey);
    if (savedSelectedCentersData && typeof savedSelectedCentersData === 'string') {
      savedSelectedCenters = JSON.parse(savedSelectedCentersData);
    }
    
    if (Object.keys(savedSelectedCenters).length > 0) {
      centersSelected = {}
      for (let centerId in savedSelectedCenters) {
        centersSelected[centerId] = savedSelectedCenters[centerId];
      }
      return centersSelected;
    }

    if (this.context.subscription && this.context.subscription.subscriptionType === 'outdoor') {
      if (this.context.center.has_outdoor_room) { 
        // If this member has outdoor subscription and the center has outdoor room
        centersSelected = {}
        centersSelected[this.context.center.centerId+'outdoor'] = JSON.parse(JSON.stringify(this.context.center));
        centersSelected[this.context.center.centerId+'outdoor'].filterRoom = '';
        centersSelected[this.context.center.centerId+'outdoor'].name = centersSelected[this.context.center.centerId+'outdoor'].name + ' ' + i18next.t("OutdoorBox");
      }
    } else if (!this.context.subscription) {
      // If no subscription, look for possible clipcard
      if (this.context.clipcards) {
        let hasOutdoorClipcard = this.context.clipcards.find((clipcard) => { 
          let name = clipcard.name.toLowerCase();
          return (name.indexOf("outdoor") || name.indexOf("box")) ? true : false;
        });
        if (hasOutdoorClipcard && this.context.center.has_outdoor_room) { 
          // If this member has outdoor clipcard and the center has outdoor room
          centersSelected = {}
          centersSelected[this.context.center.centerId+'outdoor'] = JSON.parse(JSON.stringify(this.context.center));
          centersSelected[this.context.center.centerId+'outdoor'].filterRoom = '';
          centersSelected[this.context.center.centerId+'outdoor'].name = centersSelected[this.context.center.centerId+'outdoor'].name + ' ' + i18next.t("OutdoorBox");
        }
      }
    }

    if (!centersSelected) {
      // If no special center selected, use regular homegym
      centersSelected = {}
      centersSelected[this.context.center.centerId] = JSON.parse(JSON.stringify(this.context.center));
      centersSelected[this.context.center.centerId].filterRoom = '';
    }

    return centersSelected;
  }

  componentDidUpdate() {
    //if (this.centersAll.length !== this.context.centers.length) {
      //this.popuplateCenterList();
    //}
  }

  popuplateCenterList() {
    //console.log("popuplateCenterList", this.context.centers);
    this.centersAll = this.context.centers;

    let centers = [];

    for (let i in this.context.centers) {
      let center = JSON.parse(JSON.stringify(this.context.centers[i]));
      center.filterRoom = '';
      centers.push(center);

      if (this.context.centers[i].has_outdoor_room) {
        let outdoorcenter = JSON.parse(JSON.stringify(this.context.centers[i]));
        outdoorcenter.filterRoom = 'Outdoor Box';
        outdoorcenter.name = center.name + ' ' + i18next.t("OutdoorBox");
        centers.push(outdoorcenter);
      }
    }

    this.setState({centers});
  }

  getClassesAndParticipations = () => {
    return new Promise((resolve, reject) => {
      this.setState({loading: true, classes: {}});
      this.numberOfClasses = 0;

      let centerArray = [];
      if (Object.keys(this.state.centersSelected).length) {
        for (let c of Object.values(this.state.centersSelected)) {
          centerArray.push(c.centerId);
        }
      }
      centerArray = [...new Set(centerArray)]; // Get unique

      api_get('/persons/' + this.context.person.personId.externalId + '/centers/' + centerArray.join(",") + '/classes')
        .then((resp) => {
          //console.log('classes', resp.classes);

          this.classList = [];
          this.instructorList = [];
          for (let day of Object.values(resp.classes)) {
            for (let c of day) {
              this.numberOfClasses++;
              if (!this.classList.includes(c.name)) {
                this.classList.push(c.name)
              }
              if (!this.instructorList.includes(c.instructorNames)) {
                this.instructorList.push(c.instructorNames)
              }
            }
          }

          this.setState({
            from: new Date(resp.from),
            to: new Date(resp.to),
            booking_no_limit_hours_before_class: resp.booking_no_limit_hours_before_class,
            booking_starts_days_before: resp.booking_starts_days_before,
            booking_limited_days: resp.booking_limited_days,
            booking_limited_quota: resp.booking_limited_quota,
            participations_count: resp.participations_count,
            participations_max: resp.participations_max,
            max_days_vip: resp.max_days_vip,
            max_num_vip: resp.max_num_vip,
            classes: resp.classes,
            //filterRoom: filterRoom,
            loading: false
          });
          resolve();
        })
        .catch((resp) => {
          handleError(resp);
          resolve();
        });
    })
  }

  createParticipation = (bookingId) => {
    return new Promise((resolve, reject) => {
      //let current_participations_count = this.state.participations_count;
      let bookingIdCompound = bookingId.center + 'p' + bookingId.id;
      api_post('/persons/' + this.context.person.personId.externalId + '/participations/' + bookingIdCompound)
        .then((resp) => {
          
          if (typeof resp.participation !== 'undefined' && typeof resp.participation.booking !== 'undefined') {

            let classDate = resp.participation.booking.date; 
            this.setState((currentState) => {
                if (typeof currentState.classes[classDate] !==  'undefined') {
                  for (let i in currentState.classes[classDate]) {
                    if (currentState.classes[classDate][i].bookingIdCompound === bookingIdCompound) {
                      currentState.classes[classDate][i].participation = resp.participation;
                      break;
                    }
                  }
                }
                //console.log("Add to count", currentState.participations_count);
                currentState.participations_count = resp.participations_count;
                currentState.updated = currentState.updated+1;
                //console.log("New state", currentState);
                return currentState
              },
              () => {
                resolve(resp.participation);
              }
            );
          } else {
            reject();
          }
          
        })
        .catch((resp) => {
          handleError(resp);
          reject();
        });
    })
  }

  cancelParticipation = (participation) => {
    return new Promise((resolve, reject) => {
      //let current_participations_count = this.state.participations_count;
      let classDate = participation.booking.date;
      let bookingIdCompound = participation.booking.bookingId.center + 'p' + participation.booking.bookingId.id;
      let participationIdCompound = participation.participationId.center + 'p' + participation.participationId.id;
      api_delete('/persons/' + this.context.person.personId.externalId + '/participations/' + bookingIdCompound + '/' + participationIdCompound)
        .then((resp) => {
          if (resp.success) {
            this.setState((currentState) => {
              //console.log(currentState, classDate, bookingIdCompound);
              if (typeof currentState.classes[classDate] !==  'undefined') {
                for (let i in currentState.classes[classDate]) {
                  if (currentState.classes[classDate][i].bookingIdCompound === bookingIdCompound) {
                    delete currentState.classes[classDate][i].participation;
                    break;
                  }
                }
              }
              //console.log("Subtract from count", currentState.participations_count);
              currentState.participations_count = resp.participations_count;
              //currentState.participations_count = current_participations_count-1;
              currentState.updated = currentState.updated+1;
              //console.log("New state", currentState);
              return currentState;
            });
          }
          resolve();
        })
        .catch((resp) => {
          handleError(resp);
          reject();
        });
    })
  }

  handleFilterCenter = (e) => {
    this.setState({filterCenter: e.target.value});
  }

  selectCenter = (center) => {
    let centersSelected = this.state.centersSelected;
    let selectedKey = center.centerId + (center.filterRoom ? center.filterRoom.split(" ")[0].toLowerCase() : '');
    if (typeof centersSelected[selectedKey] !== 'undefined') {
      delete centersSelected[selectedKey];      
    } else {
      centersSelected[selectedKey] = center;
    }
    
    this.setState({
        centersSelected: centersSelected, 
        closeDropdown: this.state.closeDropdown+1,
        filterCenter: ""
      }, () => {
        localStorage.setItem(this.localStorageKey, JSON.stringify(centersSelected));
        this.getClassesAndParticipations();
    });
  }

  getClassesWithParticipations = () => {
    let classes = {};
    for (let date in this.state.classes) {
      let myClasses = this.state.classes[date].filter((r) => {
        return typeof r.participation !== 'undefined' && r.participation !== false ? true : false;
      });
      if (myClasses.length) {
        classes[date] = myClasses;
      }
    }
    return classes;
  }

  render() {
    //console.log('classes', this.state.classes);
    //console.log(this.state.centersSelected);
    let days = [ ...Array(21).keys() ];
    let startDay = this.state.from ? this.state.from.getTime() / 1000 : 0;
    
    let centersSelectedLabel = '';
    if (Object.keys(this.state.centersSelected).length) {
      let i = 0;
      for (let key of Object.keys(this.state.centersSelected)) {
        if (i>0) centersSelectedLabel += ', ';
        centersSelectedLabel += this.state.centersSelected[key].name;
        i++;
      }
    } else {
      centersSelectedLabel = 'Välj gym'
    }

    //let centersSelectedKeys = Object.values(this.state.centersSelected).map((center) => { return center.centerId + center.filterRoom; });
    
    let centersForList = this.state.centers;
    centersForList.sort((a, b) => {
      if (typeof this.state.centersSelected[a.centerId + (a.filterRoom ? a.filterRoom.split(" ")[0].toLowerCase() : '')] !== 'undefined') {
        return -1;
      } else {
        return 0;
      }
    });

    //let classesWithParticipations = this.getClassesWithParticipations();
    //console.log("participations_count", this.state.participations_count);

    return (
      <Translation>
        {(t) => (
          <React.Fragment>
            <NavTop />
            <TopContent className="color-light-sand" h1={t('Headline.Booking')} />

            <Section headline={
              t('Booking.MyBookings')
              + ' '
              + (this.state.participations_max>0 ? ' (' + this.state.participations_count + ')' : '') // /' + this.state.participations_max + '
              } open={false}>
              <Container col="col-12 col-md-10 col-xxl-8">
                {
                this.state.loading 
                ? <div className="d-flex justify-content-center mt-4"><Loading /></div> 
                : (
                  <MyParticipations  cancelParticipation={this.cancelParticipation} classesUpdated={this.state.updated} />
                  )
                }
              </Container>
            </Section>
              
              {/*}
                  Object.keys(classesWithParticipations).length ? (
                    <div className="schedule my-bookings mb-4">
                      <Slider ref={(slider) => (this.slider = slider)} {...bookingSliderSettings}>
                        {Object.keys(classesWithParticipations).map((date, daynum) => {
                          return (
                            <DayParticipations 
                              {...this.state} 
                              classes={classesWithParticipations}
                              date={date} 
                              daynum={daynum} 
                              key={daynum} 
                              createParticipation={this.createParticipation} 
                              cancelParticipation={this.cancelParticipation} 
                              />
                          );
                        })}
                      </Slider>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center mt-4"><p>{t('Booking.NoClassesBooked')}</p></div>
                  )
              {*/}


  
              <Container col="col-12 col-md-10 col-xxl-8">
                <h2>{t('Booking.Headline')}</h2>
                <Dropdown closeDropdown={this.state.closeDropdown} label={centersSelectedLabel} onOpen={() => { document.getElementById("filterCenterInput").focus(); }} onClose={() => {
                  this.setState({filterCenter: ""});
                }}>
                  <ul>
                    <li className="readonly"><input id="filterCenterInput" type="text" value={this.state.filterCenter} onChange={this.handleFilterCenter} placeholder={t('Booking.FilterCenter')} /></li>
                    {this.state.centers.map((center) => {
                      //console.log(center.name, this.state.filterCenter, center.name.indexOf(this.state.filterCenter));
                      if (this.state.filterCenter && center.name.toLowerCase().indexOf(this.state.filterCenter.toLowerCase()) < 0) {
                        return null;
                      }
                    
                      let selectedCenterKey = center.centerId + (center.filterRoom ? center.filterRoom.split(" ")[0].toLowerCase() : '');
                      let isSelected = (typeof this.state.centersSelected[selectedCenterKey] !== 'undefined') ? true : false;
                      return (
                      <li 
                        key={center.centerId + '-' + center.filterRoom.split(" ")[0].toLowerCase()} 
                        onClick={() => { this.selectCenter(center) }} 
                        className={
                          isSelected
                          ? 'selected' 
                          : ''
                        }>
                          <span>{center.name}</span>
                        </li>
                      )
                    })}
                  </ul>
                </Dropdown>
              </Container>

              {
                this.numberOfClasses
                ? (
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-6 col-md-5 col-xxl-4">
                        <Dropdown closeDropdown={this.state.closeDropdown} label={this.state.filterName ? this.state.filterName : t("Booking.ChooseClass")}>
                          <ul>
                            <li onClick={() => { this.setState({filterName: false, closeDropdown: this.state.closeDropdown+1}); } }>{t("Booking.ChooseClass")}</li>
                            {this.classList.map((name) => {
                              return <li key={name} onClick={() => { 
                                this.setState({filterName: name, closeDropdown: this.state.closeDropdown+1}) 
                              }} className={this.state.filterName === name ? 'selected' : ''}>{name}</li>
                            })}
                          </ul>
                        </Dropdown>
                      </div>
                      <div className="col-6 col-md-5 col-xxl-4">
                        <Dropdown closeDropdown={this.state.closeDropdown} label={this.state.filterInstructor ? this.state.filterInstructor : t("Booking.ChooseInstructor")}>
                          <ul>
                          <li onClick={() => { this.setState({filterInstructor: false, closeDropdown: this.state.closeDropdown+1}); } }>{t("Booking.ChooseInstructor")}</li>
                            {this.instructorList.map((instructorNames) => {
                              return <li key={instructorNames} onClick={() => { 
                                this.setState({filterInstructor: instructorNames, closeDropdown: this.state.closeDropdown+1}) 
                              }} className={this.state.filterInstructor === instructorNames ? 'selected' : ''}>{instructorNames}</li>
                            })}
                          </ul>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                )
                : null
              }
              


              <Container col="col-12 col-md-10 col-xxl-8">
                {
                this.state.loading 
                ? <div className="d-flex justify-content-center"><Loading /></div> 
                : (
                  Object.keys(this.state.classes).length ? (
                    <div className="schedule mb-4">
                      <Slider ref={(slider) => (this.slider = slider)} {...bookingSliderSettings}>
                        {days.map((daynum) => {
                          return (
                            <Day 
                              {...this.state} 
                              startDay={startDay} 
                              daynum={daynum} 
                              key={daynum} 
                              createParticipation={this.createParticipation} 
                              cancelParticipation={this.cancelParticipation} 
                              />
                          );
                        })}
                      </Slider>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center"><p>{t('Booking.NoClassesFound')}</p></div>
                  )
                )
                }
              </Container>
      
            <NavBar />
          </React.Fragment>
        )}
      </Translation>
    );
  }
}




