import React, { useState, useContext, useEffect } from "react";
import { MyActicBenefits } from "../components/myactic/MyActicBenefits.js";
import { Container } from "../components/Components.js";
import { TopContent } from "../components/TopContent.js";
import { Translation } from "react-i18next";
import { Confirm } from "../components/Confirm.js";
import PersonContext from "../PersonContext.js";
import { NavTop } from "../components/Nav.js";
import Breadcrumbs from "../components/Breadcrumb.js";
import i18next from "i18next";
export const PageMyActicBenefits = (props) => {
    let personContext = useContext(PersonContext);
    let [loadingMyActic, setLoadingMyActic] = useState(true);


  useEffect(() => {
    document.title =
      i18next.t("Headline.Settings") + " - " + i18next.t("SiteName");
    if (personContext.myactic) {
      setLoadingMyActic(false);
    } else {
      getMyActic();
    }
  }, []); // eslint-disable-line


  const getMyActic = () => {
    personContext
      .getMyActic()
      .then((loadingDone) => {
        // done
        if (loadingDone) {
          setLoadingMyActic(false);
        } else {
          setTimeout(() => {
            getMyActic();
          }, 2000);
        }
      })
      .catch(() => {
        // error
        Confirm({
          message: i18next.t("Errors.FetchMyActicErrorRetry"),
          onConfirm: () => {
            setLoadingMyActic(true);
            getMyActic();
          },
        });
        setLoadingMyActic(false);
      });
  };


  return (
    <Translation>
      {(t) => (
        <>
          <NavTop />
          <Breadcrumbs/>
          <TopContent h2="Medlemsförmåner" />
          <Container><MyActicBenefits loadingMyActic={loadingMyActic} /></Container>
         
        </>
      )}
    </Translation>
  );
};
