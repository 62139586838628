import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { api_post } from '../../API.js';
import { Loading } from '../Components.js';

export const AdminLogin = (props) => {

    let params = useParams();

    useEffect(() => {
      if (params.authcode) {
        api_post("/adminlogin/" + params.authcode)
          .then((resp) => {
            if (resp.success) {
              props.handleSignInSuccess(resp);
              props.redirect("/medlemskap");
            } else {
  
            }
          })
          .catch(() => { });
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
    return (
      <div className="d-flex justify-content-center mt-5">
        <Loading />
      </div>
    );
  }