import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Translation } from "react-i18next";
//import i18next from "i18next";
import PersonContext from "../PersonContext.js";
import { getSupportURL, getDomainCountry } from "../config.js";
import { ReactComponent as NavLogo } from "../assets/icons/logo-black.svg";

const NavLinks = () => {
  let context = useContext(PersonContext);

  let translations = {
    booking: {
      SE: "/boka-pass",
      NO: "/book-time",
    },
    statistics: {
      SE: "/statistik",
      NO: "/statistikk",
    },
  };

  let lang = getDomainCountry();
  return (
    <Translation>
      {(t) => (
        <React.Fragment>
          {context.displayMyActicNavItem ? (
            <Link
              to="/myactic"
              className={
                "barmenu-myactic" +
                (window.location.pathname === "/myactic" ||
                window.location.pathname === "/aktivitetslogg"
                  ? " active"
                  : "")
              }
            >
              <span>{t("Menu.MyActic")}</span>
            </Link>
          ) : null}

          {context.displayScheduleNavItem ? (
            <Link
              to={translations["booking"][lang]}
              className={
                "barmenu-booking" +
                (window.location.pathname === "/boka-pass" ? " active" : "")
              }
            >
              <span>{t("Menu.Booking")}</span>
            </Link>
          ) : (
            <div className="barmenu-booking inactive">
              <span>{t("Menu.Booking")}</span>
            </div>
          )}

          <Link
            to={translations["statistics"][lang]}
            className={
              "barmenu-statistics" +
              (window.location.pathname === "/statistik" ? " active" : "")
            }
          >
            <span>{t("Menu.Statistics")}</span>
          </Link>

          <Link
            to="/medlemskap"
            className={
              "barmenu-membership" +
              (window.location.pathname === "/medlemskap" ? " active" : "")
            }
          >
            <span>{t("Menu.Membership")}</span>
          </Link>

          <a
            href={getSupportURL()}
            target="_blank"
            rel="noreferrer"
            className="barmenu-support"
          >
            <span
              className="d-none d-xxl-block"
              dangerouslySetInnerHTML={{ __html: t("Menu.SupportDesktop") }}
            ></span>
            <span
              className="d-block d-xxl-none"
              dangerouslySetInnerHTML={{ __html: t("Menu.SupportMobile") }}
            ></span>
          </a>
        </React.Fragment>
      )}
    </Translation>
  );
};

export const NavBar = (props) => {
  const search = useLocation().search;
  const nomenu = new URLSearchParams(search).get("nomenu");
  if (typeof nomenu !== "undefined" && nomenu) {
    return null;
  }
  return (
    <React.Fragment>
      <footer></footer>
      <div className="barmenu">
        <NavLinks />
      </div>
    </React.Fragment>
  );
};

export const MenuItem = (props) => {
  return (
    <li className="menu-item menu-item-type-custom menu-item-object-custom">
      {props.children}
    </li>
  );
};

export const Avatar = () => {
  const context = useContext(PersonContext);

  // Get the first letter of first name and last name
  const firstNameInitial = context.person?.firstName
    ? context.person.firstName.charAt(0)
    : "";
  const lastNameInitial = context.person?.lastName
    ? context.person.lastName.charAt(0)
    : "";

  return context.loggedIn ? (
    <div className="avatar order-lg-2 ms-lg-auto me-lg-4">
      <span className="avatar-text">
        {firstNameInitial}
        {lastNameInitial}
      </span>
    </div>
  ) : null;
};

export const NavTop = (props) => {
  let context = useContext(PersonContext);
  let domainCountry = getDomainCountry();
  let [isOpen, setIsOpen] = useState(false);

  const search = useLocation().search;
  const nomenu = new URLSearchParams(search).get("nomenu");
  if (typeof nomenu !== "undefined" && nomenu) {
    return null;
  }

  let translations = {
    booking: {
      SE: "/boka-pass",
      NO: "/book-time",
    },
    statistics: {
      SE: "/statistik",
      NO: "/statistikk",
    },
    log: {
      SE: "/aktivitetslogg",
      NO: "/aktivitetsloggen",
    },
  };

  let lang = getDomainCountry();
  return (
    <Translation>
      {(t) => (
        <header>
          <div className="wrapper-nav">
            <div className="container">
              <div className="row justify-content-between justify-content-lg-center align-items-center">
                <div className="col-12 col-lg-10 d-flex justify-content-between align-items-center">
                  <Avatar />
                  <Link
                    className="order-lg-1"
                    to={context.loggedIn ? "/medlemskap" : "/"}
                  >
                    <NavLogo className="logo" width={null} height={null} />
                  </Link>

                  {context.loggedIn ? (
                    <div
                      className={
                        "nav-icon order-lg-3" + (isOpen ? " open" : "")
                      }
                      onClick={() => {
                        if (isOpen) {
                          setIsOpen(false);
                        } else {
                          setIsOpen(true);
                        }
                      }}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  ) : null}
                </div>
                {context.loggedIn ? (
                  <nav
                    className={"mobile-nav p-0" + (isOpen ? " menu-open" : "")}
                  >
                    <div className="menu-header-menu-container">
                      <ul id="menu-header-menu" className="menu">
                        {domainCountry === "SE" ? (
                          <MenuItem>
                            <a
                              href="https://www.actic.se"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Actic.se
                            </a>
                          </MenuItem>
                        ) : null}
                        {domainCountry === "NO" ? (
                          <MenuItem>
                            <a
                              href="https://www.actic.no"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Actic.no
                            </a>
                          </MenuItem>
                        ) : null}
                        {domainCountry === "DE" ? (
                          <MenuItem>
                            <a
                              href="https://www.acticfitness.de"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Acticfitness.de
                            </a>
                          </MenuItem>
                        ) : null}
                        <MenuItem>
                          <Link
                            to="/medlemskap"
                            className={
                              "barmenu-membership" +
                              (window.location.pathname === "/medlemskap"
                                ? " active"
                                : "")
                            }
                          >
                            {t("Menu.Membership")}
                          </Link>
                        </MenuItem>
                        {context.displayScheduleNavItem ? (
                          <MenuItem>
                            <Link
                              to={translations["booking"][lang]}
                              className={
                                "barmenu-booking" +
                                (window.location.pathname === "/boka-pass"
                                  ? " active"
                                  : "")
                              }
                            >
                              {t("Menu.Booking")}
                            </Link>
                          </MenuItem>
                        ) : (
                          <div className="barmenu-booking inactive">
                            {t("Menu.Booking")}
                          </div>
                        )}
                        <MenuItem>
                          <Link to={translations["statistics"][lang]}>
                            {t("Menu.Statistics")}
                          </Link>
                        </MenuItem>
                        {context.displayMyActicNavItem ? (
                          <MenuItem>
                            <Link to="/myactic">{t("Menu.MyActic")}</Link>
                          </MenuItem>
                        ) : null}

                        {context.displayMyActicNavItem ? (
                          <MenuItem>
                            <Link to={translations["log"][lang]}>
                              {t("Menu.Log")}
                            </Link>
                          </MenuItem>
                        ) : null}

                        <MenuItem>
                          <a
                            href={getSupportURL()}
                            target="_blank"
                            rel="noreferrer"
                            className="barmenu-support"
                            dangerouslySetInnerHTML={{
                              __html: t("Menu.SupportDesktop"),
                            }}
                          ></a>
                        </MenuItem>
                        <MenuItem>
                          <a href="/" onClick={context.singOut}>
                            {t("Menu.Logout")}
                          </a>
                        </MenuItem>
                      </ul>
                    </div>
                  </nav>
                ) : null}
              </div>
            </div>
          </div>
        </header>
      )}
    </Translation>
  );
};
