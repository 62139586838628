import React from 'react'

export const SmallList = ({headline, children, style}) => {
    return (
        <div>
            <h6>{headline}</h6>
            <ul className={style}>
                {children}
            </ul>
        </div>
    )
}
