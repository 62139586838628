import React from 'react';
import { Contracts } from '../components/settings/Contracts.js';
import { Container } from '../components/Components.js';
import { TopContent } from '../components/TopContent.js';
import { Translation } from 'react-i18next';
import { NavTop } from '../components/Nav.js';
import Breadcrumbs from '../components/Breadcrumb.js';

export const PageContracts = () => {

    return (
      <Translation>
        {(t) => (
          <>
            <NavTop />
            <Breadcrumbs/>
            <TopContent h2={t("Settings.ContractsHeadline")}/>
            <Container>
              <Contracts />
            </Container>
          </>
        )}
      </Translation>
  );
};
