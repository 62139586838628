import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { cloneObject } from "./lib/cloneObject.js";

import { Loading, DateFormat } from "./components/Components.js";

import { Container } from "./components/Components";
import { UpgradesModular } from "./components/settings/UpgradesModular";

import { Card } from "./components/Card";
import { NavTop } from "./components/Nav";
import { TopContent } from "./components/TopContent";
import { CenterDropdown } from "./components/change/CenterDropdown";
import { ChangeCompletedScreen } from "./components/change/ChangeCompletedScreen";
import { ConfirmationScreen } from "./components/change/ConfirmationScreen";
import { Slideup } from "./components/Slideup";
import { PreviewBasket } from "./components/change/PreviewBasket";
import { ChangeOption } from "./components/change/ChangeOption";
import { Alert } from "./components/Alert";

import { api_get, api_post } from "./API.js";
import PersonContext from "./PersonContext.js";
import ChangeContext from "./ChangeContext.js";
import { getDomainCountry } from "./config.js";

const findMatchingSubscription = (
  currentSelectedData,
  availableSubscriptions
) => {
  let result = availableSubscriptions.find((subscription) => {
    if (
      subscription.subscriptionType === currentSelectedData.subscriptionType &&
      subscription.subscriptionAccess ===
        currentSelectedData.subscriptionAccess &&
      subscription.subscriptionTimespan ===
        currentSelectedData.subscriptionTimespan &&
      subscription.subscriptionBinding ===
        currentSelectedData.subscriptionBinding
    ) {
      return true;
    } else {
      return false;
    }
  });
  return result || false;
};

export const PageChangeMembership = () => {
  const { t } = useTranslation();
  const personContext = useContext(PersonContext);
  //const ChangeContext = useContext(ChangeContext);
  //console.log("personContext", personContext);

  let [subscriptionOptions, setSubscriptionOptions] = useState(false);
  const selectedDefaults = {
    personType: personContext.person.presonType,
    centerId: personContext.center.centerId,
    subscriptionType: "",
    subscriptionAccess: "",
    subscriptionTimespan: "",
    subscriptionBinding: "",
    //addons: [],
    //companyCode: "",
    //campaignCode: "",
  };
  // let [showModal, setShowModal] = useState(false);
  let [selectedCenter, setSelectedCenter] = useState(personContext.center); // eslint-disable-line
  let [selectedSubscription, setSelectedSubscription] = useState(false);
  let [selectedData, setSelectedData] = useState(cloneObject(selectedDefaults));
  let [selectedStartData, setSelectedStartData] = useState(cloneObject(selectedDefaults));
  let [has, setHas] = useState({});
  let [showConfirm, setShowConfirm] = useState(false);
  let [changeCompleted, setChangeCompleted] = useState(false);
  let [currentSubscription, setCurrentSubscription] = useState({});
  let [newSubscription, setNewSubscription] = useState(false);
  let [currentSubscriptionIsSelected, setCurrentSubscriptionIsSelected] =
    useState(false);
  let [availableSubscriptions, setAvailableSubscriptions] = useState([]);

  let [isLoading, setLoading] = useState(true);
  let [isSubmitting, setSubmitting] = useState(false);
  let domainCountry = getDomainCountry();
  
  useEffect(() => {
    api_get("/subscriptions/options").then((resp) => {
      setSubscriptionOptions(resp);
    });

    api_get(
      "/persons/" +
        personContext.person.personId.externalId +
        "/subscriptions/change/products"
    ).then((resp) => {
      if (resp.activeChange) {
        setCurrentSubscription(false);
        setSelectedData(cloneObject(selectedDefaults));
        setAvailableSubscriptions([]);
        setChangeCompleted(true);
        setNewSubscription(resp.newSubscription);
      } else if (resp.currentSubscription && resp.subscriptions.length) {
        setCurrentSubscription(resp.currentSubscription);

        setSelectedStartData((prev) => {
          return {
            ...prev,
            centerId: resp.currentSubscription.centerId,
            subscriptionType: resp.currentSubscription.subscriptionType,
            subscriptionAccess: resp.currentSubscription.subscriptionAccess,
            subscriptionTimespan: resp.currentSubscription.subscriptionTimespan,
            subscriptionBinding: resp.currentSubscription.subscriptionBinding,
          };
        });

        setSelectedData((prev) => {
          return {
            ...prev,
            centerId: resp.currentSubscription.centerId,
            subscriptionType: resp.currentSubscription.subscriptionType,
            subscriptionAccess: resp.currentSubscription.subscriptionAccess,
            subscriptionTimespan: resp.currentSubscription.subscriptionTimespan,
            subscriptionBinding: resp.currentSubscription.subscriptionBinding,
          };
        });

        setCurrentSubscriptionIsSelected(true);

        setAvailableSubscriptions(resp.subscriptions);
        setHas(resp.has);

        let changeModalShown = sessionStorage.getItem("changeModalShown");
        if (!changeModalShown) {
          personContext.openModal("change");
          sessionStorage.setItem("changeModalShown", true);
        }

      } else {
        setCurrentSubscription(false);
        setSelectedData(cloneObject(selectedDefaults));
        setAvailableSubscriptions([]);
        if (typeof resp.error !== 'undefined' && resp.error) {
          Alert(t("Errors." + resp.error));
        }
      }

      setLoading(false);
    });
  }, []); // eslint-disable-line

  //const setMatachingSubscription = () => {
  //  let subscription = findMatchingSubscription
  //}
  const setMatchingSubscription = (currentSelectedData) => {
    if (availableSubscriptions) {
      let matchingSubscription = findMatchingSubscription(
        currentSelectedData,
        availableSubscriptions
      );
      setSelectedSubscription(matchingSubscription);
    }
  };

  const changeDataOnClick = (data) => {
    let currentSelectedData = { ...selectedData, ...data };
    setSelectedData(currentSelectedData);
    setMatchingSubscription(currentSelectedData);

    let isCurrentSubscriptionSelected = findMatchingSubscription(
      currentSelectedData,
      [currentSubscription]
    );
    setCurrentSubscriptionIsSelected(
      isCurrentSubscriptionSelected ? true : false
    );
  };

  const initChange = (e) => {
    e.preventDefault();
    setShowConfirm(true);
  };

  const postSubscriptionChange = () => {
    if (!selectedSubscription) {
      Alert(t("Errors.ChangeSubscription.NoSubscriptionSelected"));
      setShowConfirm(false);
      return;
    }

    setSubmitting(true);

    let post_url =
      "/persons/" +
      personContext.person.personId.externalId +
      "/subscriptions/change/" +
      selectedSubscription.productId.center +
      "p" +
      selectedSubscription.productId.id;
    api_post(post_url, {})
      .then((resp) => {

        setCurrentSubscription(resp.subscription);
        if (resp.subscriptions && Array.isArray(resp.subscriptions)) {
          personContext.setSubscriptions(resp.subscriptions, true);
        }

        setNewSubscription(resp.newSubscription);

        setAvailableSubscriptions([]);
        setLoading(false);
        setShowConfirm(false);
        setSelectedData(cloneObject(selectedDefaults));
        setSubmitting(false);
        setChangeCompleted(true);

        if (resp.newSubscription.startDate === new Date().toISOString().split('T')[0]) {
          Alert(
            t("ChangeSubscription.ChangeSuccess")
          );
        } else {
          Alert(
            t("ChangeSubscription.ChangeSuccessDate", {
              startDate: DateFormat(resp.newSubscription.startDate),
            })
          );
        }
        

        //if (!isTest()) {
        //    TagManager.dataLayer({
        //        dataLayer: {
        //            event: 'FormSubmitted-upgrade'
        //        }
        //    });
        //}
      })
      .catch((err) => {
        setSubmitting(false);
        Alert(t("ChangeSubscription.UpgradeFailed"));
      });
      
  };

  function getIndex(object, key, value) {
    let index = object.findIndex(function(item) {
      return item[key] === value;
    });
    return index;
  }

  return (
    <ChangeContext.Provider
      value={{
        findMatchingSubscription,
        selectedData,
        selectedCenter,
        selectedSubscription,
        availableSubscriptions,
        currentSubscription,
        currentSubscriptionIsSelected,
      }}
    >
    
      
      <NavTop />
      <TopContent
        classNae="mb-small"
        h1={t("ChangeSubscription.Headline")}
        p={t("ChangeSubscription.HeaderDescription")}
      />
      <Container>
        {isLoading ? (
          <div className="d-flex justify-content-center mt-4 mb-4">
            <Loading>{t("Settings.LoadingDetails")}</Loading>
          </div>
        ) : changeCompleted ? (
          <ChangeCompletedScreen newSubscription={newSubscription} />
        ) : showConfirm ? (
          <ConfirmationScreen
            postSubscriptionChange={postSubscriptionChange}
            isSubmitting={isSubmitting}
            setShowConfirm={setShowConfirm}
          />
        ) : availableSubscriptions.length > 0 ? (
          <React.Fragment>
            <CenterDropdown />
            <UpgradesModular>
              <Card title="Tillgångsnivå">
                {has?.local && selectedStartData.subscriptionType !== 'area' && selectedStartData.subscriptionType !== 'max' && (
                  <ChangeOption
                    optionKey="subscriptionType"
                    optionId={"local"}
                    text={t("ChangeSubscription.OptionLocal")}
                    onClick={() => {
                      changeDataOnClick({ subscriptionType: "local" });
                    }}
                  />
                )}
                {has?.area && selectedStartData.subscriptionType !== 'max' && (
                  <ChangeOption
                    optionKey="subscriptionType"
                    optionId={"area"}
                    text={t("ChangeSubscription.OptionArea")}
                    onClick={() => {
                      changeDataOnClick({ subscriptionType: "area" });
                    }}
                  />
                )}
                {has?.max && (
                  <ChangeOption
                    optionKey="subscriptionType"
                    optionId={"max"}
                    text={t("ChangeSubscription.OptionMax")}
                    onClick={() => {
                      changeDataOnClick({ subscriptionType: "max" });
                    }}
                  />
                )}
              </Card>
              {subscriptionOptions && subscriptionOptions.subscription_access && (
                <Card title={subscriptionOptions.subscription_access.title}>
                  {subscriptionOptions.subscription_access.options.map(
                    (opt, index) => {

                      if (index < getIndex(subscriptionOptions.subscription_access.options, 'option_id', selectedStartData['subscriptionAccess'])) {
                        return null;
                      }

                      return (
                        <ChangeOption
                          key={opt.option_id}
                          optionKey="subscriptionAccess"
                          optionId={opt.option_id}
                          text={opt.option_name}
                          onClick={() => {
                            changeDataOnClick({
                              subscriptionAccess: opt.option_id,
                            });
                          }}
                        />
                      );
                    }
                  )}
                </Card>
              )}


              {subscriptionOptions && domainCountry !== 'NO' &&
                subscriptionOptions.subscription_timespans && (
                  <Card
                    title={subscriptionOptions.subscription_timespans.title}
                  >
                    {subscriptionOptions.subscription_timespans.options.map(
                      (opt, index) => {

                        if (index < getIndex(subscriptionOptions.subscription_timespans.options, 'option_id', selectedStartData['subscriptionTimespan'])) {
                          return null;
                        }

                        return (
                          <ChangeOption
                            key={opt.option_id}
                            optionKey="subscriptionTimespan"
                            optionId={opt.option_id}
                            text={opt.option_name}
                            onClick={() => {
                              changeDataOnClick({
                                subscriptionTimespan: opt.option_id,
                              });
                            }}
                          />
                        );
                      }
                    )}
                  </Card>
                )}
              {subscriptionOptions &&
                subscriptionOptions.subscription_bindings && (
                  <Card title={subscriptionOptions.subscription_bindings.title}>
                    {subscriptionOptions.subscription_bindings.options.map(
                      (opt, index) => {

                        if (index < getIndex(subscriptionOptions.subscription_bindings.options, 'option_id', selectedStartData['subscriptionBinding'])) {
                          return null;
                        }

                        return (
                          <ChangeOption
                            key={opt.option_id}
                            optionKey="subscriptionBinding"
                            optionId={opt.option_id}
                            text={opt.option_name}
                            onClick={() => {
                              changeDataOnClick({
                                subscriptionBinding: opt.option_id,
                              });
                            }}
                          />
                        );
                      }
                    )}
                  </Card>
                )}
            </UpgradesModular>
          </React.Fragment>
        ) : (
          <div className="mt-4 mb-4"><span className="body-text">{t("ChangeSubscription.NoSubscriptionsAvailable")}</span></div>
        )}
      </Container>
      {(selectedSubscription || currentSubscription) && !showConfirm && availableSubscriptions.length ? (
        <Slideup>
          <PreviewBasket
            currentSubscription={currentSubscription}
            selectedSubscription={selectedSubscription}
            initChange={initChange}
          />
        </Slideup>
      ) : null}
    </ChangeContext.Provider>
  );
};
