import React from 'react'

export const CheckBoxOption = ({text, label, checked, onClick, className}) => {
    return (
        <div className={"holder-checkbox " + className} onClick={() => {
            if (typeof onClick !== 'undefined') {
                onClick();
            }
        }}>
            <div>
                <span className="body-text-small option-title">{text}</span>
            </div>
            <div className="d-flex align-items-center">
                <label className="h6 me-2 mb-0">{label}</label>
                <span className="checkbox"><span className={checked?'checked':''}></span></span>
            </div>
        </div>
    )
}
