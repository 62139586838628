import React, { useState, useContext, useEffect } from 'react';
//import {getApiHost} from '../../config.js'
import { Translation } from 'react-i18next';
import PersonContext from "../../PersonContext.js";
import i18next from 'i18next';
import { api_post, api_get, api_upload, api_delete, handleError } from '../../API.js';
import { Loading, DateFormat } from '../Components.js';
import { Alert } from '../Alert.js';
import { Dropdown } from "../Dropdown.js";
import { FreezeNo } from "../../components/settings/FreezeNoOther.js";

export const Freeze = (props) => {
  let context = useContext(PersonContext);
  let [closeDropdown1, setCloseDropdown1] = useState(0);
  let [closeDropdown2, setCloseDropdown2] = useState(0);
  let [isLoading, setLoading] = useState(true);
  let [isSubmitting, setSubmitting] = useState(false);
  let [isInvalid, setInvalid] = useState(false); // eslint-disable-line
  let [currentFreeze, setCurrentFreeze] = useState(false);
  let [data, setData] = useState(false);

  let nowDate = new Date();
  let today = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate());
  let selectableStartDates = [];
  let [selectableEndDates, setSelectableEndDates ] = useState([]);

  for (let i = 1; i < 12; i++) {
    let startDate = new Date(today.getFullYear(), today.getMonth() + i, 1);
    startDate = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60 * 1000))
    selectableStartDates.push(DateFormat(startDate));
  }

  let activeFreezeStart = false;
  let activeFreezeEnd   = false;
  let isActiveFreeze    = false;
  if (typeof context.subscription.freeze !== 'undefined' && typeof context.subscription.freeze.startDate !== 'undefined') {
      activeFreezeStart = new Date(context.subscription.freeze.startDate);
      activeFreezeEnd   = new Date(context.subscription.freeze.endDate);
      isActiveFreeze    = true
  }
  let [activeFreeze, setActiveFreeze] = useState(isActiveFreeze);


  useEffect(() => {
     

    setData({
      cause: '',
      freeze_from: selectableStartDates[0],
      freeze_to: selectableEndDates[0],
      attestation: '',
      acceptTerms: false,
    });

    if(context?.person?.personId?.externalId == null) {
      return;
    }
    api_get('/persons/' + context.person.personId.externalId + '/freeze')
        .then((resp) => {
          if (resp.freeze) {
            setCurrentFreeze(resp.freeze);
          }
          setLoading(false);
        });

    
  }, [context]); // eslint-disable-line

  const handleChange = (e) => {
    if (typeof e.target.checked !== 'undefined' && typeof e.target.checked === 'boolean') {
      setData({...data, [e.target.name]: e.target.checked});
    } else {
      setData({...data, [e.target.name]: e.target.value});
    }
  }
  const handleFileChange = (e) => {
    if (typeof e.target.files[0] !== 'undefined') {
      setData({...data, [e.target.name]: e.target.files[0]});
    } else {
      setData({...data, [e.target.name]: ''});
    }
  }

  const handleSubmit = () => {

    if (data.cause === '') {
      Alert(i18next.t('Errors.Freeze.Cause'));
      return false;
    }

    if (data.freeze_from === '' || data.freeze_from === '') {
      Alert(i18next.t('Errors.Freeze.Dates'));
      return false;
    }

    let freeze_from = data.freeze_from;
    let freeze_from_ms = new Date(freeze_from).getTime();
	  let freeze_from_time = Math.round(freeze_from_ms/1000);

    let freeze_to = data.freeze_to;
    let freeze_to_ms = new Date(freeze_to).getTime();
	  let freeze_to_time = Math.round(freeze_to_ms/1000);

    //let now_ms = new Date().getTime();
	  //let now = Math.round(now_ms/1000);

    if (freeze_to_time <= freeze_from_time) {
      Alert(i18next.t('Errors.Freeze.Dates'));
      return false;
    }

    let freeze_length = freeze_to_time-freeze_from_time;
    let year_length = 86400*365;
		if (freeze_length > year_length) {
			Alert(i18next.t('Errors.Freeze.TooLong'));
			return false;
		}

		if (data.attestation === '') {
			Alert(i18next.t('Errors.Freeze.File'));
			return false;
		}

    if (!data.acceptTerms) {
      Alert(i18next.t('Errors.Freeze.Terms'));
			return false;
    }

    setSubmitting(true);

    const formData = new FormData();
    for (let keyName of Object.keys(data)) {
      formData.append(keyName, data[keyName]);
    }
    
    api_upload("/persons/"+context.person.personId.externalId+"/freeze", formData)
      .then(resp => {
        if (resp.freeze) {
          setCurrentFreeze(resp.freeze);
        }
        setSubmitting(false);
        Alert(i18next.t('Settings.FreezeSuccess'));
      })
      .catch(err => {
        handleError(err);
        setSubmitting(false);
      })
       
  }

  const removeFreeze = (e) => {
    e.preventDefault();
    api_delete("/persons/"+context.person.personId.externalId+"/freeze")
      .then(resp => {
        setCurrentFreeze(false);
        setSubmitting(false);
        Alert(i18next.t('Settings.FreezeDeleteSuccess'));
      })
      .catch(err => {
        handleError(err);
        setSubmitting(false);
      })
  }

  const cancelOrStopFreeze = () => {
      setSubmitting(true);

      api_post('/persons/' + context.person.personId.externalId + '/unfreeze')
        .then((resp) => {
            setSubmitting(false);
            if (resp.success) {
                context.setPersonData({ subscription: resp.subscription });
                context.setMyActic(resp.myactic);
                setActiveFreeze(false);
                if (typeof resp.msg === 'string') {
                    Alert(resp.msg);
                }
            } else {
                handleCancelOrStopFreezeError(resp);
            }
        })
        .catch((err) => {
            handleCancelOrStopFreezeError(err);
            setSubmitting(false);
        });
  }

  const handleCancelOrStopFreezeError = (resp) => {
      if (resp.msg) {
          Alert(resp.msg);
      } else {
          Alert(i18next.t('Errors.Unknown'))
      }
  }

  useEffect(() => {
   let today =  new Date(Date.parse(data.freeze_from));
   let newEndDates = [];
    for (let i = 1; i < 12; i++) {
      let endDate = new Date(today.getFullYear(), today.getMonth()+i+1, 0);
      endDate = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60 * 1000))
      newEndDates.push(DateFormat(endDate));
    }
    setSelectableEndDates(newEndDates);
  }, [data.freeze_from]); // eslint-disable-line

  useEffect(() => {
    setData({...data, freeze_to: selectableEndDates[0]});
  }, [selectableEndDates]); // eslint-disable-line


  return (
    <Translation>
      {(t) => (
        isLoading 
        ? <div className="d-flex justify-content-center mt-4 mb-4"><Loading>{t('Settings.LoadingDetails')}</Loading></div> 
        : (
          /* Current freeze, member has a freeze form submitted. */
          currentFreeze !== false 
          ? (
            <div className="mt-4">
              <div className="row">
                <div className="col-12 col-md-10 col-xxl-8">
                  <p className="body-text-small">{t('Settings.FreezeSuccess')}</p>
                  <div className=" mb-small">
                    <ul className="list">
                      <li>
                        <div>
                          <div><span className="body-text">{t('Settings.FreezeYourPeriod')}</span></div>
                          <div className="text-end"><span className="body-text-small">{currentFreeze.freeze_from} - {currentFreeze.freeze_to}</span></div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <div><span className="body-text">{t('Settings.FreezeAttachedFile')}</span></div>
                          <div className="text-end"><span className="body-text-small">{currentFreeze.file_name}</span></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="text-center mb-small">
                    <button className="btn secondary-btn color-secondary" onClick={removeFreeze}>{t('Settings.FreezeRemove')}</button>
                  </div>
                </div>
              </div>
            </div>
          )
          : (
          <div className="mt-4">
            {
                /* Has an active freeze, regardless of how. Can be MyActic VIP. */
                activeFreeze
                ?
                (
                    <div>
                        <div className="row">
                            <div className="col-12 col-md-10 col-xxl-8">
                                {(activeFreeze && activeFreezeStart < nowDate) ? <div className="mt-4 mb-4"><span className="body-text-small">{t("Settings.FreezeFreezedTo", {enddate: DateFormat(activeFreezeEnd)})}</span></div> : null}
                                {(activeFreeze && activeFreezeStart > nowDate) ? <div className="mt-4 mb-4"><span className="body-text-small mb-small">{t("Settings.FreezeFreezedSoon", {startdate: DateFormat(activeFreezeStart)})}</span></div> : null}
                            </div>
                            <div className="col-12">
                                <div className="text-center">
                                    <button 
                                        className="btn secondary-btn color-secondary" 
                                        onClick={cancelOrStopFreeze}
                                        disabled={isSubmitting ? true : false}
                                    >{t("Settings.FreezeCancelFreeze")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                :
                /* Show freeze form. */
                (
                    <div>
                        <div className="row">
                            <div className="col-12">
                                <div className="input-group">
                                    <select
                                        className={isInvalid.cause ? 'invalid' : ''}
                                        name="cause"
                                        disabled={isSubmitting ? true : false}
                                        onChange={handleChange}
                                    >
                                        <option value="">{t('Settings.FreezeCause')}</option>
                                        <option value={t('Settings.FreezeCause1')}>{t('Settings.FreezeCause1')}</option>
                                        <option value={t('Settings.FreezeCause2')}>{t('Settings.FreezeCause2')}</option>
                                        <option value={t('Settings.FreezeCause3')}>{t('Settings.FreezeCause3')}</option>
                                        <option value={t('Settings.FreezeCause4')}>{t('Settings.FreezeCause4')}</option>
                                        <option value={t('Settings.FreezeCause5')}>{t('Settings.FreezeCause5')}</option>
                                        {
                                            context.center.countrycode === 'DE' 
                                            ? (<option value={t('Settings.FreezeCause6')}>{t('Settings.FreezeCause6')}</option>)
                                            : null
                                        }
                                        {
                                            context.center.countrycode === 'TEMP_REMOVE_NO'
                                            ? (<option value="other">Annet/Ferie</option>)
                                            : null
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                        {
                        data.cause === 'other'
                        ? 
                        <>
                        <FreezeNo />
                        </>
                        : null
                        }

{
                        data.cause !== 'other' &&  context.center.countrycode === 'NO'
                        ? 
                        <>
                        <p className='caption-text mt-5'>
                          Ved behov for frys utover 2 måneder så må det dokumenteres, gyldig dokumentasjon er:
                        </p>
                        <ul className="caption-text">
                            <li>Sykdom/skade</li>
                            <li>Utstasjonering</li>
                            <li>Arbeid på annet sted</li>
                            <li>Studier på annet sted</li>
                            <li>Militærtjeneste</li>
                            <li>Svangerskap</li>
                        </ul>
                        <p className='caption-text mb-5'>
                            Medlemskap kan fryses fra den 1. i påfølgende måned - altså ikke midt i en måned. Eventuell bindingstid forskyves tilsvarende fryseperioden.
                            Under fryseperioden vil ikke medlemsavgiften debiteres, og medlemskortet sperres for adgang til Actics treningsfasiliteter.
                            Medlemskapet åpnes automatisk dagen etter siste dato i fryseperioden.
                        </p>
                        </>
                        : null
                        }

                        {data.cause !== 'other' && (
                          <>
                            <div className="row">
                                <div className="col col-xl-6">
                                    <label className="h4">{t('Settings.FreezeFrom')}</label>
                                    <Dropdown
                                        closeDropdown={closeDropdown1}
                                        label={data.freeze_from ? DateFormat(data.freeze_from) : t('Settings.FreezeFrom')}
                                    >
                                        <ul>
                                        {selectableStartDates.map((d, i) => {
                                            return (
                                            <li
                                                key={d}
                                                className={
                                                data.freeze_from && d === data.freeze_from
                                                    ? "selected"
                                                    : ""
                                                }
                                                onClick={() => {
                                                setData({...data, freeze_from: d});
                                                setCloseDropdown1(closeDropdown1+1);
                                                }}
                                            >
                                                <span>{d === today ? "Idag" : DateFormat(d)}</span>
                                            </li>
                                            );
                                        })}
                                        </ul>
                                    </Dropdown>
                                </div>
                                <div className="col col-xl-6">
                                    <label className="h4">{t('Settings.FreezeTo')}</label>
                                    <Dropdown
                                        closeDropdown={closeDropdown2}
                                        label={data.freeze_to ? DateFormat(data.freeze_to) : t('Settings.FreezeTo')}
                                    >
                                        <ul>
                                        {selectableEndDates.map((d, i) => {
                                            return (
                                            <li
                                                key={d}
                                                className={
                                                data.freeze_to && d === data.freeze_to
                                                    ? "selected"
                                                    : ""
                                                }
                                                onClick={() => {
                                                setData({...data, freeze_to: d});
                                                setCloseDropdown2(closeDropdown2+1);
                                                }}
                                            >
                                                <span>{d === today ? "Idag" : DateFormat(d)}</span>
                                            </li>
                                            );
                                        })}
                                        </ul>
                                    </Dropdown>
                                </div>
                                
                                <div className="col-12 col-md-10 col-xxl-8">
                                    <label className="h4">{t('Settings.FreezeAttestation')}</label>
                                    <div className="input-group">
                                        <input
                                            type="file"
                                            className={isInvalid.freeze_to ? 'invalid' : ''}
                                            name="attestation"
                                            disabled={isSubmitting ? true : false}
                                            onChange={handleFileChange}
                                            placeholder={t('Settings.FreezeAttestation')}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <p className="caption-text" dangerouslySetInnerHTML={{__html: t("Settings.FreezeTerms")}}></p>
                                    <div className="input-group">
                                        <div className="form-check-switch">
                                            <input className="form-check-input-switch accept-required" type="checkbox" name="acceptTerms" value="yes" onChange={handleChange} />
                                            <label className="caption-text m-0">{t("Settings.FreezeIAccept")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 mt-small mb-small">
                                    <div className="text-center">
                                        <button 
                                        className="btn secondary-btn color-secondary" 
                                        onClick={handleSubmit}
                                        disabled={isSubmitting ? true : false}
                                        >{t("Settings.FreezeSubmit")}</button>
                                    </div>
                                </div>
                            </div>
                          </>
                        )}

                  </div>
                )
            }
          </div>
          )
        )
      )}
    </Translation>
    );
};
