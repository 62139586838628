import React, { useState, useContext } from "react";
//import { Link } from "react-router-dom";
import PersonContext from "./../PersonContext";
import i18next from "i18next";

export const DateFormat = (date) => {
  let dateString = "";
  if (date && typeof date.getMonth === "function") {
    try {
      let tzoffset = new Date().getTimezoneOffset() * 60000;
      dateString = new Date(date.getTime() - tzoffset)
        .toISOString()
        .split("T")[0];
    } catch (err) {}
  } else if (typeof date === "string") {
    dateString = date;
  }
  return dateString;
};

export const Container = (props) => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div
          className={
            (typeof props.col !== "undefined" && props.col
              ? props.col
              : "col-12 col-md-10 col-xxl-8") +
            (typeof props.className !== "undefined" && props.className
              ? " " + props.className
              : "")
          }
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export const Loading = (props) => {
  let text = props.children || "";
  return (
    <div className="loading">
      <div className="ripple">
        <div></div>
        <div></div>
      </div>
      {text.length ? (
        <div>
          <span>{text}</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const CardLink = ({ children, icon, className }) => {
  return (
    <div className={`card ${className ? className : ""}`}>
      <div className="card-body d-flex justify-content-between align-items-center">
        {children}
        {icon && <div className="card-icon">{icon}</div>}
      </div>
    </div>
  );
};

export const Section = (props) => {
  let [isOpen, setOpen] = useState(props.open || false);
  let inactive =
    typeof props.inactive !== "undefined" &&
    props.inactive &&
    window.location.host !== "localhost:3000"
      ? true
      : false;
  let headlineMarginBottom =
    typeof props.headlineMarginBottom !== "undefined" &&
    props.headlineMarginBottom
      ? true
      : false;
  let toggleOpen = () => {
    if (inactive) {
      return false;
    }
    setOpen(!isOpen);
  };
  return (
    <React.Fragment>
      <div
        className={
          typeof props.className !== "undefined" && props.className
            ? props.className
            : "container"
        }
      >
        <div className="row">
          <div
            className={
              typeof props.col !== "undefined" && props.col
                ? props.col
                : "col-12 col-md-10 col-xxl-8 mx-auto"
            }
          >
            <div
              onClick={toggleOpen}
              className={
                "section-headline" +
                (inactive ? " inactive" : " toggle-icon") +
                (isOpen ? "" : " closed") +
                (headlineMarginBottom ? " mbsh" : "")
              }
            >
              <h6>{props.headline}</h6>
              {props.buttons ? (
                <div className="btn-group">{props.buttons}</div>
              ) : null}
              {props.right ? <div>{props.right}</div> : null}
              <span></span>
            </div>
          </div>
        </div>
      </div>
      {isOpen ? props.children : null}
    </React.Fragment>
  );
};

export const CenterName = (props) => {
  let context = useContext(PersonContext);
  if (props.centerId > 0) {
    for (let center of context.centers) {
      if (center.centerId === props.centerId) {
        return <React.Fragment>{center.name}</React.Fragment>;
      }
    }
  }
  return <React.Fragment>-</React.Fragment>;
};

export const OfferCarousel = (props) => {
  return (
    <div className="section-offer-carousel">
      <Container>
        <div className="text-center">
          <span onClick={props.onClick} className="item d-block">
            <h5 className="text-white mb-0">{props.title}</h5>
          </span>
        </div>
      </Container>
    </div>
  );
};
