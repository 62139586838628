import React, { useState, useContext } from 'react';
import copy from 'copy-to-clipboard';
import { Translation } from 'react-i18next';
import PersonContext from "../../PersonContext";
import { getDomainCountry } from "../../config.js";
import recruitUrls from "../../config/recruitUrls.json";
import i18next from "i18next";
import { api_post, handleError } from "../../API.js";
import { Alert } from "../Alert.js";


export const RecruitModal = (props) => {
    const context = useContext(PersonContext);
    const [isCopies, setCopied] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [sendInvalid, setSendInvalid] = useState({});
    const [sendData, setSendData] = useState({email: ''});

    const version = props?.version;

    const domainCountry = getDomainCountry();
    const linkBase = recruitUrls[domainCountry];
    let recruitUrl = linkBase + context.person.personId.center + 'p' + context.person.personId.id;

    // If RecruitModal is created with version props, we put that into the URL and later on
    // we'll change which text is shown. Version is also sent to the backend when the user
    // sends the link via e-mail (different e-mail templates).
    if (version) {
        recruitUrl += '/' + version + '/';
    }

    let headline, subheadline, description, descriptionSmall;
    if (version) {
        headline         = "Recruit._version._" + version + ".Headline";
        subheadline      = "Recruit._version._" + version + ".SubHeadline";
        description      = "Recruit._version._" + version + ".Description";
        descriptionSmall = "Recruit._version._" + version + ".DescriptionSmall";
    } else {
        headline         = "Recruit.Headline";
        subheadline      = "Recruit.SubHeadline";
        description      = "Recruit.Description";
        descriptionSmall = "Recruit.DescriptionSmall";
    }

    const copyLink = (e) => { 
        e.preventDefault();
        copy(recruitUrl);
        setCopied(true);
        setTimeout(() => { setCopied(false); }, 3000);
    }

    const handleSendEmailOnChange = (e) => {
        if (sendInvalid[e.target.name]) {
            setSendInvalid({ ...sendInvalid, [e.target.name]: false });
        }
        setSendData({ ...sendData, [e.target.name]: e.target.value });
    }

    const handleSendEmailSubmit = (e) => {
        if (
            typeof e !== "undefined" &&
            typeof e.preventDefault !== "undefined"
        ) {
            e.preventDefault();
        }

        let invalid = {};
        for (let keyName of Object.keys(sendData)) {
            if (sendData[keyName] === "") {
                invalid[keyName] = true;
            }
        }

        if (Object.keys(invalid).length) {
            setSendInvalid(invalid);
            return false;
        } else {
            setSendInvalid(false);
        }

        setIsSending(true);

        const apiUrl =
            "/persons/" +
            context.person.personId.externalId +
            "/invites/send";

        const postData = {
            ...sendData,
            version,
            recruitUrl: 'https://' + recruitUrl,
        };
        
        api_post(apiUrl, postData)
            .then((resp) => {
                setIsSending(false);
                if (resp.success) {
                    setSendData({email: ''});
                    Alert({
                        headline: i18next.t("Recruit.FormSuccessHeadline"),
                        message: i18next.t("Recruit.FormSuccessMessage"),
                    });
                } else {
                    if (typeof resp.error_message !== 'undefined') {
                        handleError({message: resp.error_message});
                    } else {
                        handleError({message: i18next.t("Recruit.FormUnknownError")})
                    }
                }
            })
            .catch((resp) => {
                if (typeof resp.error_message !== 'undefined') {
                    handleError({message: resp.error_message});
                } else {
                    handleError({message: i18next.t("Recruit.FormUnknownError")})
                }
                setIsSending(false);
            });
    }

    return (
        <Translation>
            {(t) => (
                <React.Fragment>
                    <div className="top-content">
                        <h1>{t(headline)}</h1>
                        <h2>{t(subheadline)}</h2>
                    </div>

                    <div className="modal-content">
                        <div className="d-flex justify-content-center mt-small">
                        <div className="col-12">
                            <p dangerouslySetInnerHTML={{__html: t(description) }} />
                            {descriptionSmall && (<p className="body-text-small mb-small">
                                {t(descriptionSmall)}
                            </p>)}
                        </div>
                        </div>

                        {/* Email form only available for version = gt for now. */}
                        {version === 'gt' && (
                            <div
                                className={
                                    'mb-small' +
                                    (isSending ? ' form-disabled' : '')
                                }
                            >
                                <div className="row">
                                    <div className="col col-xl-8">
                                        <input
                                            type="text"
                                            className={sendInvalid?.email ? 'invalid' : null}
                                            name="email"
                                            value={sendData.email}
                                            disabled={isSending ? true : false}
                                            onChange={handleSendEmailOnChange}
                                            placeholder={t("Recruit.FormEmailPlaceholder")}
                                        />
                                    </div>
                                    <div className="col col-xl-2">
                                        <button
                                            className="btn secondary-btn color-secondary"
                                            onClick={handleSendEmailSubmit}
                                            disabled={isSending ? true : false}
                                        >
                                            {t("Recruit.FormSendButton")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="section-headline">
                            <h6>{t("Recruit.YourCode")}</h6>
                        </div>
                        <ul className="list">
                        <li>
                            <div className="body-text">
                            <div>{recruitUrl}</div>
                                {
                                    isCopies
                                    ? (
                                        <div className="body-text-small text-center">{t("Recruit.Copied")}</div>
                                    ) : (
                                        <button className="btn secondary-btn" onClick={copyLink}>{t("Recruit.Copy")}</button>
                                    )
                                }
                            </div>
                        </li>
                        </ul>

                    </div>
        </React.Fragment>
      )}
    </Translation>
  );
};
