import React from 'react';

import PersonContext from './PersonContext.js';

export class PageMain extends React.Component {
  static contextType = PersonContext;

  constructor(props) {
    super();
  }

  componentDidMount() {
    
  }

  render() {
    return (<React.Fragment></React.Fragment>);
  }
}
