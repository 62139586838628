import React, { useState, useContext, useEffect } from "react";
import { Translation } from "react-i18next";
import i18next from 'i18next';
import { api_patch, handleError } from "../../API.js";
import PersonContext from "../../PersonContext.js";
import {Loading} from "../Components.js";
import {Alert} from "../Alert.js";

export const Password = (props) => {
  
  let context = useContext(PersonContext);
  let [isLoading, setLoading] = useState(true);
  let [isSubmitting, setSubmitting] = useState(false);
  let [isInvalid, setInvalid] = useState(false); // eslint-disable-next-line
  let [data, setData] = useState(false);

  let [currentPasswordShow, setCurrentPasswordShow] = useState(false);
  let [newPasswordShow, setNewPasswordShow] = useState(false);
  let [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  useEffect(() => {
    setData({
      newPassword: '',
      confirmPassword: '',
      currentPassword: ''
    });
    setLoading(false);
  }, []); // eslint-disable

  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value});
  }

  const handleSubmit = () => {

    let invalid = {};
    for (let keyName of Object.keys(data)) {
      if (typeof data[keyName] === 'undefined' || data[keyName] === '') {
        invalid[keyName] = true;
      }
    }

    if (data.newPassword !== data.confirmPassword) {
      //invalid.confirmPassword = true;
      Alert(i18next.t("Errors.Person.PasswordNotEqual"));
      return;
    }

    if (Object.keys(invalid).length) {
      Alert(i18next.t('Errors.FillAllFields'));
      setInvalid(invalid);
      return false;
    } else {
      setInvalid(false);
    }

    setSubmitting(true);
    api_patch("/persons/"+context.person.personId.externalId+"/password", data)
      .then(resp => {
        setSubmitting(false);
        Alert(i18next.t("Settings.PasswordUpdateSuccess"));
      })
      .catch(resp => {
        handleError(resp);
        setSubmitting(false);
      })
    
  }

  return (
    <Translation>
      {(t) => (
        isLoading
        ? (
          <div className="d-flex justify-content-center mt-4 mb-4"><Loading>{t('Settings.LoadingDetails')}</Loading></div> 
        ) : (
            
          <div className={(isSubmitting ? " form-disabled" : "")}>
            
            <div className="mb-small mt-3">
                <p className="body-text-small">{t("Settings.PasswordInfoText")}</p>
            </div>

            <div>
              <div className="input-group toggle-password-display">
              <span onClick={() => { setCurrentPasswordShow(!currentPasswordShow) }}>{currentPasswordShow ? 'visibility' : 'visibility_off'}</span>
                <label className="d-none">{t("Settings.PasswordOldLabel")}</label>
                <input 
                  type={currentPasswordShow ? "text" : "password"}
                  className={isInvalid.currentPassword ? 'invalid' : ''}
                  name="currentPassword"
                  onChange={handleChange} 
                  value={data.currentPassword} 
                  placeholder={t("Settings.PasswordOldLabel")}
                  disabled={isSubmitting ? true : false}
                  />
              </div>
            </div>
            
            <div>
                <div className="input-group toggle-password-display">
                    <span onClick={() => { setNewPasswordShow(!newPasswordShow) }}>{newPasswordShow ? 'visibility' : 'visibility_off'}</span>
                    <label className="d-none">{t("Settings.PasswordLabel")}</label>
                    <input 
                    type={newPasswordShow ? "text" : "password"} 
                    className={isInvalid.newPassword ? 'invalid' : ''}
                    name="newPassword"
                    onChange={handleChange} 
                    value={data.newPassword} 
                    placeholder={t("Settings.PasswordLabel")}
                    disabled={isSubmitting ? true : false}
                    />
                </div>
            </div>

            <div>
              <div className="input-group toggle-password-display">
              <span onClick={() => { setConfirmPasswordShow(!confirmPasswordShow) }}>{confirmPasswordShow ? 'visibility' : 'visibility_off'}</span>
                <label className="d-none">{t("Settings.Password2Label")}</label>
                <input 
                  type={confirmPasswordShow ? "text" : "password"}
                  className={isInvalid.confirmPassword ? 'invalid' : ''}
                  name="confirmPassword"
                  onChange={handleChange} 
                  value={data.confirmPassword} 
                  placeholder={t("Settings.Password2Label")}
                  disabled={isSubmitting ? true : false}
                  />
              </div>
            </div>

            <div className="col-12 mt-small mb-small">
              <div className="text-center">
                <button className="btn secondary-btn color-secondary" onClick={handleSubmit}>{t("Settings.Save")}</button>
              </div>
            </div>
          </div>
        )
      )}
    </Translation>
  );
};