import React from "react";
import { Translation } from "react-i18next";

export const MyActicBar = (props) => {
    if (!props.myactic) {
        return null;
    }

    let myactic = props.myactic;

    //let nextLevelName = '';
    let maxPoints = 0;
    if (myactic.loyaltyLevelNext > myactic.loyaltyPoints) {
        maxPoints = myactic.loyaltyLevelNext;
    } else {
        maxPoints = myactic.loyaltyPoints;
    }

    let percentSubscription =
        (myactic.loyaltyPointsBySubscription / maxPoints) * 100;
    let percentCheckins = (myactic.loyaltyPointsByCheckins / maxPoints) * 100;
    let percentClipcards = (myactic.loyaltyPointsByClipcards / maxPoints) * 100;
    let percentParticipations =
        (myactic.loyaltyPointsByParticipations / maxPoints) * 100;
    let percentOther = (myactic.loyaltyPointsByOther / maxPoints) * 100;
    //let totalPercent = (myactic.loyaltyPoints / maxPoints) * 100;
    //let flagPadding = totalPercent- 11 + 0.3;
    //if (flagPadding > 100) {
    //  flagPadding = 100;
    //}
    //console.log("flagPadding", flagPadding);
    return (
        <Translation>
            {(t) => (
                <div className="bar-wrapper mt-3">
                    
                    <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="body-text-regular color-black mb-0">
                            {t("MyActic.MemberLevel")}
                        </div>
                        <div className="text-end">
                            <p className="h4 color-black mb-0">
                                {myactic.loyaltyLevelName || ""}
                            </p>
                        </div>
                    </div>

                    <div>
                    <output className="bar">
                        <span className="bar-animation">
                            <div
                                className="bar-content background-myactic-blue"
                                style={{
                                    width: percentSubscription + "%",
                                }}
                            />
                            <div
                                className="bar-content background-myactic-turquoise"
                                style={{ width: percentCheckins + "%" }}
                            />
                            <div
                                className="bar-content background-myactic-green"
                                style={{
                                    width: percentClipcards + "%",
                                }}
                            />
                            <div
                                className="bar-content background-myactic-pink"
                                style={{
                                    width: percentParticipations + "%",
                                }}
                            />
                            <div
                                className="bar-content background-myactic-yellow"
                                style={{ width: percentOther + "%" }}
                            />
                        </span>
                    </output>
                    </div>
                 
                    <div className="text-end">
                        {myactic.loyaltyLevel < 4 ? (
                            <span className="body-text-small color-rock mb-2">
                                {myactic.loyaltyLevelNext} p
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            )}
        </Translation>
    );
};
