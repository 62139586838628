import React, {useContext, useState, useEffect} from "react";
import { Translation } from "react-i18next";
import i18next from 'i18next';
import {api_get} from "./API.js";
import {isMobile} from "./lib/deviceControl.js";
import { NavTop, NavBar } from "./components/Nav.js";
import { TopContent } from './components/TopContent.js';
import { StatsContainer } from "./components/statistics/StatsContainer.js";
import {Loading} from "./components/Components.js";
//import {Container } from './components/Components.js';
import PersonContext from "./PersonContext.js";


export const PageStatistics = () => {

  let context = useContext(PersonContext);
  let [isLoading, setLoading] = useState(false);
  let [stats, setStats] = useState(false);
  let [months, setMonths] = useState(false);

  let getStats = (displayNumberOfMonths) => {
    if (isLoading) {
      return;
    }
    if (displayNumberOfMonths === months) {
      return;
    }
    setLoading(true);
    setMonths(displayNumberOfMonths);

    api_get("/persons/" + context.person.personId.externalId + "/myactic/statistics/" + displayNumberOfMonths)
      .then(resp => {
        
        resp.displayNumberOfMonths = displayNumberOfMonths;
        setLoading(false);
        setStats(resp);
      })
      .catch(err => {
        setLoading(false);
        setStats(false);
      })
  }

  useEffect(() => {
    document.title = i18next.t("Headline.Statistics") + ' - ' + i18next.t("SiteName");
    getStats(isMobile() ? 3 : 12);

  }, []); // eslint-disable-line

  

    return (
      <Translation>
        {(t) => (
          <React.Fragment>

            <NavTop />
            <TopContent className="color-light-sand" h1={t('Headline.Statistics')}/>

              <div className="stats mt-small">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                      <div className="col-12 col-md-10 col-xxl-8 d-flex justify-content-center">
                            <button className= {(months===1 ? 'active ' : '') + "btn toggle-primary-btn color-secondary me-3 size-fluid" + (isLoading ? ' inactive' : '')} onClick={() => { getStats(1) }}>
                                <span className="d-none d-md-block">{t("Statistics.Last1Month")}</span>
                                <span className="d-block d-md-none">{t("Statistics.Last1MonthShort")}</span>
                            </button>
                            <button className={(months===3 ? 'active ' : '') + "btn toggle-primary-btn color-secondary me-3 size-fluid" + (isLoading ? ' inactive' : '')} onClick={() => { getStats(3) }}>
                                <span className="d-none d-md-block">{t("Statistics.Last3Month")}</span>
                                <span className="d-block d-md-none">{t("Statistics.Last3MonthShort")}</span>
                            </button>
                            <button className={(months===12 ? 'active ' : '') + "btn toggle-primary-btn color-secondary size-fluid" + (isLoading ? ' inactive' : '')} onClick={() => { getStats(12) }}>
                                <span className="d-none d-md-block">{t("Statistics.Last12Month")}</span>
                                <span className="d-block d-md-none">{t("Statistics.Last12MonthShort")}</span>
                            </button>
                        </div>
                      </div>
                  </div>
            



                {
                isLoading
                ? <div className="loading-container"><Loading>{t("Statistics.Loading")}</Loading></div>
                : <StatsContainer stats={stats} />
                }

                </div>

   

           

              <NavBar />
          </React.Fragment>
        )}
      </Translation>
    );
}
