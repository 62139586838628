import React, {useContext} from "react";
import { Translation } from "react-i18next";
import { Section, Container } from '../Components.js';
import PersonContext from '../../PersonContext.js';
import {getDomainCountry} from "../../config.js";
//import { Loading, CenterName } from '../Components.js';

export const MyActicModal = (props) => {

    // let context = useContext(PersonContext);
    let domainCountry = getDomainCountry();

    let content = '';
    if (domainCountry === 'SE') {
        content = <MyActicModalSE />;
    } else if (domainCountry === 'NO') {
        content = <MyActicModalNO />;
    } else if (domainCountry === 'DE') {
        content = null;
    }
    return (content);
}

const MyActicModalSE = (props) => {
    let context = useContext(PersonContext);
    return (
        <Translation>
            {(t) => (
                <React.Fragment>
                    <div className="top-content mb-small">
                        <h2>MyActic</h2>
                    </div>

                    <div className="modal-content">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10 col-xxl-8 mb-small">
                                    <h3>Träna mer, orka mer och få mer!</h3>
                                </div>
                                <div className="col-12 col-md-10 col-xxl-8 mb-small">
                                    <h4>Poängsystem och förmåner</h4>
                                    <p className="body-text-small">
                                        Du ser enkelt under Mina sidor hur mycket poäng du har och vilken nivå du är på. 
                                        För varje gång du tränar så får du poäng och för varje månad du är medlem hos Actic 
                                        så ger vi dig poäng. Du hittar dina förmåner under Dina förmåner och för varje ny 
                                        nivå så får du nya spännande förmåner att använda hos Actic. När du valt att nyttja 
                                        en förmån så försvinner den från Dina förmåner.
                                    </p>
                                </div>
                                <div className="col-12 col-md-10 col-xxl-8 mb-small">
                                    <h4>Klättra i nivåer genom att samla poäng!</h4>
                                    <p className="body-text-small">
                                        När du tränar eller går på ett pass så kommer du se att du får fler och fler poäng, 
                                        du kan enkelt se hur långt ifrån du är nästa nivå här under Mina sidor och i appen. 
                                        När du kommer till en ny nivå så får du en ny medalj, här nedan ser du vilka nivåer 
                                        som finns och hur många poäng du behöver ha.
                                    </p>
                                </div>
                                <div className="col-12 col-md-10 col-xxl-8 mb-small">
                                    <p className="body-text-small">
                                            Kan jag förlora mina poäng? Nej du kan inte förlora dina poäng så länge du är medlem 
                                        hos Actic. Skulle du välja att säga upp ditt medlemskap så behåller du dina poäng 
                                        under 1 månad i de fall att du ångrar dig och vill komma tillbaka.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <Section headline="Nivåer" open={false}>
                            <Container col="col-12 col-md-10 col-xxl-8 mb-small">
                                <p className="body-text-small mt-3">
                                    Det finns 5 nivåer som du som Actic medlem kan samla poäng i. Vi belönar alla 
                                    våra träningskompisar och du som ny medlem startar på nivån Bas, är du en av 
                                    våra mest trogna träningskompisar så belönar vi dig med VIP medaljen!
                                </p>
                                <ul className="list medium">
                                    <li>
                                        <div>
                                            <div>
                                                <span className="body-text">Bas</span>
                                            </div>
                                            <div className="text-end">
                                                <span className="h4">0 - 29 999<span className="body-text ms-1">poäng</span></span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                <span className="body-text">Silver</span>
                                            </div>
                                            <div className="text-end">
                                                <span className="h4">30 000 - 99 999 <span className="body-text ms-1">poäng</span></span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                <span className="body-text">Guld</span>
                                            </div>
                                            <div className="text-end">
                                                <span className="h4">100 000 - 149 999<span className="body-text ms-1">poäng</span></span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                <span className="body-text">Elite</span>
                                            </div>
                                            <div className="text-end">
                                                <span className="h4">150 000 - 299 999<span className="body-text ms-1">poäng</span></span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                <span className="body-text">Vip</span>
                                            </div>
                                            <div className="text-end">
                                                <span className="h4">300 000<span className="body-text ms-1">poänggräns</span></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Container>
                        </Section>

                        <Section headline="Poäng" open={false}>
                            <Container col="col-12 col-md-10 col-xxl-8 my-actic-points">
                                <p className="body-text-small mt-3">
                                    Alla nya medlemmar får poäng för att kickstarta ert samlande och för varje månad som du väljer att fortsätta vara en del av Actic så belönar vi dig med poäng. Men vill du också nå VIP status så behöver du boosta dina poäng genom att träna! För varje träningstillfälle ger dig poäng. Träna mer, orka mer och få mer! 
                                </p>
                                <ul className="list big points-table">
                                    <li>
                                        <div className="points-row">
                                            <div className="points-table-dot">
                                                <div className="dot background-myactic-blue"></div>
                                            </div>
                                            <div className="points-table-name">
                                                <span className="body-text-regular color-black">Medlemskap</span>
                                                <span className="body-text-small color-rock">Poäng per månad du är medlem</span>
                                            </div>
                                            <div className="points-table-nr text-end">
                                                <span className="d-flex justify-content-end align-items-center">
                                                    <span className="h4 color-black m-0">1000 <span className="body-text ms-1">p</span></span>
                                                </span> 
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="points-row">
                                            <div className="points-table-dot">
                                                <div className="dot background-myactic-green"></div>
                                            </div>
                                            <div className="points-table-name">
                                                <span className="body-text-regular color-black">PT-timmar</span>
                                                <span className="body-text-small color-rock">Poäng per genomförd timme</span>
                                            </div>
                                            <div className="points-table-nr text-end">
                                                <span className="d-flex justify-content-end align-items-center">
                                                    <span className="h4 color-black m-0">1500 <span className="body-text ms-1">p</span></span>
                                                </span> 
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="points-row">
                                            <div className="points-table-dot">
                                                <div className="dot background-myactic-pink"></div>
                                            </div>
                                            <div className="points-table-name">
                                                <span className="body-text-regular color-black">Gruppträning</span>
                                                <span className="body-text-small color-rock">Poäng per genomfört pass</span>
                                            </div>
                                            <div className="points-table-nr text-end">
                                                <span className="d-flex justify-content-end align-items-center">
                                                    <span className="h4 color-black m-0">750 <span className="body-text ms-1">p</span></span>
                                                </span> 
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="points-row">
                                            <div className="points-table-dot">
                                                <div className="dot background-myactic-turquoise"></div>
                                            </div>
                                            <div className="points-table-name">
                                                <span className="body-text-regular color-black">Träning hos Actic</span>
                                                <span className="body-text-small color-rock">Utförd träning i Actics lokaler</span>
                                            </div>
                                            <div className="points-table-nr text-end">
                                                <span className="d-flex justify-content-end align-items-center">
                                                    <span className="h4 color-black m-0">500 <span className="body-text ms-1">p</span></span>
                                                </span> 
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="points-row">
                                            <div className="points-table-dot">
                                                <div className="dot background-myactic-yellow"></div>
                                            </div>
                                            <div className="points-table-name">
                                                <span className="body-text-regular color-black">Övrigt</span>
                                                <span className="body-text-small color-rock">Bjud in en vän och få poäng</span>
                                            </div>
                                            <div className="points-table-nr text-end">
                                                <span className="d-flex justify-content-end align-items-center">
                                                    <span className="h4 color-black m-0">100 <span className="body-text ms-1">p</span></span>
                                                </span> 
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Container>
                        </Section>
                    </div>
                    <Container col="col-12 col-md-10 col-xxl-8">
                            <div className="text-center mb-small mt-small">
                                <button className="btn secondary-btn color-secondary" onClick={() => { context.openModal(false) }}>{t("Alert.OK")}</button>
                             </div>
                    </Container>
                 
                </React.Fragment>
            )}
        </Translation>
    );
};


const MyActicModalNO = (props) => {
    let context = useContext(PersonContext);
    return (
        <Translation>
            {(t) => (
                <React.Fragment>
                    <div className="top-content mb-small">
                        <h2>MyActic</h2>
                    </div>

                    <div className="modal-content">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10 col-xxl-8 mb-10">
                                    <h3>Tren mer og få mer!</h3>
                                    <p className="body-text-small mt-3">
                                    På Mine sider ser du hvor mange poeng du har, og hvilket nivå du er på. Du får poeng for hver gang du trener, og for hver måned du er medlem. Under Dine privilegier ser du fordelene du har opptjent.
                                    </p>
                                    <p className="body-text-small">
                                    Du får poeng for hver gang du trener. Et nytt nivå gir deg en ny medalje med tilhørende privilegier. Her under ser du en oversikt over poengnivåene.
                                    </p>
                                    <p className="body-text-small">
                                    Du kan ikke miste poeng så lenge du fortsetter å være medlem hos oss. Hvis du velger å si opp medlemskapet ditt beholder poengene dine én måned etter oppsigelsen – vi håper nemlig at du skal ombestemme deg og komme tilbake til oss.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <Section headline="Nivåer" open={false}>
                            <Container col="col-12 col-md-10 col-xxl-8 mb-small">
                                <p className="body-text-small mt-3">
                                Det er 5 poengnivåer. Du begynner på Basis når du melder deg inn. VIP er det høyeste nivået du kan oppnå.
                                </p>
                                <ul className="list medium">
                                    <li>
                                        <div>
                                            <div>
                                                <span className="body-text">Basis</span>
                                            </div>
                                            <div className="text-end">
                                                <span className="h4">0 - 29 999<span className="body-text ms-1"> poeng</span></span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                <span className="body-text">Sølv</span>
                                            </div>
                                            <div className="text-end">
                                                <span className="h4">30 000 - 99 999 <span className="body-text ms-1"> poeng</span></span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                <span className="body-text">Gull</span>
                                            </div>
                                            <div className="text-end">
                                                <span className="h4">100 000 - 149 999<span className="body-text ms-1"> poeng</span></span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                <span className="body-text">Elite</span>
                                            </div>
                                            <div className="text-end">
                                                <span className="h4">150 000 - 299 999<span className="body-text ms-1"> poeng</span></span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                <span className="body-text">Vip</span>
                                            </div>
                                            <div className="text-end">
                                                <span className="h4">300 000<span className="body-text ms-1"> poeng</span></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Container>
                        </Section>

                        <Section headline="Poeng" open={false}>
                            <Container col="col-12 col-md-10 col-xxl-8 my-actic-points">
                                <ul className="list big points-table">
                                    <li>
                                        <div className="points-row">
                                            <div className="points-table-dot">
                                                <div className="dot background-myactic-blue"></div>
                                            </div>
                                            <div className="points-table-name">
                                                <span className="body-text color-black">Medlemskap</span>
                                                <span className="body-text-small color-rock">Poeng for hver måned du er medlem</span>
                                            </div>
                                            <div className="points-table-nr text-end">
                                                <span className="d-flex justify-content-end align-items-center">
                                                    <span className="h4 color-black m-0">1000 <span className="body-text ms-1">p</span></span>
                                                </span> 
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="points-row">
                                            <div className="points-table-dot">
                                                <div className="dot background-myactic-green"></div>
                                            </div>
                                            <div className="points-table-name">
                                                <span className="body-text color-black">PT-timer </span>
                                                <span className="body-text-small color-rock">Poeng for hver gjennomførte time</span>
                                            </div>
                                            <div className="points-table-nr text-end">
                                                <span className="d-flex justify-content-end align-items-center">
                                                    <span className="h4 color-black m-0">1500 <span className="body-text ms-1">p</span></span>
                                                </span> 
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="points-row">
                                            <div className="points-table-dot">
                                                <div className="dot background-myactic-pink"></div>
                                            </div>
                                            <div className="points-table-name">
                                                <span className="body-text color-black">Gruppetrening</span>
                                                <span className="body-text-small color-rock">Poeng for å ha deltatt på gruppetime</span>
                                            </div>
                                            <div className="points-table-nr text-end">
                                                <span className="d-flex justify-content-end align-items-center">
                                                    <span className="h4 color-black m-0">750 <span className="body-text ms-1">p</span></span>
                                                </span> 
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="points-row">
                                            <div className="points-table-dot">
                                                <div className="dot background-myactic-turquoise"></div>
                                            </div>
                                            <div className="points-table-name">
                                                <span className="body-text color-black">Trening hos Actic</span>
                                                <span className="body-text-small color-rock">Poeng for trening i våre lokaler</span>
                                            </div>
                                            <div className="points-table-nr text-end">
                                                <span className="d-flex justify-content-end align-items-center">
                                                    <span className="h4 color-black m-0">500 <span className="body-text ms-1">p</span></span>
                                                </span> 
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Container>
                        </Section>
                        <a target="_blank" href="https://www.actic.no/myactic/">
                        <Section headline="Fordeler" open={false}>
                            <Container col="col-12 col-md-10 col-xxl-8 mb-small">
                            </Container>
                        </Section>
                        </a>

                    </div>
                    <Container col="col-12 col-md-10 col-xxl-8">
                            <div className="text-center mb-small mt-small">
                                <button className="btn secondary-btn color-secondary" onClick={() => { context.openModal(false) }}>{t("Alert.OK")}</button>
                             </div>
                    </Container>
                 
                </React.Fragment>
            )}
        </Translation>
    );
};
