import React, { useContext, useState } from 'react';
//import { Link } from "react-router-dom";
import { Translation } from 'react-i18next';
import i18next from 'i18next';
import PersonContext from '../../../PersonContext.js';
import { api_post, handleError } from '../../../API.js';
import { Alert } from '../../Alert.js';

export const PTExtra = (props) => {

  let context = useContext(PersonContext);
  let apiUrl = '/persons/' + context.person.personId.externalId + '/myactic/benefits/pt/extra';
  let formDataDefault = {};
  
  let successMessage = { headline: i18next.t('MyActic.PTextraSent'), message: i18next.t('MyActic.PTextraSentText') };
  let now = Math.floor(Date.now() / 1000);
  let remainingCalculated = context.myactic.loyaltyPTExtraAvailable - context.myactic.loyaltyPTExtraBooked;
  let expires = new Date(context.myactic.loyaltyPTExtraExpires).toISOString().split("T")[0];

  let [ isSubmitting, setSubmitting ] = useState(false);
  //let [ invalid, setInvalid ] = useState({});
  let [ data, setData ] = useState(formDataDefault);
  let [ used, setUsed ] = useState(false);
  let [remaining, setRemaining] = useState(remainingCalculated);
  let [showSuccessMessage, setShowSuccessMessage] = useState(false);
  /*
  const onChange = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
      return;
    }

    if (typeof invalid[e.target.name] !== 'undefined') {
      setInvalid({ ...invalid, [e.target.name]: false });
    }

    setData({ ...data, [e.target.name]: e.target.value });
  };
  */

  const handleSubmit = (e) => {
    if (typeof e !== 'undefined' && typeof e.preventDefault !== 'undefined') {
      e.preventDefault();
    }

    /*
    let invalid = {};
    for (let keyName of Object.keys(data)) {
      if (typeof data[keyName] === 'undefined' || data[keyName] === '') {
        invalid[keyName] = true;
      }
    }

    if (Object.keys(invalid).length) {
      setInvalid(invalid);
      return false;
    } else {
      setInvalid(false);
    }
    */

    setSubmitting(true);
    api_post(apiUrl, data)
      .then((resp) => {
        setSubmitting(false);
        if (parseInt(resp.result) === 1) {
          setUsed(true);
          setData(formDataDefault);
          setRemaining(resp.left || 0);
          Alert(successMessage);
          setShowSuccessMessage(true);
          if (typeof resp.myactic !== 'undefined') {
            context.setMyActic(resp.myactic);
          }
        } else {
          if (typeof resp.reason !== 'undefined') {
              handleError({error: "MyActicPT." + resp.reason + (typeof resp.param!=='undefined' && resp.param ? '-'+resp.param : '')});
          }
        }
      })
      .catch((err) => {
        handleError(err);
        setSubmitting(false);
      });
  };
  
    return (
    <Translation>
          {(t) => (
            <div className={"benefit-wrapper " + (isSubmitting ? 'form-disabled' : '')}>
                <p>{t("MyActic.PTextraText")}</p>
                <p>{t("MyActic.NumLeft")}: {remaining}</p>
                {showSuccessMessage ? <p>{successMessage.message}</p> : null}
                {!remaining && !showSuccessMessage ? <p>{t("MyActic.PTextraSorry")}</p> : null}
                {expires > now && remaining > 0 ? <p>{t("MyActic.ValidUntil")} : {expires}</p> : null}

                {used || props.inactive || !remaining ? null : (
                  <div>
                    <div className="text-center mb-small mt-small">
                      <button className="btn secondary-btn color-secondary" onClick={handleSubmit} disabled={isSubmitting ? true : false}>
                        {t('MyActic.SendBooking')}
                      </button>
                    </div>
                  </div>
                )}
            </div>
          )}
        </Translation>
      );
};