import React from "react"; // eslint-disable-line

export const CenterDropdown = ({}) => { // eslint-disable-line
    return null;
    /*
    return (
        <div className="mb-small">
            <h2>Mitt hemmagym</h2>
            <div className="form-group">
                <div>
                    <div className="input-group icon-search mb-0">
                        <input type="text" />
                    </div>
                </div>
            </div>
        </div>
    );
    */
};
