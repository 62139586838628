import React from 'react';
import { Balance } from '../components/settings/Balance.js';
import { Container } from '../components/Components.js';
import { TopContent } from '../components/TopContent.js';
import { Translation } from 'react-i18next';
import { NavTop } from '../components/Nav.js';
import Breadcrumbs from '../components/Breadcrumb.js';

export const PageBalance = () => {
  return (
    <Translation>
      {(t) => (
        <>
          <NavTop />
          <Breadcrumbs/>
          <TopContent h2="EPASSI" />
          <Container>
            <Balance/>
          </Container>
        </>
      )}
    </Translation>
  );
};


