import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import PersonContext from "../../PersonContext";
import ChangeContext from "../../ChangeContext";
import { DateFormat } from "../Components.js";
import {Alert} from '../Alert';
import { SmallList } from "../SmallList.js";
import { SmallListItem } from "../SmallListItem.js";

import {numberFormat} from "../../lib/numberFormat";

export const ConfirmationScreen = ({
  postSubscriptionChange,
  isSubmitting,
  setShowConfirm,
}) => {
  const { t } = useTranslation();
  const personContext = useContext(PersonContext);
  const changeContext = useContext(ChangeContext);

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptPersonalData, setAcceptPersonalData] = useState(false);
  let center = changeContext.selectedCenter;
  let subscription = changeContext.selectedSubscription;

  return (
    <React.Fragment>
      <div className="text-center">
        <h2 className="mb-small">{t("ChangeSubscription.ConfirmHeadline")}</h2>
      </div>

      <div className="">
        <SmallList headline={t("Subscriptions.PersonType")} className="list-small-xs">
          <SmallListItem
            text={t("PersonTypeLabels." + personContext.person.personType)}
          />
        </SmallList>
        <SmallList headline={t("MemberInfo.Center")} className="list-small-xs">
          <SmallListItem text={center.name} />
        </SmallList>
        <SmallList
          headline={t("Subscriptions.AccessLevel")}
          className="list-small-xs"
        >
          <SmallListItem text={t(subscription.cardArea)} />
        </SmallList>
        <SmallList headline={t("MemberInfo.Membership")} className="list-small-xs">
          {subscription.cardIncludes.map((feature, index) => {
            return <SmallListItem key={index} text={feature} />;
          })}
        </SmallList>
      </div>


      <div className="d-flex justify-content-between align-items-center input-group border-bottom-color-sand">
          <div><span className="body-text">{t("ChangeSubscription.NewPrice")}</span></div>
          <div>{<Price price={subscription.periodPrice} /> }</div>
      </div>

      <div className="mb-small">
        <p className="body-text-small color-rock pt-3">
          {t("ChangeSubscription.UpgradeConfirmDescriptionText", {
            startDate: DateFormat(subscription.startDate),
          })}
        </p>
      </div>

      <div className="d-flex justify-content-start align-items-center">
        <div className="input-group">
            <div className="form-check-switch">
                <input className="form-check-input-switch accept-required" type="checkbox" name="acceptTerms" value="yes" onChange={(e) => {
                  if (e.currentTarget.checked) {
                    setAcceptTerms(true);
                  } else {
                    setAcceptTerms(false);
                  }
                }} />
                <label className="caption-text m-0" dangerouslySetInnerHTML={{__html: t("ChangeSubscription.AcceptTerms")}}></label>
            </div>
        </div>
      </div>

      <div className="d-flex justify-content-start align-items-center mb-small">
        <div className="input-group">
            <div className="form-check-switch">
                <input className="form-check-input-switch accept-required" type="checkbox" name="acceptPersonalData" value="yes" onChange={(e) => {
                  if (e.currentTarget.checked) {
                    setAcceptPersonalData(true);
                  } else {
                    setAcceptPersonalData(false);
                  }
                }} />
                <label className="caption-text m-0" dangerouslySetInnerHTML={{__html: t("ChangeSubscription.AcceptPersonalData")}}></label>
            </div>
        </div>
      </div>

      <div>
          <span className="body-text-small color-rock">{t("ChangeSubscription.SmallPersonalDataInfo")}</span>
        </div>

        <div className="text-center mt-small mb-5">
            <button
              disabled={isSubmitting ? true : false}
              className={
                "btn primary-btn color-primary mx-4"
                + (isSubmitting ? " disabled" : "")
              }
              onClick={() => {
                if (isSubmitting) {
                  return;
                }
                if (acceptTerms && acceptPersonalData) {
                  postSubscriptionChange();
                } else {
                  Alert(t("ChangeSubscription.MustAcceptTerms"));
                }
              }}
            >
              {t("Alert.Confirm")}
            </button>
          </div>
          <div className="text-center mb-small">
          <button
            disabled={isSubmitting ? true : false}
            className={
              "btn secondary-btn color-secondary mx-4"
              + (isSubmitting ? " disabled" : "")
            }
            onClick={() => {
              if (isSubmitting) {
                return;
              }
              setShowConfirm(false);
            }}
          >
            {t("Alert.Cancel")}
          </button>
        </div>

    </React.Fragment>
  );
};


const Price = ({price}) => {
  var countrycode = 'NO';

  if (countrycode === 'NO') {
      return <span><span className="h3 mb-0">{Math.round(price)}</span><span className="body-text ms-2">kr/mnd</span></span>;
  } else if (countrycode === 'DE' || countrycode === 'FI') {
      return <span><span className="h3 mb-0">{numberFormat(price, 2, '.', '')}</span><span className="body-text ms-2">&euro;/Mon</span></span>;
  } else { // SE
      return <span><span className="h3 mb-0">{Math.round(price)}</span><span className="body-text ms-2">kr/mån</span></span>;
  }
}