import React, { useContext, useState } from 'react';
import { Link } from "react-router-dom";
import { Translation, Trans } from 'react-i18next';
import i18next from 'i18next';
import PersonContext from '../../../PersonContext.js';
import { api_post, handleError } from '../../../API.js';
import { Alert } from '../../Alert.js';
import {getContactURL} from "../../../config.js";

export const Freeze = (props) => {

  let context = useContext(PersonContext);
  let apiUrl = '/persons/' + context.person.personId.externalId + '/myactic/benefits/freeze';
  let formDataDefault = {'freeze-start': new Date().toISOString().split('T')[0], 'freeze-length': 20};
  let successMessage = { headline: i18next.t('MyActic.FreezeSent'), message: i18next.t('MyActic.FreezeSentText') };
  let nowDate = new Date();
  //let remainingCalculated= context.myactic.loyaltyPTAvailable - context.myactic.loyaltyPTBooked;
  //let UsedAt = new Date(context.myactic.loyaltyFreezeUsedAt).toISOString().split("T")[0];


  let [ isSubmitting, setSubmitting ] = useState(false);
  let [ invalid, setInvalid ] = useState({});
  let [ data, setData ] = useState(formDataDefault);
  let [ used, setUsed ] = useState(false);
  //let [remaining, setRemaining] = useState(remainingCalculated);
  //let [showSuccessMessage, setShowSuccessMessage] = useState(false);

  let freezeActive = false;
  let currentFreezeStart = false;
  let currentFreezeEnd = false;
  if (typeof context.subscription.freeze !== 'undefined' && typeof context.subscription.freeze.startDate !== 'undefined') {
    currentFreezeStart = new Date(context.subscription.freeze.startDate);
    currentFreezeEnd = new Date(context.subscription.freeze.endDate);
    freezeActive = true;
  }

  


  const onChange = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
      return;
    }

    if (typeof invalid[e.target.name] !== 'undefined') {
      setInvalid({ ...invalid, [e.target.name]: false });
    }

    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    if (typeof e !== 'undefined' && typeof e.preventDefault !== 'undefined') {
      e.preventDefault();
    }

    let invalid = {};
    for (let keyName of Object.keys(data)) {
      if (typeof data[keyName] === 'undefined' || data[keyName] === '') {
        invalid[keyName] = true;
      }
    }

    if (Object.keys(invalid).length) {
      setInvalid(invalid);
      return false;
    } else {
      setInvalid(false);
    }

    setSubmitting(true);
    api_post(apiUrl, data)
      .then((resp) => {
        setSubmitting(false);
        if (parseInt(resp.result) === 1) {
            setUsed(true);
            setData(formDataDefault);
            context.setPersonData({ subscription: resp.subscription });
            context.setMyActic(resp.myactic);
            Alert(successMessage);
            //setShowSuccessMessage(true);
            if (typeof resp.myactic !== 'undefined') {
              context.setMyActic(resp.myactic);
            }
        } else {
            if (typeof resp.msg === 'string') {
                handleError(resp.msg);
            } else if (typeof resp.reason !== 'undefined') {
                handleError({error: "MyActicFreeze." + resp.reason + (typeof resp.param!=='undefined' && resp.param ? '-'+resp.param : '')});
            }
        }
      })
      .catch((err) => {
        handleError(err);
        setSubmitting(false);
      });
  };
  let dayints = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];

    return (
      <Translation>
      {(t) => (
        <div className={"benefit-wrapper " + (isSubmitting ? 'form-disabled' : '')}>
            <p>{t("MyActic.FreezeText")}</p>
            <p>{t("MyActic.FreezeText2")}</p>
            {
                i18next.exists("MyActic.FreezeText3")
                ? (
                    <p>
                        <Trans i18nKey="MyActic.FreezeText3">
                            If you have any questions you are welcome to <a className="color-primary" href={getContactURL()} target="_blank" rel="noreferrer">contact us</a>.
                        </Trans>
                    </p>
                ) : null
            }
            {
                used || props.inactive || freezeActive
                ? (
                    <React.Fragment>
                        <p>
                            {(freezeActive && currentFreezeStart < nowDate) ? (t("MyActic.FreezeTo", {enddate: currentFreezeEnd.toISOString().split("T")[0]})) : null}
                            {(freezeActive && currentFreezeStart > nowDate) ? (t("MyActic.FreezeSoon", {startdate: currentFreezeStart.toISOString().split("T")[0]})) : null}
                            {" "}
                            <Trans i18nKey="MyActic.FreezeCancel">
                                If you want to cancel your account freeze, <Link to="/medlemskap/frysa-medlemskap">click here</Link>.
                            </Trans>
                        </p>
                    </React.Fragment>
                )
                :
                (
                    <div>
                        <div>
                            <label className="h4">{t('MyActic.FreezeStart')}</label>
                            <div className="input-group">
                                <input
                                type="date"
                                className={invalid['freeze-start'] ? 'invalid' : ''}
                                name="freeze-start"
                                value={data['freeze-start']}
                                disabled={isSubmitting ? true : false}
                                onChange={onChange}
                                placeholder={t('MyActic.FreezeStart')}
                                />
                            </div>
                        </div>

                        <div>
                            <label className="h4 mt-3">{t('MyActic.FreezeDays')}</label>
                            <div className="input-group">                        
                                <select
                                    className={invalid["freeze-length"] ? 'invalid' : ''}
                                    name="freeze-length"
                                    value={data["freeze-length"]}
                                    disabled={isSubmitting ? true : false}
                                    onChange={onChange}
                                >
                                    <option value="">{t('MyActic.FreezeDays')}</option>
                                    {
                                    dayints.map((dayint) => {
                                        return (
                                        <option key={dayint} value={dayint}>{dayint}</option>
                                        );
                                    })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="text-center mb-small mt-small">
                            <button className="btn secondary-btn color-secondary" onClick={handleSubmit} disabled={isSubmitting ? true : false}>
                                {t('MyActic.FreezeSend')}
                            </button>
                        </div>
                    </div>
                )
            }
        </div>
      )}
    </Translation>
      );
};