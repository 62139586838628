import React from "react";
import { Freeze } from "../components/settings/Freeze.js";
import { Container } from "../components/Components.js";
import { TopContent } from "../components/TopContent.js";
import { Translation } from "react-i18next";
import { NavTop } from "../components/Nav.js";
import Breadcrumbs from "../components/Breadcrumb.js";

export const PageFreeze = () => {
  return (
    <Translation>
      {(t) => (
        <>
          <NavTop />
          <Breadcrumbs/>
          <TopContent h2={t("Settings.FreezeHeadline")} />
          <Container>
            <Freeze />
          </Container>
        </>
      )}
    </Translation>
  );
};
