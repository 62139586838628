import React, { useContext } from "react";
import { Translation } from "react-i18next";
import { Section, Container } from "../Components.js";
import PersonContext from "../../PersonContext.js";
import {getDomainCountry} from "../../config.js";
//import { Loading, CenterName } from '../Components.js';

export const WelcomeModal = (props) => {
    let context = useContext(PersonContext);
    let domainCountry = getDomainCountry();

    let content = '';
    if (domainCountry === 'SE') {
        content = <WelcomeModalSE />;
    } else if (domainCountry === 'NO') {
        content = <WelcomeModalNO />;
    } else if (domainCountry === 'DE') {
        content = <WelcomeModalDE />;
    }

    return (
        <Translation>
            {(t) => (
                <React.Fragment>
                    <div className="top-content mb-small">
                        <h2>{t("WelcomeToMyPages")}</h2>
                    </div>
                    <div className="modal-content">
                        
                        {content}

                        <div className="text-center mt-small">
                            <button
                                className="btn secondary-btn color-secondary"
                                onClick={() => {
                                    context.openModal(false);
                                }}
                            >
                                {t("Alert.OK")}
                            </button>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </Translation>
    );
};

const WelcomeModalSE = () => {
    return (
        <React.Fragment>
            <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-12 col-xxl-12 mx-auto mb-5">
                                    <p className="body-text-small">
                                        Söker du friskvårdskvitto är det här du
                                        hittar det, tillsammans med en hel del
                                        annat bra och kul information. Boka
                                        pass, se dina poäng, träningsstatistik
                                        och dina personliga uppgifter.
                                    </p>
                                    <p className="body-text-small">
                                    Observera att beloppet på din första betalning kommer vara högre då vi tillämpar förskottsbetalning på Actic, du betalar då för innevarande månad + kommande månad.
                                    </p>
                                </div>
                            </div>
                        </div>

                            <Container col="col-12 col-md-12 col-xxl-12">
                                <h6>Kortbetalning eller autogiro</h6>
                                <p className="body-text-small mt-3">
                                Du vet väl att du när som helst kan byta till den betalmetod du tycker är smidigast. Du gör det enkelt här på ”Mina sidor”. Där finns även en del andra inställningar och information som kan vara bra för dig att ha koll på.
                                </p>
                                
                            </Container>
               
                </React.Fragment>
    );
}

const WelcomeModalNO = () => {
    return (
        <React.Fragment>
            <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-12 col-xxl-12 mx-auto">
                                    <p className="body-text-small">
                                    Her finner du informasjon om medlemskapet ditt. Book timer, følg med på treningsstatistikken din, rediger opplysninger og få svar på ofte stilte spørsmål.
                                    </p>
                                    <p className="body-text-small">
                                    Vi ber deg om å kontrollere at personopplysningene dine stemmer. Vi er ikke koblet til folkeregisteret, og du er dermed selv ansvarlig for å gi oss beskjed om eventuell ny adresse, telefonnummer eller liknende.
                                    </p>
                                    <p className="body-text-small">
                                    Vi gjør oppmerksom på at den første fakturaen din vil være høyere enn månedsavgiften din dersom du melder deg inn en annen dag enn den 1. i måneden. Det er fordi vi praktiserer forskuddsvis betaling, og trekker for inneværende og påfølgende måned når du melder deg inn.
                                    </p>
                                </div>
                            </div>
                        </div>
        </React.Fragment>
    );
}

const WelcomeModalDE = () => {
    return (
        <React.Fragment>
            <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-12 col-xxl-12 mx-auto">
                                    <p className="body-text-small">
                                    Hier findest du viele wichtige Informationen und Möglichkeiten 
                                    rund um deine Mitgliedschaft. Drucke dir einen Nachweis für deine
                                    Krankenkasse/deinen Arbeitgeber aus, buche Kurse, erkundige dich 
                                    nach deiner Trainingsstatistik oder verwalte deine persönlichen 
                                    Daten und Zahlungsinformationen.
                                    </p>
                                    <p className="body-text-small">
                                    Bitte beachte, dass der Betrag deiner ersten Rechnung 
                                    etwas höher ist, da hier der angefangene vorherige Monat 
                                    sowie die Vorauszahlung für den kommenden Monat anfällt.
                                    </p>
                                    <p className="body-text-small">
                                    Wir wünschen dir viel Spaß beim Erkunden von „Meine Seiten“.
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                </React.Fragment>
    );
}
