import React, { useState, useContext, useEffect } from "react";
import { getDomainCountry } from "./config.js";
import { useParams } from "react-router-dom";
import { Translation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { CardLink } from "./components/Components.js";
import i18next from "i18next";
import { NavTop, NavBar } from "./components/Nav.js";
import { TopContent } from "./components/TopContent.js";
import { Section, Container, OfferCarousel } from "./components/Components.js";
import { Confirm } from "./components/Confirm.js";
import PersonContext from "./PersonContext.js";
import { Subscription } from "./components/settings/Subscription.js";
import { Details } from "./components/settings/Details.js";
import { TextBold } from "./components/TextBold.js";
import { Button } from "./components/Button.js";
import { Loading } from "./components/Components.js";
import { api_get } from "./API.js";

export const PageSettings = (props) => {
  let domainCountry = getDomainCountry();
  let memberURL = "https://www.actic.se/bli-medlem/";
  if (domainCountry === "NO") {
    memberURL = 'https://www.actic.no/bli-medlem/';
  }
  let context = useContext(PersonContext);
  let personContext = useContext(PersonContext);
  let params = useParams();
  let [loading, setLoading] = useState(true);
  let [disableSettings, setDisableSettings] = useState([]);
  let [personSettings, setPersonSettings] = useState([]);
  let [loadingMyActic, setLoadingMyActic] = useState(true);
  let [errors, setErrors] = useState(false);
  let hideEpassi = false;
  let [courses, setCourses] = useState([]);
  useEffect(() => {
    document.title =
      i18next.t("Headline.Settings") + " - " + i18next.t("SiteName");
    if (personContext.myactic) {
      setLoadingMyActic(false);
    } else {
      getMyActic();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    api_get(
      "/persons/" + context.person.personId.externalId + "/settings"
    ).then((resp) => {
      if (resp.success) {
        setDisableSettings(resp.disableSettings);
        setPersonSettings(resp.personSettings);
      } else {
        setErrors(true);
      }
      setLoading(false);
    });
  }, []); // eslint-disable-line

  const getMyActic = () => {
    personContext
      .getMyActic()
      .then((loadingDone) => {
        // done
        if (loadingDone) {
          setLoadingMyActic(false);
        } else {
          setTimeout(() => {
            getMyActic();
          }, 2000);
        }
      })
      .catch(() => {
        // error
        Confirm({
          message: i18next.t("Errors.FetchMyActicErrorRetry"),
          onConfirm: () => {
            setLoadingMyActic(true);
            getMyActic();
          },
        });
        setLoadingMyActic(false);
      });
  };

  let hasSubscription = personContext.subscription ? true : false;
  let canSeeInvoices = true;

  // Temp för Västerås - City
  let canCancelSubscription = false;
  if (personContext.center.centerId !== 179) {
    canCancelSubscription = true;
  }

  //let memberId = personContext.person.personId.center + 'p' + personContext.person.personId.id;
  let canSeeChangeSection = false;
  if (
    personContext.center.countrycode === "SE" ||
    personContext.center.countrycode === "NO"
  ) {
    canSeeChangeSection = true;
  }

  const personalCampaigns = personContext?.personalCampaigns?.personalCampaigns ? personContext.personalCampaigns.personalCampaigns : [];

  const unusedCampaigns = personalCampaigns.filter((campaign) => {
    return campaign.status === 'unused';
  });

  function isSettingDisabledForCenter($key) {
    if (disableSettings === null || disableSettings === undefined) {
      return false;
    }
    return disableSettings.includes($key);
  }

  function havePersonalSetting($key) {
    if(personSettings === null || personSettings === undefined) {
      return false;
    }
    
    return personSettings.includes($key);
  }

  let translations = {
    "change-card-details": {
      SE: "andra-kortuppgifter",
      NO: "endre-kortdetaljer",
    },

    "change-membership": {
      SE: "andra",
      NO: "endre",
    },
    autogiro: {
      SE: "autogiro",
      NO: "avtalegiro",
    },
    courses: {
      SE: "kurser",
      NO: "kurs",
    },

    "stop-membership": {
      SE: "uppsagning-av-medlemskap",
      NO: "oppsigelse-av-medlemskap",
    },
    "change-password": {
      SE: "andra-losenord",
      NO: "endre-passord",
    },
    contracts: {
      SE: "avtal",
      NO: "avtale",
    },
    contribution: {
      SE: "friskvardskvitto",
      NO: "helsehjelp-kvittering",
    },
    invoices: {
      SE: "mina-betalningar",
      NO: "mine-betalinger",
    },
    freeze: {
      SE: "frysa-medlemskap",
      NO: "fryse-medlemskap",
    },
    "comming-courses": {
      SE: "kommande-kurser",
      NO: "kommende-kurs",
    },
    pt: {
      SE: "klippkort",
      NO: "klippekort",
    },
    "my-actic-benefits": {
      SE: "medlemsformaner",
      NO: "medlemsskjemaer",
    },
    balance: {
      SE: "epassi",
      NO: "epassi",
    },
    log: {
      SE: "aktivitetslogg",
      NO: "aktivitetsloggen",
    },
    statistics: {
      SE: "statistik",
      NO: "statistikk",
    },
    booking: {
      SE: "boka-pass",
      NO: "book-time",
    },
    'personal-campaign': {
      'SE': 'personliga-kampanjer',
      'NO': 'personlige-kampanjer',
    },
  };

  let lang = getDomainCountry();

  return (
    <Translation>
      {(t) => (
        <React.Fragment>
          <NavTop />
            {!loading && havePersonalSetting('haveK5Campaign') && (
                <OfferCarousel onClick={() => { context.openModal("recruit-cash"); }} title={t("Recruit._version._cash.CarouselButton")} />
            )}

            {!loading && havePersonalSetting('inviteFriendAllowed') && (
                <OfferCarousel onClick={() => { context.openModal("recruit-gt"); }} title={t("Recruit._version._gt.CarouselButton")} />
            )}
            {!loading && !havePersonalSetting('inviteFriendAllowed') && !havePersonalSetting('haveK5Campaign') && (
                <OfferCarousel onClick={() => { context.openModal("recruit"); }} title={t("Headline.Recruit")} />
            )}
          <TopContent
            className="color-light-sand"
            h1={t("Headline.Membership")}
          />

          {loading ? (
            <div className="container d-flex justify-content-center">
              <Loading />
            </div>
          ) : null}

          {!loading && errors ? (
            <div className="container d-flex justify-content-center">
              Någonting gick fel, försök igen.
            </div>
          ) : null}

          {!loading && !errors ? (
            <div className="wrapper-settings">
              {/* Underhållsmedelande */}
              {personContext.maintenanceMessage ? (
                <Section headline={t("Settings.MessageHeadline")} open={true}>
                  <Container>
                    <div className="mb-small mt-3">
                      <p className="text-large">
                        {personContext.maintenanceMessage}
                      </p>
                    </div>
                  </Container>
                </Section>
              ) : null}

              {/* Mina uppgifter */}
              {!isSettingDisabledForCenter("my_details") ? (
                <Container>
                  <Details />
                </Container>
              ) : null}

              {/* Mitt medlemskap */}
              {canSeeChangeSection &&
              !isSettingDisabledForCenter("my_membership") ? (
                <Container>
                  {hasSubscription ? (
                    <React.Fragment>
                      <div className="mt-5 d-flex justify-content-between align-items-center mb-3">
                        <h2 className="mb-0">
                          {t("Settings.SubscriptionHeadline")}
                        </h2>

                        {personContext.newSubscription ? (
                          personContext.newSubscription.startDate ===
                          new Date().toISOString().split("T")[0] ? (
                            <TextBold
                              text={t(
                                "ChangeSubscription.SubscriptionChangedInfo"
                              )}
                            />
                          ) : (
                            <TextBold
                              text={t(
                                "ChangeSubscription.SubscriptionChangedInfoDate",
                                {
                                  startDate:
                                    personContext.newSubscription.startDate,
                                }
                              )}
                            />
                          )
                        ) : personContext.subscription.product.type ===
                          "CASH" ? null : (
                          <div>
                            <Button
                              action={translations["change-membership"][lang]}
                              text={t("ChangeSubscription.Change")}
                              className="btn secondary-btn color-primary size-small"
                            />
                          </div>
                        )}
                      </div>

                      <div className="border-card">
                        <Subscription />
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="d-flex justify-content-center mt-4 mb-4">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t("Settings.HasNoSubscription", {
                            url: memberURL,
                          }),
                        }}
                      ></p>
                    </div>
                  )}
                </Container>
              ) : null}

              {/* Mina avtal */}
              {hasSubscription &&
              !isSettingDisabledForCenter("my_agreements") ? (
                <Link to={translations["contracts"][lang]}>
                  <Container>
                    <CardLink icon>
                      <span>{t("Settings.ContractsHeadline")}</span>
                    </CardLink>
                  </Container>
                </Link>
              ) : null}

              {/* Mina Betalningar */}
              {canSeeInvoices && !isSettingDisabledForCenter("my_payments") ? (
                <Link to={translations["invoices"][lang]}>
                  <Container>
                    <CardLink icon>
                      <span>{t("Settings.InvoicesHeadline")}</span>
                    </CardLink>
                  </Container>
                </Link>
              ) : null}

              {/* Friskvårdskvitto */}
              {canSeeInvoices &&
              !isSettingDisabledForCenter("wellness_receipt") ? (
                <Link
                  className={domainCountry === "NO" ? "d-none container" : null}
                  to={translations["contribution"][lang]}
                >
                  <Container>
                    <CardLink icon>
                      <span>{t("Settings.ContributionHeadline")}</span>
                    </CardLink>
                  </Container>
                </Link>
              ) : null}

              {/* Medlemsförmåner */}
              {getDomainCountry() === "SE" &&
              !isSettingDisabledForCenter("membership_benefits") ? (
                <Container>
                  <Link to={translations["my-actic-benefits"][lang]}>
                    <CardLink icon>
                      <span>Medlemsförmåner</span>
                    </CardLink>
                  </Link>
                </Container>
              ) : null}

              {/* Personliga kampanjer */}
              {getDomainCountry() === "SE" &&
              !isSettingDisabledForCenter("personal_campaigns") ? (
             
                  <Container>
                  
                  <Link to={translations['personal-campaign'][lang]}>
                        <CardLink icon badge={unusedCampaigns.length}>
                          <div className="d-flex">
                            <span>Personliga kampanjer</span>
                            { unusedCampaigns.length > 0 ? <div className="card-badge">{unusedCampaigns.length}</div> : null }
                          </div>
                        </CardLink>
           
                    </Link>
                  </Container>
      
              ) : null}

              <Container>
                <React.Fragment>
                  <h2 className="mt-5">{t("Headline.Services")}</h2>
                </React.Fragment>
              </Container>

              {/* Frysa medlemskap && Uppsägning av medlemskap*/}
              {hasSubscription ? (
                <React.Fragment>
                  {/* Frysa medlemskap */}
                  {!isSettingDisabledForCenter("freeze_membership") ? (
                    <Link to={translations["freeze"][lang]}>
                      <Container>
                        <CardLink icon>
                          <span>{t("Settings.FreezeHeadline")}</span>
                        </CardLink>
                      </Container>
                    </Link>
                  ) : null}

                  {/* Uppsägning av medlemskap */}
                  {canCancelSubscription &&
                  !isSettingDisabledForCenter("membership_cancellation") ? (
                    <Link to={translations["stop-membership"][lang]}>
                      <Container>
                        <CardLink icon>
                          <span>{t("Settings.CancelHeadline")}</span>
                        </CardLink>
                      </Container>
                    </Link>
                  ) : null}
                </React.Fragment>
              ) : null}

              {/* Ändra lösenord */}
              {!isSettingDisabledForCenter("change_password") ? (
                <Link to={translations["change-password"][lang]}>
                  <Container>
                    <CardLink icon>
                      <span>{t("Settings.PasswordHeadline")}</span>
                    </CardLink>
                  </Container>
                </Link>
              ) : null}

              {/* Autogiro */}
              {!isSettingDisabledForCenter("autogiro") ? (
                <Link to={translations["autogiro"][lang]}>
                  <Container>
                    <CardLink icon>
                      <span>{t("Settings.AutogiroHeadline")}</span>
                    </CardLink>
                  </Container>
                </Link>
              ) : null}

              {/* Epassi */}
              {getDomainCountry() === "SE" &&
              hideEpassi === false &&
              !isSettingDisabledForCenter("epassi") ? (
                <Link to={translations["balance"][lang]}>
                  <Container>
                    <CardLink icon>
                      <span>Betala med EPASSI</span>
                    </CardLink>
                  </Container>
                </Link>
              ) : null}

              {/* Ändra Kortuppgifter */}
              {!isSettingDisabledForCenter("change_card_details") ? (
                <Link to={translations["change-card-details"][lang]}>
                  <Container>
                    <CardLink icon>
                      <span>{t("Settings.AgreementHeadline")}</span>
                    </CardLink>
                  </Container>
                </Link>
              ) : null}

              <Container>
                <React.Fragment>
                  <h2 className="mt-5">{t("Cart.CartHeadline")}</h2>
                </React.Fragment>
              </Container>
              {/* Kurser */}
              {!isSettingDisabledForCenter("courses") ? (
                <Link to={translations["courses"][lang]}>
                  <Container>
                    <CardLink icon>
                      <span>Kurser</span>
                    </CardLink>
                  </Container>
                </Link>
              ) : null}

              {/* Klippkort */}
              {!isSettingDisabledForCenter("clipcards") ? (
                <Link to={translations["pt"][lang]}>
                  <Container>
                    <CardLink icon>
                      <span>{t("Settings.ClipcardHeadline")}</span>
                    </CardLink>
                  </Container>
                </Link>
              ) : null}

              {/* Kurser med förtur Här vill man bara se sektionen om det finns kurser med förtur*/}
              {getDomainCountry() === "SE" &&
              !isSettingDisabledForCenter("courses_with_pre-requisites") &&
              courses.length == 0 ? (
                <>
                <Container>
                <React.Fragment>
                  <h2 className="mt-5">Erbjudanden</h2>
                </React.Fragment>
              </Container>
                <Link to={translations["comming-courses"][lang]}>
                  <Container>
                    <CardLink icon>
                      <span>Kurser med förtur</span>
                    </CardLink>
                  </Container>
                </Link>
                </>
              ) : null}
            </div>
          ) : null}
          <NavBar />
        </React.Fragment>
      )}
    </Translation>
  );
};
