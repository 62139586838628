import React from 'react';
import { Translation } from 'react-i18next';
import { ClassCard } from './ClassCard.js';

export const DayParticipations = (props) => {
  //let currentDayStartTime = props.startDay + 86400 * props.daynum;
  let date = new Date(props.date);
  let dateIso = props.date;
  let hasClasses = (typeof props.classes[dateIso] !== 'undefined' && props.classes[dateIso].length) ? true : false;
  
  if (hasClasses) {
    props.classes[dateIso].sort((a, b) => {
        let sortValueA = a.timestamp + a.name;
        let sortValueB = b.timestamp + b.name;
        return sortValueA > sortValueB ? 1 : -1;
      }
    );
  } else {
    return null;
  }
  
  let withParticipations = props.classes[dateIso].filter((r) => { return typeof r.participation !== 'undefined' ? true : false; });
  if (!withParticipations.length) {
    return null;
  }

  return (
    <Translation>
      {(t) => {
        return (
          <div className="day-container">
            <div className="day-header">
              <span className="mb-0 d-block h4 color-black">{t('DayNames.' + date.getDay())}</span>
              <span className="caption-text-regular d-block color-rock mb-2">
                {date.getDate()} {t('MonthNames.' + date.getMonth())}
              </span>
            </div>
            {
            hasClasses
            ? props.classes[dateIso].map((item, index) => {

                if (typeof item.participation == 'undefined' || !item.participation) {
                  return null;
                }
                return <ClassCard {...props} item={item} key={index} t={t} />;
              })
            : null
          }
          </div>
        );
      }}
    </Translation>
  );
};
