import React, { useState, useContext, useEffect } from 'react';
import { Translation } from 'react-i18next';
//import i18next from 'i18next';
import { api_get } from '../../API.js';
import PersonContext from '../../PersonContext.js';
import { Loading } from '../Components.js';
import {getApiHost} from '../../config.js'

export const Contracts = (props) => {
  let context = useContext(PersonContext);
  let [ items, setItems ] = useState([]);
  let [ isLoading, setLoading ] = useState(true);



  useEffect(() => {
    if(context?.person?.personId?.externalId == null) {
        return;
    }
    api_get(
      api_get('/persons/' + context.person.personId.externalId + '/contracts')
    ).then((resp) => {
      setItems(resp);
      setLoading(false);
    });
}, [context]); // eslint-disable-line

  return (

  
    <Translation>
      {(t) => (
        isLoading 
        ? <div className="d-flex justify-content-center mt-4 mb-4"><Loading>{t('Settings.LoadingDetails')}</Loading></div> 
        : (
          <div className='border-card'>
          <ul className="list">
            {
              items.map((item, index) => {
                return <ListItem item={item} key={index} />;
              })
            }
          </ul>
          </div>
        )
      )}
    </Translation>

    );
};

const ListItem = (props) => { 
  let context = useContext(PersonContext);
  let item = props.item;
  return (
    <Translation>
            {(t) => (
    <li>
      <div>
        <div>
          <span className="body-text color-black">{t('Settings.ContractsListHeadline')}</span>
        </div>
        <div className="body-text text-end">
          <span className="body-text-small">{item.creationDate}</span>
        </div>
        <div className="icon download  text-end">
          <a href={getApiHost()+"/persons/"+context.person.personId.externalId+"/contracts/"+item.key+"?AccessToken="+context.accessToken}><span></span></a>
        </div>
      </div>
    </li>
    )}
  </Translation>
  );
}
