import React, { useState } from 'react';
//import { Translation } from 'react-i18next';
import { CenterName } from '../Components.js';
import { Modal } from '../Modal.js';

import { ClassModal } from './ClassModal.js';

export const ClassCard = (props) => {
    //console.log("Update ClassCard", props);
    let [ infoOpen, setInfoOpen ] = useState(false);
    let participation = typeof props.item.participation !== 'undefined' ? props.item.participation : false;
    //console.log(props.item.bookingIdCompound, props.participations);
    let classStart = new Date(props.item.date + " " + props.item.startTime);
    let now = new Date();
    let classStartTimeHasPassed = classStart < now;
    let bookingState = (typeof props.item.bookingState !== 'undefined') ? props.item.bookingState : '';
    let isOutsideNoLimit = (props.item.secondsRemainingToStart > (props.booking_no_limit_hours_before_class*3600)) ? true : false;
    let notBookableOutsideNoLimit = (props.participations_count >= props.participations_max && isOutsideNoLimit && !participation) ? true : false;
    return (
      <React.Fragment>
        <div
          className={
            'class-info' 
            + (notBookableOutsideNoLimit ? ' unavailable-max-booked' : '')
            + (participation ? ' booked' : '')
            + (classStartTimeHasPassed ? ' passed' : '')
            + (bookingState === 'NOT_BOOKABLE_OUTSIDE_HOURS' ? ' booking-closed' : '')
            + (bookingState === 'NOT_BOOKABLE_DATE' ? ' booking-locked' : '')
          }
          onClick={() => {
            setInfoOpen(true);
          }}
        >
          <div>
            <span className="body-text-regular start-time">{props.item.startTime}</span>
            <span className="mb-2 caption-text-regular  duration color-black">{props.item.duration} min</span>
            <span className="class-name h4 color-black d-block">{props.item.name}</span>
            <span className="mb-3 center-name caption-text-regular d-block">
              <CenterName centerId={props.item.centerId} />
            </span>
            {
            !classStartTimeHasPassed && !notBookableOutsideNoLimit
            ? <BookingIndicator {...props} participation={participation} t={props.t} isOutsideNoLimit={isOutsideNoLimit} /> 
            : null
            }
          </div>
        </div>
        {infoOpen ? (
          <Modal close={() => { setInfoOpen(false); }} >
            <ClassModal {...props} participation={participation} />
          </Modal>
        ) : null}
      </React.Fragment>
    );
  };
  
  const BookingIndicator = (props) => {
    let bookingState = (typeof props.item.bookingState !== 'undefined') ? props.item.bookingState : '';
    if (props.participation) {
      if (props.participation.waitingListIndex > 0) {
        return (
          <div className="indicator queue">
            <p>{props.t("Booking.Spot")}: {props.participation.waitingListIndex}</p>
          </div>
        );
      } else {
        return (
          <div className="indicator booked">
            <p>{props.t("Booking.Booked")}</p>
          </div>
        );
      }
    } else {
      if (bookingState === 'NOT_BOOKABLE_OUTSIDE_HOURS') {
        return (
          <div className="indicator b-closed">
            <p></p>
          </div>
        );
      } else if (bookingState === 'NOT_BOOKABLE_DATE') {
        return (
          <div className="indicator b-locked">
            <p></p>
          </div>
        );
      //} else if (props.isOutsideNoLimit) {
      //  return (
      //    <div className="indicator prebookingfull">
      //      <p>{props.t("Booking.IsOutsideNoLimit")}</p>
      //      <p>{props.t("Booking.IsOutsideNoLimitReadMore")}</p>
      //    </div>
      //  );
      } else if (props.item.waitingListCount > 0) {
        return (
          <div className="indicator queue">
            <p>{props.t("Booking.Queue")}: {props.item.waitingListCount}</p>
          </div>
        );
      } else {
        if (props.item.limitedBookingFull) {
          return (
            <div className="indicator prebookingfull">
              <p>{props.t("Booking.PrebookingFull")}</p>
            </div>
          );
        } else {
          return (
            <div className="indicator available">
              <p>{props.t("Booking.Book")}</p>
            </div>
          );
        }
      }
    }
  };
  