export {findCurrentSubscription};

/*
 * Find the current subscription in the subscriptions array
 */
function findCurrentSubscription(subscriptions) {

  if (subscriptions.length) {

    // Look for local, area or max
    for (let sub of subscriptions) {
      if (['ACTIVE', 'TEMPORARYINACTIVE', 'FROZEN'].includes(sub.state) && ['local', 'area', 'max'].includes(sub.subscriptionType)) {
        return sub;
      }
    }

    // Look for outdoor
    for (let sub of subscriptions) {
      if (['ACTIVE', 'TEMPORARYINACTIVE', 'FROZEN'].includes(sub.state) && ['outdoor'].includes(sub.subscriptionType)) {
        return sub;
      }
    }

    // Look for anywhere
    for (let sub of subscriptions) {
      if (['ACTIVE', 'TEMPORARYINACTIVE', 'FROZEN'].includes(sub.state) && ['digital'].includes(sub.subscriptionType)) {
        return sub;
      }
    }

    // Look for bath
    for (let sub of subscriptions) {
      if (['ACTIVE', 'TEMPORARYINACTIVE', 'FROZEN'].includes(sub.state) && ['bath'].includes(sub.subscriptionType)) {
        return sub;
      }
    }

  }

  return false;
}