import React, { useState, useContext, useEffect } from 'react';
import { Translation } from "react-i18next";
import i18next from "i18next";
import PersonContext from "../../PersonContext.js";
import { Loading } from "../Components.js";
import { api_get, api_post, handleError } from "../../API.js";
import { Alert } from "../Alert.js";

export const Achievements = (props) => {
  let context = useContext(PersonContext);
  
  let [loaded, setLoaded ] = useState(false);
  let [retreiving, setRetreiving ] = useState(false);
  let [showMore, setShowMore ] = useState(false);
  let maxItems = 8;

  useEffect(() => {
    if (typeof context.person.personId === "undefined") {
      return;
    }
    if (context.achievements) {
        setLoaded(true);
    } else {
        api_get(
            "/persons/" +
                context.person.personId.externalId +
                "/achievements"
        )
            .then((resp) => {
                context.setAchievements(resp).then(() => {
                  setLoaded(true);
                });
            })
            .catch((resp) => {
                handleError(resp);
            });
            
    }

  }, []); // eslint-disable-line

  const getReward = (achievement) => {
    //console.log("getReward", achievement);
    if (achievement.id) {

      setRetreiving(achievement.id);

        api_post("/persons/" + context.person.personId.externalId + "/reward/achievement/" + achievement.id)
          .then((resp) => {

            //console.log("resp", resp);
            let achievements = context.achievements;
            
            for (let x in resp.achievements) {
              let achievementsIndex = Object.keys(achievements).find(key => achievements[key].id === resp.achievements[x].id);
              achievements[achievementsIndex] = resp.achievements[x];
            }
            
            let allAchievements = achievements.concat(context.achievementsCompleted);
            //console.log("getReward achievements", achievements);
            context.setAchievements({achievements: allAchievements}).then(() => {
              setRetreiving(false);
            });
            
          })
          .catch((resp) => {
            
            setRetreiving(false);
            Alert(i18next.t("Errors.FetchAchievementsError"));
            
          });

    }
  }

        return (
            <Translation>
                {(t) => (
                    <div className="achievements-wrapper">
                        {!loaded || !context.achievements ? (
                          <div className="d-flex justify-content-center mt-5">
                            <Loading>{t("Achievements.LoadingAchievements")}</Loading>
                            </div>
                        ) : (
                            <React.Fragment>
                            <p className="body-text-small mt-3">{t("Achievements.InfoText")}</p>
                            <ul className="list big list-achievements">
                                {context.achievements.map(
                                    (item, index) => {
                                        if (index >= maxItems && !showMore) {
                                          return null;
                                        }
                                        let width = item.percent_done + "%";
                                        return (
                                            <li key={item.id} className={retreiving===item.id ? 'retreiving' : ''}>
                                                <div>
                                                    <div>
                                                        <span className="body-text-regular color-black">
                                                            {item.title}
                                                        </span>
                                                        <span className="body-text-small color-rock">
                                                            {item.description}
                                                        </span>
                                                    </div>
                                                    <div className="text-end">
                                                        <span className="d-flex justify-content-end align-items-center mb-2">
                                                            <span className="h4 m-0 pe-1">{item.reward}</span>{" "}
                                                            <span className="body-text m-0">p</span>
                                                        </span>
                                                        <span className="achi-progress">
                                                            <span
                                                                data-width={
                                                                    item.percent_done
                                                                }
                                                                style={{
                                                                    width: width,
                                                                }}
                                                            ></span>
                                                        </span>
                                                    </div>
                                                    <div className="achi-get text-end">
                                                    {
                                                      item.completed && !item.reward_acquired
                                                      ? (
                                                        retreiving === item.id
                                                        ? <Loading />
                                                        :  <span className={retreiving!==false ? 'inactive' : ''} onClick={() => {
                                                            if (retreiving===false) {
                                                              getReward(item);
                                                            }
                                                          }}></span>
                                                      )
                                                      : null
                                                    }
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                            {
                              context.achievements.length > maxItems && !showMore
                              ? (
                                <div className="text-center mt-4 mb-small">
                                  <button className="btn secondary-btn color-secondary" onClick={() => { setShowMore(true) }}>{t("Common.ShowMore")}</button>
                                </div>
                              )
                              : null
                            }
                            
                            </React.Fragment>
                        )}
                    </div>
                )}
            </Translation>
        );
    
}

export const AchievementsCompleted = () => {
    let context = useContext(PersonContext);

    if (!context.achievementsCompleted) {
        return (
            <div className="d-flex justify-content-center">
                <p></p>
            </div>
        );
    }

    return (
        <Translation>
            {(t) => (
                <div className="achievements-wrapper">
                    <ul className="list big list-achievements">
                        {context.achievementsCompleted.map((item, index) => {
                            return (
                                <li key={item.id}>
                                    <div>
                                        <div>
                                            <span className="body-text-regular color-black">{item.title}</span>
                                            <span className="body-text-small color-rock">{item.description}</span>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </Translation>
    );
};
