import React from 'react';
import { Agreement } from '../components/settings/Agreement.js';
import { Container } from '../components/Components.js';
import { TopContent } from '../components/TopContent.js';
import { Translation } from 'react-i18next';
import { NavTop } from '../components/Nav.js';
import Breadcrumbs from '../components/Breadcrumb.js';


export const PageAgrement = (props) => {
  return (
    <Translation>
      {(t) => (
        <>
          <NavTop />
            <Breadcrumbs/>
          <TopContent h2={t("Settings.AgreementHeadline")} />
          <Container>
            <Agreement />
          </Container>
        </>
      )}
    </Translation>
  );
};
