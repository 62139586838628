import React, { useEffect } from "react";
import { Translation } from "react-i18next";
import i18next from "i18next";
import Chart from "chart.js";
import { isMobile } from "../../lib/deviceControl";

let currentNumberOfMonths = false;
export const StatsContainer = (props) => {
    //let myLineChart = null;
    //let myLineChart = null;
    useEffect(() => {
        
        if (!props.stats) {
            return;
        }
        if (currentNumberOfMonths === props.stats.displayNumberOfMonths) {
            return;
        }

        currentNumberOfMonths = props.stats.displayNumberOfMonths;
        let pointRadius, boxWidth, pointStyle, pointPadding, pointfontSize;
        let participationLabel = "";
        let checkinLabel = "";
        let ptLabel = "";

        if (isMobile()) {
            pointRadius = 4;
            boxWidth = 10;
            pointStyle = false;
            pointPadding = 5;
            pointfontSize = 11;

            participationLabel = i18next.t("Statistics.Grouptraining");
            checkinLabel = i18next.t("Statistics.TrainingShort");
            ptLabel = i18next.t("Statistics.PT");
        } else {
            pointRadius = 5;
            pointStyle = true;
            pointPadding = 10;
            pointfontSize = 12;

            participationLabel = i18next.t("Statistics.Grouptraining");
            checkinLabel = i18next.t("Statistics.Training");
            ptLabel = i18next.t("Statistics.PT");
        }
        
        new Chart(document.getElementById("line-chart"), {
            type: "bar",
            responsive: true,
            maintainAspectRatio: false,

            data: {
                labels: props.stats.labels,

                datasets: [
                    {
                        //Gruppträning - Rosa
                        data: props.stats.grouptraining,
                        label: participationLabel,
                        borderColor: "#E83C84",
                        backgroundColor: "#E83C84",
                        borderWidth: 1,
                        pointRadius: pointRadius,
                        pointHoverRadius: pointRadius,
                        pointBackgroundColor: "#E83C84",
                        fill: false,
                        showLine: true,
                    },
                    {
                        //Träning hos Actic - Blå
                        data: props.stats.actictraing,
                        label: checkinLabel,
                        borderColor: "#2ABFC9",
                        backgroundColor: "#2ABFC9",
                        borderWidth: 1,
                        pointRadius: pointRadius,
                        pointHoverRadius: pointRadius,
                        pointBackgroundColor: "#2ABFC9",
                        fill: false,
                        showLine: true,
                    },
                    {
                        //Personlig träning -Grön
                        pointRadius: pointRadius,
                        pointHoverRadius: pointRadius,
                        data: props.stats.personaltraining,
                        label: ptLabel,
                        borderColor: "#97D205",
                        backgroundColor: "#97D205",
                        borderWidth: 1,
                        pointBackgroundColor: "#97D205",
                        fill: false,
                        showLine: true,
                    },
                ],
            },

            options: {
                showLines: true,

                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                stepSize: 1,
                            },
                        },
                    ],

                    xAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },

                legend: {
                    display: true,
                    position: "bottom",

                    labels: {
                        //fontColor: "#4A4A4A",
                        fontSize: pointfontSize,
                        fontStyle: "bold",
                        padding: pointPadding,
                        usePointStyle: pointStyle,
                        boxWidth: boxWidth,
                    },
                },
            },
        });
    });

    return (
        <Translation>
            {(t) => (
                <div className="stats-container">
                    <div className="container mb-small mt-small">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-xxl-8">
                                <div className="chart-container">
                                    <canvas id="line-chart" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-xxl-8">
                                <ul className="list medium">
                                    <StatsPuff
                                        h1={t("Statistics.TrainingOccasions")}
                                        h2={props.stats.sum1}
                                        p={false}
                                    />
                                    <StatsPuff
                                        h1={t("Statistics.TrainingPerWeek")}
                                        h2={props.stats.sum2}
                                        p={false}
                                    />
                                </ul>
                            </div>
                        </div>
                 
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-xxl-8">
                                <ul className="list medium">
                                    <StatsPuffDot
                                        className="background-myactic-pink"
                                        h1={t("Statistics.Grouptraining")}
                                        h2={
                                            props.stats.shareParticipation + "%"
                                        }
                                        p={props.stats.sumParticipation + " st"}
                                    />
                                    <StatsPuffDot
                                        className="background-myactic-turquoise"
                                        h1={t("Statistics.Training")}
                                        h2={props.stats.shareCheckin + "%"}
                                        p={props.stats.sumCheckin + " st"}
                                    />
                                    <StatsPuffDot
                                        className="background-myactic-green"
                                        h1={t("Statistics.PT")}
                                        h2={props.stats.sharePT + "%"}
                                        p={props.stats.sumPT + " st"}
                                    />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Translation>
    );
};

const StatsPuff = (props) => {
    return (
        <li className="border-card">
            <div>
                <div>
                    <span className="body-text-regular color-black">{props.h1}</span>
                    {typeof props.p !== "undefined" ? (
                        <span className="body-text color-black">{props.p}</span>
                    ) : (
                        ""
                    )}
                </div>
                <div className="text-end">
                    <span className="d-flex justify-content-end align-items-center">
                        <span className="h4 color-black m-0 pe-1">
                            <span>{props.h2}</span>
                        </span>
                    </span>
                </div>
            </div>
        </li>
    );
};

const StatsPuffDot = (props) => {
    return (
        <li>
            <div className="points-row border-card">
                <div className="points-table-dot">
                    <div className={props.className}></div>
                </div>
                <div className="points-table-name">
                    <span className="body-text-regular color-black">{props.h1}</span>
                </div>
                <div className="points-table-nr text-end">
                    <span className="d-flex justify-content-end align-items-center">
                         <span className="h4 color-black m-0"><span>{props.h2}</span></span>
                    </span>
                    <span className="body-text-small color-transparen-dark-black">
                        {props.p}
                    </span>
                </div>
            </div>
        </li>
    );
};
