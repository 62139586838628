import React, {useContext} from "react";
import PersonContext from '../PersonContext.js';
import { Modal } from "./Modal.js";
import { ModalNew } from "./ModalNew.js";
import { WelcomeModal } from "./modals/WelcomeModal.js";
import { RecruitModal } from "./modals/RecruitModal.js";
import { RecruitModalNew } from "./modals/RecruitModalNew.js";
import { MyActicModal } from "./modals/MyActicModal.js";
import { ChangeModal } from "./modals/ChangeModal.js";

export const ModalHandler = (props) => {
    const context = useContext(PersonContext);

    if (context.currentlyOpenModal  === 'welcome') {
        return <Modal close={() => { context.openModal(false); }}><WelcomeModal /></Modal>;
    } else if (context.currentlyOpenModal  === 'recruit') {
        return <Modal close={() => { context.openModal(false); }}><RecruitModal /></Modal>;
    } else if (context.currentlyOpenModal  === 'recruit-gt') {
        return <Modal close={() => { context.openModal(false); }}><RecruitModal version="gt" /></Modal>;
    } else if (context.currentlyOpenModal  === 'recruit-cash') {
        return <ModalNew close={() => { context.openModal(false); }}><RecruitModalNew version="cash" /></ModalNew>;
    } else if (context.currentlyOpenModal  === 'myactic') {
        return <Modal close={() => { context.openModal(false); }}><MyActicModal /></Modal>;
    } else if (context.currentlyOpenModal  === 'change') {
        return <Modal close={() => { context.openModal(false); }}><ChangeModal /></Modal>;
    } else {
        return null;
    }
}