import React from "react";
import{ Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18next from 'i18next';
import { createBrowserHistory } from 'history';
import { NavTop } from "./components/Nav.js";
//import { LoginForm } from "./components/login/LoginForm.js";
import { RecoverRequestForm, RecoverForm } from "./components/login/RecoverForm.js";

const History = createBrowserHistory();

export class PageRecover extends React.Component {

  constructor(props) {
    super();

    this.state = {
      puk: false,
    }
  }

  componentDidMount() {
    document.title = i18next.t("Headline.Login") + ' - ' + i18next.t("SiteName");

    const urlParams = new URLSearchParams(History.location.search);
    const puk = urlParams.get('puk');
    if (puk) {
      this.setState({puk});
    } else {
      this.setState({puk: false});
    }
  }

  componentDidUpdate() {
    const urlParams = new URLSearchParams(History.location.search);
    const puk = urlParams.get('puk');
    if (this.state.puk !== false && !puk) {
      this.setState({puk: false});
    }
  }

  render() {

    return (
      <Translation>
        {(t) => (
          <React.Fragment>
            <NavTop />
            <div className="container wrapper-login">
              <div className="row justify-content-center">
                <div className="col col-md-6">
                    {
                      this.state.puk
                      ? (<RecoverForm puk={this.state.puk} />) 
                      : (<RecoverRequestForm />)
                    }
                    
                    <div className="text-center mt-5"><Link className="body-text-small color-stone link" to="/">{t("Headline.Login")}</Link></div>
                </div>
              </div>
            </div>
          </React.Fragment>
          
        )}
      </Translation>
    );
  }
}
