import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {getDomainCountry} from "./config";
import transSV from "./lang/sv.json";
import transNO from "./lang/no.json";
import transDE from "./lang/de.json";

const detector = new LanguageDetector()
detector.addDetector({
  name: 'acticdomain',

  lookup(options) {
    let domainCountry = getDomainCountry();
    if (domainCountry === 'NO') {
        return 'no';
    } else if (domainCountry === 'DE') { 
        return 'de';
    } else {
        return 'sv';
    }
  },

  cacheUserLanguage(lng, options) { },
});



i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    debug: false,
    resources: {
      sv: { translations: transSV },
      no: { translations: transNO },
      de: { translations: transDE },
    },
    fallbackLng: 'sv',
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['acticdomain'], // , 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 
    }
  }
);

export default i18n;
