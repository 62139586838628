import React, { useContext } from "react";
import PersonContext from "../../../PersonContext.js";
import { Translation } from "react-i18next";
//import { Link } from "react-router-dom";
//import { Translation } from "react-i18next";
//import PersonContext from "../PersonContext.js";

export const PartnerOffers = (props) => {
    let context = useContext(PersonContext);
    //console.log("loyaltyPartnerOffers", context.myactic.loyaltyPartnerOffers);
    return (
        <Translation>
          {(t) => (
        <div className="benefit-wrapper">
            {
                context.myactic.loyaltyPartnerOffers.map((offer, index) => {
                    return (
                    <div key={index} className="mb-4">
                        {offer.imageUrl ? <p><img src={offer.imageUrl} alt={offer.name} /></p> : null}
                        {offer.name ? <h4>{offer.name}</h4> : null}
                        {offer.text ? <p>{offer.text}</p> : null}
                        {offer.code ? <p>{t("MyActic.PartnerCode")}: {offer.code}</p> : null}
                        {typeof offer.links == 'object' && Array.isArray(offer.links) && offer.links.length ? (
                            <React.Fragment>
                                {offer.links.map((link, linkindex) => {
                                    return (
                                    <p key={linkindex}>
                                        <a href={link.url} target="_blank" className="link color-primary" rel="noreferrer">
                                            {
                                                link.title ? link.title : t("MyActic.PartnerToOffer")
                                            }
                                        </a>
                                    </p>
                                    )
                                })}
                            </React.Fragment>
                        ) : null}
                    </div>
                    );
                })
            }
        </div>
        )}
        </Translation>
    );
};