import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container } from './Components';

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const slugToTitle = (slug) => {
    const replacements = {
      'friskvardskvitto': 'Friskvårdskvitto',
      'andra-losenord': 'Ändra lösenord',
      'uppsagning-av-medlemskap': 'Uppsägning av medlemskap',
      'medlemsformaner': 'Medlemsförmåner',
      'andra-kortuppgifter': 'Ändra kortuppgifter'
      // add more replacements here if needed
    };

    if (replacements[slug.toLowerCase()]) {
      return replacements[slug.toLowerCase()];
    }

    const words = slug.split('-');
    return words.map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      } else {
        return word.toLowerCase();
      }
    }).join(' ');
  };

  return (
    <nav aria-label="breadcrumb" className="breadcrumb w-100">
      <Container col="col-12 col-lg-10">
        <ul className="d-flex">
          {pathnames.map((value, index) => {
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
            const isLast = index === pathnames.length - 1;
            return (
              <li key={to} className={`breadcrumb-item${isLast ? ' active' : ''}`} aria-current={isLast ? 'page' : undefined}>
                {!isLast ? (
                  <>
                    <Link to={to}>{slugToTitle(value)}</Link>
                    <span className="breadcrumb-separator"> / </span>
                  </>
                ) : (
                  <span>{slugToTitle(value)}</span>
                )}
              </li>
            );
          })}
        </ul>
      </Container>
    </nav>
  );
};

export default Breadcrumbs;
