import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { api_post } from "../../API.js";
import { Loading } from "../Components.js";

export const SsoLogin = (props) => {
  let params = useParams();
  const search = useLocation().search;

  useEffect(() => {
    if (params.token) {
      sessionStorage.setItem("welcomeShown", true);
      api_post("/mypages-sso-login", { token: params.token })
        .then((resp) => {
          if (resp.success) {
            props.handleSignInSuccess(resp);

            let path = '/medlemskap';
            if (typeof params.redirectpath !== 'undefined' && params.redirectpath) {
              path = '/' + params.redirectpath;
            }

            const nomenu = new URLSearchParams(search).get("nomenu");
            if (typeof nomenu !== "undefined" && nomenu) {
              path += "?nomenu=1";
            }

            props.redirect(path);
          } else {
            props.redirect("/");
          }
        })
        .catch(() => {
          props.redirect("/");
        });
    }
  }, []); // eslint-disable-line

  return (
    <div className="d-flex justify-content-center mt-5">
      <Loading />
    </div>
  );
};
