import React, { useContext, useState } from "react";
//import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18next from "i18next";
import PersonContext from "../../../PersonContext.js";
import { api_post, handleError } from "../../../API.js";
import { Alert } from "../../Alert.js";

export const InviteFriend = (props) => {
    let context = useContext(PersonContext);

    let apiUrl =
        "/persons/" +
        context.person.personId.externalId +
        "/myactic/benefits/invite";
    let formDataDefault = {
        name: "",
        email: "",
    };
    let successMessage = {
        headline: i18next.t("MyActic.InviteSent"),
        message: i18next.t("MyActic.InviteSentText"),
    };

    let [isSubmitting, setSubmitting] = useState(false);
    let [invalid, setInvalid] = useState({});
    let [data, setData] = useState(formDataDefault);
    let [used, setUsed] = useState(false);
    //let [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const onChange = (e) => {
        if (e.key === "Enter") {
            handleSubmit();
            return;
        }

        if (typeof invalid[e.target.name] !== "undefined") {
            setInvalid({ ...invalid, [e.target.name]: false });
        }

        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        if (
            typeof e !== "undefined" &&
            typeof e.preventDefault !== "undefined"
        ) {
            e.preventDefault();
        }

        let invalid = {};
        for (let keyName of Object.keys(data)) {
            if (data[keyName] === "") {
                invalid[keyName] = true;
            }
        }

        if (Object.keys(invalid).length) {
            setInvalid(invalid);
            return false;
        } else {
            setInvalid(false);
        }

        setSubmitting(true);
        data.period = "once";
        api_post(apiUrl, data)
            .then((resp) => {
                setSubmitting(false);
                if (parseInt(resp.result) === 1) {
                    setUsed(true);
                    setData(formDataDefault);
                    Alert(successMessage);
                    //setShowSuccessMessage(true);
                    if (typeof resp.myactic !== 'undefined') {
                        context.setMyActic(resp.myactic);
                      }
                } else {
                    if (typeof resp.reason !== 'undefined') {
                        handleError({error: "MyActicInvite." + resp.reason + (typeof resp.param!=='undefined' && resp.param ? '-'+resp.param : '')});
                    }
                }
            })
            .catch((resp) => {
                handleError(resp);
                setSubmitting(false);
            });
    };

    return (
        <Translation>
            {(t) => (
                <div
                    className={
                        "benefit-wrapper " +
                        (isSubmitting ? "form-disabled" : "")
                    }
                >
                    <p>{t("MyActic.InviteFriendText")}</p>
                    {used || props.inactive ? null : (
                        <div>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className={invalid.name ? "invalid" : ""}
                                    name="name"
                                    value={data.name}
                                    disabled={isSubmitting ? true : false}
                                    onChange={onChange}
                                    placeholder={t("MyActic.InviteFriendName")}
                                />
                            </div>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className={invalid.email ? "invalid" : ""}
                                    name="email"
                                    value={data.email}
                                    disabled={isSubmitting ? true : false}
                                    onChange={onChange}
                                    placeholder={t("MyActic.InviteFriendEmail")}
                                />
                            </div>
                            <div className="text-center mb-small mt-small">
                                <button
                                    className="btn secondary-btn color-secondary mx-auto mb-small"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting ? true : false}
                                >
                                    {t("MyActic.Send")}
                                </button>
                            </div>
                          
                        </div>
                    )}
                </div>
            )}
        </Translation>
    );
};
