import React from "react";
import { Translation } from "react-i18next";
import i18next from 'i18next';
import { api_post, handleError } from "../../API";
import {Alert} from "../Alert";
import { Loading } from '../Components.js';
import PersonContext from '../../PersonContext.js';
export class RecoverForm extends React.Component {

  static contextType = PersonContext;

  constructor(props) {
    super();

    this.state = {
      isSubmitting: false,
      newPassword: '',
      newPasswordShow: false,
      confirmPassword: '',
      confirmPasswordShow: false,
      invalid: {}
    }
  }

  onChange = (e) => {
    if (e.key === 'Enter') {
      this.submitForm();
      return;
    }
    let newState = {};
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  }

  submitForm = () => {

    let invalid = {};
    if (this.state.newPassword === '') {
      invalid["newPassword"] = true;
    }
    if (this.state.confirmPassword === '') {
      invalid["confirmPassword"] = true;
    }

    if (this.state.newPassword !== this.state.confirmPassword) {
      Alert(i18next.t("Errors.Person.PasswordNotEqual"));
      return;
    }

    if (Object.keys(invalid).length) {
      this.setState({invalid});
      return;
    }

    this.setState({isSubmitting: true, invalid}, () => {
      api_post("/passwordrecover", {
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword,
        puk: this.props.puk,
      })
        .then((resp) => {
          if (resp.success) {
            this.context.redirect("/");
            Alert(i18next.t("Recover.NewPasswordSuccess"));
          } else {
            handleError(resp);
          }
          this.setState({isSubmitting: false});
        })
        .catch((resp) => {
          handleError(resp);
          this.setState({isSubmitting: false});
      });
    });
    
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <React.Fragment>
          <h1>{t("Recover.SetNewPassword")}</h1>
          <p>{t("Recover.PasswordInfo")}</p>

          <div className={this.state.isSubmitting ? 'form-disabled' : ''}>

            <div className="input-group toggle-password-display">
              <span onClick={() => { this.setState({newPasswordShow: !this.state.newPasswordShow}) }}>{this.state.newPasswordShow ? 'visibility' : 'visibility_off'}</span>
              <input 
                type={this.state.newPasswordShow ? "text" : "password"} 
                className={this.state.invalid.newPassword ? 'invalid' : ''}
                name="newPassword"
                disabled={this.state.isSubmitting ? true : false}
                onChange={this.onChange}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    this.submitForm();
                    return;
                  }
                }}
                placeholder={t("Recover.NewPassword")}
                />
            </div>

            <div className="input-group toggle-password-display mb-small">
            <span onClick={() => { this.setState({confirmPasswordShow: !this.state.confirmPasswordShow}) }}>{this.state.confirmPasswordShow ? 'visibility' : 'visibility_off'}</span>
              <input 
                type={this.state.confirmPasswordShow ? "text" : "password"} 
                className={this.state.invalid.confirmPassword ? 'invalid' : ''}
                name="confirmPassword"
                disabled={this.state.isSubmitting ? true : false}
                onChange={this.onChange}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    this.submitForm();
                    return;
                  }
                }}
                placeholder={t("Recover.ConfirmPassword")}
                />
            </div>
            
            {
              this.state.isSubmitting
              ? (
                <div className="d-flex justify-content-center mt-4 mb-4"><Loading>{t('Recover.Sending')}</Loading></div> 
              )
              : (
                <div className="text-center">
                  <button 
                    className="btn primary-btn" 
                    disabled={this.state.isSubmitting?true:false}
                    onClick={this.submitForm}
                  >{t("Recover.Send")}</button>
                </div>
              )
            }
            

          </div>
          </React.Fragment>
        )}
      </Translation>
    );
  }
}






export class RecoverRequestForm extends React.Component {
  static contextType = PersonContext;

  constructor(props) {
    super();

    this.state = {
      isSubmitting: false,
      username: '',
      invalid: {}
    }
  }
  onChange = (e) => {
    if (e.key === 'Enter') {
      this.submitForm();
      return;
    }
    let newState = {};
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  }
  submitForm = () => {

    let invalid = {};
    if (this.state.username === '') {
      invalid["username"] = true;
    }

    if (Object.keys(invalid).length) {
      this.setState({invalid});
      return;
    }

    this.setState({isSubmitting: true, invalid}, () => {
      api_post("/passwordrequest", {
        username: this.state.username,
      })
        .then((resp) => {
          if (resp.success) {
            this.context.redirect("/");
            Alert(i18next.t("Recover.Success"));
          } else {
            handleError(resp);
          }
          this.setState({isSubmitting: false});
        })
        .catch((resp) => {
          handleError(resp);
          this.setState({isSubmitting: false});
      });
    });
    
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <React.Fragment>
          <h1>{t("Recover.Headline")}</h1>

          <div className={this.state.isSubmitting ? 'form-disabled' : ''}>

            <div className="input-group mb-small">
              <input 
                type="text"
                className={this.state.invalid.username ? 'invalid' : ''}
                name="username"
                disabled={this.state.isSubmitting ? true : false}
                onChange={this.onChange}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    this.submitForm();
                    return;
                  }
                }}
                placeholder={t("Recover.Username")}
                />
            </div>
            
            {
              this.state.isSubmitting
              ? (
                <div className="d-flex justify-content-center mt-4 mb-4"><Loading>{t('Recover.Sending')}</Loading></div> 
              )
              : (
                <div className="text-center">
                  <button 
                    className="btn primary-btn" 
                    disabled={this.state.isSubmitting?true:false}
                    onClick={this.submitForm}
                  >{t("Recover.Send")}</button>
                </div>
              )
            }
            

          </div>
          </React.Fragment>
        )}
      </Translation>
    );
  }
}
