import React from 'react'


export const Slideup = ({children}) => {
    return (
        <div className="slideupbox">
            <div className="wrapper-slide-top"></div>
            <div className="wrapper-slide-content">
                {children}
            </div>
        </div>
    )
}
