import React, { useContext } from "react";
import { Translation } from "react-i18next";
import PersonContext from "../../PersonContext.js";
import { getDomainCountry } from "../../config.js";
//import { Loading, CenterName } from '../Components.js';

export const ChangeModal = (props) => {
  let context = useContext(PersonContext);
  let domainCountry = getDomainCountry();

  let content = "";
  if (domainCountry === "SE") {
    content = <ModalSE />;
  } else if (domainCountry === "NO") {
    content = <ModalNO />;
  } else if (domainCountry === "DE") {
    content = <ModalDE />;
  }

  return (
    <Translation>
      {(t) => (
        <React.Fragment>
          <div className="top-content mb-small">
            <h2>{t("ChangeSubscription.ModalHeadline")}</h2>
          </div>
          <div className="modal-content">
            {content}

            <div className="text-center mt-small">
              <button
                className="btn secondary-btn color-secondary"
                onClick={() => {
                  context.openModal(false);
                }}
              >
                {t("Alert.OK")}
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </Translation>
  );
};

const ModalSE = () => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-xxl-12 mx-auto">
            <p className="body-text-small">
              Uppgradering av medlemskap, det vill säga ökad access, slår direkt
              igenom samma dag som du ändrar ditt medlemskap.
            </p>
            <p className="body-text-small">
              Nedgradering, minskad access, börjar gälla från första dagen
              kommande månad.
            </p>
            <p className="body-text-small">
              Observera att tillfälliga kampanjer, till exempel en fri
              startmånad, kommer att avbrytas vid en ändring av medlemskapet.
            </p>
            <p className="body-text-small">
              Vid ändring av medlemskapet är det alltid den senaste prislistan
              som gäller.
            </p>
            <p className="body-text-small">
              När du ändrar ditt medlemskap godkänner du även nya
              medlemskapsvillkor.
            </p>

            <h4 className="mt-5">
              Har du köpt medlemskap innan 2022-05-18 så gäller även:
            </h4>

            <p className="body-text-small">
              Med en ny medlemsstruktur, jämfört med ditt nuvarande medlemskap,
              så kan du närsomhelst ändra vad som ska ingå i ditt medlemskap och
              på så vis även påverka ditt månadspris.
            </p>
            <p className="body-text-small">
              Är du inom bindningstid eller har köpt årskort så kan du inte
              ändra ditt medlemskap förrän bindningstiden är över.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const ModalNO = () => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-xxl-12 mx-auto">
            <p className="body-text-small">
              Oppgradering av medlemskap, dvs. økt tilgang, trer i kraft
              omgående.
            </p>
            <p className="body-text-small">
              Nedgradering, dvs. redusert tilgang, gjelder fra den 1. påfølgende
              måned.
            </p>
            <p className="body-text-small">
              Vær oppmerksom på at kampanjer som f.eks. 1 frimåned når du melder
              deg inn, vil bli avbrutt dersom du endrer medlemskapet.
            </p>
            <p className="body-text-small">
              Ved endring av medlemskapet er det bestandig nyeste pris som
              gjelder.{" "}
            </p>
            <p className="body-text-small">
              Endring av medlemskapet innebærer også at du godtar nye
              medlemsvilkår.
            </p>

            <h4 className="mt-5">
              Hvis du har kjøpt medlemskap før den 01.12.2021 gjelder i tillegg
              følgende:
            </h4>

            <p className="body-text-small">
              Med ny medlemskapsstruktur, sammenliknet med den du har nå, kan du
              til enhver tid endre hva medlemskapet ditt skal inneholde og
              dermed også månedsprisen din.
            </p>
            <p className="body-text-small">
              Du kan ikke endre medlemskapet ditt hvis du er i bindingstid eller
              har forhåndsbetalt.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const ModalDE = () => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-xxl-12 mx-auto">
            <p className="body-text-small">
              Deserunt nulla esse quis ullamco in fugiat ex cillum aliqua aute
              ex. Eu labore aliquip in anim minim irure in dolore mollit tempor
              nostrud consectetur. Sint esse ipsum ipsum velit ea. Adipisicing
              ut enim labore aliqua elit est cupidatat. Sit Lorem do fugiat
              velit est consequat laboris ex elit pariatur eu.
            </p>

            <h3 className="mt-5">
              Sunt est quis in qui magna exercitation magna aute dolore ut.
            </h3>

            <p className="body-text-small">
              Amet laborum eiusmod et commodo eu nisi sunt pariatur consectetur.
              Id aliqua est nulla amet proident incididunt magna et cupidatat
              elit qui. Irure commodo adipisicing officia eiusmod commodo labore
              do sint amet. Nostrud laborum aute ipsum aliquip ad officia.
              Laboris laborum elit ea laboris occaecat aute nisi labore id
              aliqua. Duis ut voluptate incididunt esse pariatur sunt.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
