import React from 'react';
import { PT } from '../components/settings/PT.js';
import { Container } from '../components/Components.js';
import { TopContent } from '../components/TopContent.js';
import { Translation } from 'react-i18next';
import { NavTop } from '../components/Nav.js';
import Breadcrumbs from '../components/Breadcrumb.js';

export class PagePT extends React.Component {
  render() {
    return (
      <Translation>
        {(t) => (
          <>
            <NavTop />
            <Breadcrumbs/>
            <TopContent h2={t("Settings.ClipcardHeadline")}/>
            <Container>
              <PT/>
            </Container>
          </>
        )}
      </Translation>
    );
  }
}
