import React from 'react';
import ReactDOM from 'react-dom';
import i18next from 'i18next';


export const Alert = (props) => {

  let message, headline, code;

  if (typeof props == 'object' && typeof props.err == 'object') {
    message = props.err.message || '';
    headline = '';
    code = props.err.code || '';
  } else if (typeof props == 'object') {
    message = props.message || '';
    headline = props.headline || '';
    code = props.code || '';
  } else {
    message = props;
    headline = '';
    code = '';
  }

  function close() {
    ReactDOM.unmountComponentAtNode(document.querySelector('#alert-root'));
  }

  let html = (

      <div id="alert-popup">
      <div className="alert-overlay" />
      <div
        className="alert-wrapper"
        onClick={(e) => {
          e.stopPropagation();
          close();
        }}
      >
        <div className="alert" onClick={(e) => { e.stopPropagation(); }}>
          <div className="alert-icon">
            <i className="fas fa-exclamation-triangle" />
          </div>
          {typeof headline !== 'undefined' ? <h2>{headline}</h2> : ''}

          {
            code !== '' 
            ? <p>{i18next.t("Errors." + code)}</p>
            :  message !== '' ? <p>{message}</p> : <p>{i18next.t("Errors.UnknownError")}</p>
          }

          <div className="btn-group">
            <button
              className="btn primary-btn m-0"
              onClick={(e) => {
                e.stopPropagation();
                close();
              }}
            >
              {i18next.t("Alert.OK")}
            </button>
          </div>
          {code !== '' ? <p className="inactive">{i18next.t("Alert.ErrorCode")} {code}</p> : ''}
        </div>
      </div>
    </div>
  );

  ReactDOM.render(html, document.querySelector('#alert-root'));
}
