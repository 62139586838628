import {getApiHost, getDomainCountry} from './config.js'
import i18next from 'i18next';
import {Alert} from './components/Alert'

export function handleError(err) {
    if (typeof err.error != 'undefined') {
        Alert(i18next.t("Errors."+err.error));
    } else if (typeof err.reason != 'undefined') {
        Alert(i18next.t("Errors."+err.reason));
    } else if (typeof err.message != 'undefined') {
        Alert(err.message);
    } else if (typeof err == 'string') {
        Alert(err);
    } else {
        //console.log(err);
    }
}

export function api_get(path) {
    return new Promise(async (resolve, reject) => {

        let accessToken = sessionStorage.getItem("accessToken");

        let options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Domain-Country": getDomainCountry(),
                "Access-Token": accessToken || ""
            }
        };

        fetch(getApiHost() + path, options)
            .then((response) => {
                if (response.status === 200) {
                    response.json().then(responseData => {
                        resolve(responseData);
                    }).catch((err) => {
                        reject({success: false, error: "ServiceUnavailable"})
                    })
                } else {
                    response.json().then(responseData => {
                        reject(responseData);
                    }).catch((err) => {
                        reject({success: false, error: "ServiceUnavailable"})
                    })
                }
            })
            .catch((err) => {
                reject({success: false, error: "ServiceUnavailable"})
            });
    });
}
export function api_patch(path, data) {
    return new Promise(async (resolve, reject) => {

        let accessToken = sessionStorage.getItem("accessToken");

        let options = {
            method: "PATCH",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Domain-Country": getDomainCountry(),
                "Access-Token": accessToken || ""
            }
        };

        fetch(getApiHost() + path, options)
            .then((response) => {
                if (response.status === 200) {
                    response.json().then(responseData => {
                        resolve(responseData);
                    }).catch((err) => {
                        reject({success: false, error: "ServiceUnavailable"})
                    })
                } else {
                    response.json().then(responseData => {
                        reject(responseData);
                    }).catch((err) => {
                        reject({success: false, error: "ServiceUnavailable"})
                    })
                }
            })
            .catch((err) => {
                reject({success: false, error: "ServiceUnavailable"})
            });
    });
}
export function api_post(path, data) {
    return new Promise(async (resolve, reject) => {

        let accessToken = sessionStorage.getItem("accessToken");

        let options = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Domain-Country": getDomainCountry(),
                "Access-Token": accessToken || ""
            }
        };

        fetch(getApiHost() + path, options)
            .then((response) => {
                if (response.status === 200) {
                    response.json().then(responseData => {
                        resolve(responseData);
                    }).catch((err) => {
                        reject({success: false, error: "ServiceUnavailable"})
                    })
                } else {
                    response.json().then(responseData => {
                        reject(responseData);
                    }).catch((err) => {
                        reject({success: false, error: "ServiceUnavailable"})
                    })
                }
            })
            .catch((err) => {
                reject({success: false, error: "ServiceUnavailable"})
            });
    });
}
export function api_delete(path) {
    return new Promise(async (resolve, reject) => {

        let accessToken = sessionStorage.getItem("accessToken");

        let options = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Domain-Country": getDomainCountry(),
                "Access-Token": accessToken || ""
            }
        };

        fetch(getApiHost() + path, options)
            .then((response) => {
                if (response.status === 200) {
                    response.json().then(responseData => {
                        resolve(responseData);
                    }).catch((err) => {
                        reject({success: false, error: "ServiceUnavailable"})
                    })
                } else {
                    response.json().then(responseData => {
                        reject(responseData);
                    }).catch((err) => {
                        reject({success: false, error: "ServiceUnavailable"})
                    })
                }
            })
            .catch((err) => {
                reject({success: false, error: "ServiceUnavailable"})
            });
    });
}
export const api_upload = (path, data) => {
    return new Promise(async (resolve, reject) => {

        let accessToken = sessionStorage.getItem("accessToken");

        let options = {
            method: "POST",
            body: data,
            headers: {
                "Access-Token": accessToken || "",
                "Domain-Country": getDomainCountry()
            }
        };

        fetch(getApiHost() + path, options)
            .then((response) => {
                if (response.status === 200) {
                    response.json().then(responseData => {
                        resolve(responseData);
                    }).catch((err) => {
                        reject({success: false, error: "ServiceUnavailable"})
                    })
                } else {
                    response.json().then(responseData => {
                        reject(responseData);
                    }).catch((err) => {
                        reject({success: false, error: "ServiceUnavailable"})
                    })
                }
            })
            .catch((err) => {
                reject({success: false, error: "ServiceUnavailable"})
            });
    });
}