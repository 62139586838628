import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import TagManager from 'react-gtm-module'
//import ReactGA from 'react-ga';
import { isTest } from './config.js';

if (!isTest()) {
  const nomenu = (new URLSearchParams(window.location.search).get("nomenu")) ? true : false;
  if (!nomenu)  {
    TagManager.initialize({ gtmId: 'GTM-PXTGW56' })
    // TagManager.dataLayer({event: "test"}) // <- Example
    //ReactGA.initialize('UA-35067373-1');
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }

  //console.log(ReactGA);
}





ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
