import React, {useContext} from "react";
import { useTranslation } from 'react-i18next';
import { CheckBoxOption } from "../CheckBoxOption";
import ChangeContext from "../../ChangeContext";
import {numberFormat} from "../../lib/numberFormat";

export const ChangeOption = ({optionKey, optionId, text, onClick}) => {
  
  const {t} = useTranslation();
  const changeContext = useContext(ChangeContext);

  let isChecked = changeContext.selectedData[optionKey] === optionId ? true : false;

  let priceDiff = 0;
  let thisOptionData = {};
  thisOptionData[optionKey] = optionId;
  let matchingAvailableSubscription = changeContext.findMatchingSubscription({...changeContext.selectedData, ...thisOptionData}, changeContext.availableSubscriptions);

  let matchingCurrentSubscription = changeContext.findMatchingSubscription({...changeContext.selectedData, ...thisOptionData}, [changeContext.currentSubscription]);
  
  if (isChecked) {
    priceDiff = 0;
  } else {

    if (changeContext.selectedSubscription) {
      if (matchingAvailableSubscription) {
        priceDiff = parseFloat(matchingAvailableSubscription.periodPrice)-parseFloat(changeContext.selectedSubscription.periodPrice);
      } else if (matchingCurrentSubscription) {
        priceDiff = parseFloat(matchingCurrentSubscription.price)-parseFloat(changeContext.selectedSubscription.periodPrice);
      }
    } else {
      priceDiff = parseFloat(matchingAvailableSubscription.periodPrice)-parseFloat(changeContext.currentSubscription.price);
    }
    if (isNaN(priceDiff)) {
      priceDiff = 0;
    }
    /*
    if (matchingSubscription) {
      //if (currentSubscription && selectedData[optionKey] !== optionId) {
        priceDiff = parseInt(parseFloat(matchingSubscription.periodPrice)-parseFloat(changeContext.currentSubscription.price));
      //}
    } else if (matchingCurrentSubscription) {
      //priceDiff = parseFloat(matchingCurrentSubscription.price)-parseFloat(changeContext.selectedSubscription.periodPrice);
    }
    */
  } 
  
  //else if (matchingCurrentSubscription) {
  //  priceDiff = 0;
  //}

  //if (optionKey === 'subscriptionBinding') {
    //console.log(optionKey, optionId, "Selected:", changeContext.selectedData[optionKey]);
    //console.log(changeContext.currentSubscription.price, matchingSubscription.periodPrice, priceDiff);
    //console.log("");
  //}
  
  let currentExtraText = changeContext.currentSubscription[optionKey] === optionId ? ' ('+t("ChangeSubscription.CurrentSubscriptionOptionMarker")+')' : '';
  return (
    <CheckBoxOption
      text={text + currentExtraText}
      label={<PriceDiff price={priceDiff} showPosNegSign={true} />}
      checked={isChecked}
      onClick={() => {
        if (matchingAvailableSubscription || changeContext.currentSubscription[optionKey] === optionId) {
          onClick();
        }
      }}
      className={!matchingAvailableSubscription && changeContext.currentSubscription[optionKey] !== optionId ? 'inactive' : ''}
    />
  );
};

const PriceDiff = ({price}) => {
  var countrycode = 'NO';
  var pre = '';

  if (price === 0) {
    //pre = '+-';
    return '';
  } else if (price > 0) {
    pre = '+';
  }

	if (countrycode === 'NO') {
		return pre + Math.round(price)+'kr/mnd';
	} else if (countrycode === 'DE' || countrycode === 'FI') {
		return pre + numberFormat(price, 2, '.', '') + '&euro;/Mon';
	} else { // SE
		return pre + Math.round(price)+'kr/mån';
	}
}