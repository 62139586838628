import domainCountries from "./config/domainCountries.json";
import supportUrls from "./config/supportUrls.json";
import contactUrls from "./config/contactUrls.json";
import environments from "./config/environments.json";

const getDomainCountry = () => {
    // Just for testing return 'NO';
    if (typeof domainCountries[window.location.host] !== 'undefined') {
        return domainCountries[window.location.host];
    } else {
        return 'SE';
    }
}

const getSupportURL = () => {
    let countryCode = getDomainCountry();
    if (typeof supportUrls[countryCode] !== 'undefined') {
        return supportUrls[countryCode];
    } else {
        return '';
    }
}

const getContactURL = () => {
    let countryCode = getDomainCountry();
    if (typeof contactUrls[countryCode] !== 'undefined') {
        return contactUrls[countryCode];
    } else {
        return '';
    }
}

const getApiHost = () => {
    let environment = 'development';
    if (typeof environments[window.location.host] !== 'undefined') {
        environment = environments[window.location.host];
    }
    switch (environment) {
        case 'production':
            return process.env.REACT_APP_API_HOST_PRODUCTION;
        case 'staging':
            return process.env.REACT_APP_API_HOST_STAGING;
        default:
            return process.env.REACT_APP_API_HOST_DEVELOPMENT;
    }
}

const isTest = () => {
    if (window.location.host.indexOf("texasdigital.se") >= 0 
        || window.location.host.indexOf("staging.") >= 0 
        || window.location.host.indexOf("localhost") >= 0) {
        return true;
    } else {
        return false;
    }
}

const getWebUrl = () => {
    let countryCode = getDomainCountry();
    switch (countryCode) {
        case 'SE':
            return process.env.REACT_APP_WEB_URL_SE;
        case 'NO':
            return process.env.REACT_APP_WEB_URL_NO;
        case 'DE':
            return process.env.REACT_APP_WEB_URL_DE;
        default:
            return process.env.REACT_APP_WEB_URL_SE;
    }
}

export {getApiHost, getDomainCountry, getSupportURL, getContactURL, isTest, getWebUrl};