import React from "react";
import { useTranslation } from 'react-i18next';
import { Container } from "../Components";
import {numberFormat} from "../../lib/numberFormat";

export const PreviewBasket = ({currentSubscription, selectedSubscription, initChange}) => {
    const {t} = useTranslation();
    let price = false;
    if (selectedSubscription && selectedSubscription.periodPrice) {
        price = selectedSubscription.periodPrice;
    } else if (currentSubscription && currentSubscription.price) {
        price = currentSubscription.price;
    }
    return (
        <div>
            <Container>
                <div className="d-flex justify-content-between">
                    {price && <Price price={price} />}
                    {
                        selectedSubscription
                        ? (
                            <button className="btn secondary-btn color-primary"
                                onClick={initChange}
                            >{t("ChangeSubscription.Change")}</button>
                        )
                        : null
                    }
                </div>
                <div>
                    {
                        selectedSubscription
                        ? <p className="caption-text color-black">{t("ChangeSubscription.YourNewPrice")}</p>
                        : <p className="caption-text color-black">{t("ChangeSubscription.YourCurrentPrice")}</p>
                    }
                </div>
            </Container>
        </div>
    );
};

const Price = ({price}) => {
    var countrycode = 'NO';

    if (countrycode === 'NO') {
        return <span className="price-basket">{Math.round(price)}<span className="body-text ms-2">kr/mnd</span></span>;
    } else if (countrycode === 'DE' || countrycode === 'FI') {
        return <span className="price-basket">{numberFormat(price, 2, '.', '')}<span className="body-text ms-2">&euro;/Mon</span></span>;
    } else { // SE
        return <span className="price-basket">{Math.round(price)}<span className="body-text ms-2">kr/mån</span></span>;
    }
}