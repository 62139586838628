import React, { useState } from "react";
import { Translation } from "react-i18next";
import i18next from "i18next";
import PersonContext from "../../PersonContext.js";
import { Loading } from "../Components.js";

import { Bia } from "./benefits/Bia.js";
import { BringFriend } from "./benefits/BringFriend.js";
import { Freeze } from "./benefits/Freeze.js";
import { Instructor } from "./benefits/Instructor.js";
import { InviteFriend } from "./benefits/InviteFriend.js";
import { InviteMonth } from "./benefits/InviteMonth.js";
import { InvitePlus } from "./benefits/InvitePlus.js";
import { Max } from "./benefits/Max.js";
import { PartnerOffers } from "./benefits/PartnerOffers.js";
import { PT } from "./benefits/PT.js";
import { PTExtra } from "./benefits/PTExtra.js";
//import { Reset } from "./benefits/Reset.js";

export class MyActicBenefits extends React.Component {
    static contextType = PersonContext;

    constructor(props) {
        super();

        this.state = {
            benefits: [],
        };
    }

    componentDidMount() {
        this.updateBenefits();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.loadingMyActic && !this.props.loadingMyActic) {
            this.updateBenefits();
        }
    }

    updateBenefits = () => {
        if (!this.props.loadingMyActic) {
            //console.log(this.context);
            let myactic = this.context.myactic;
            let benefits = [];
            let now = Math.floor(Date.now() / 1000);

            // Invite
            if (myactic.loyaltyInviteOnceAvailable !== 0) {
                let benefitObject = {
                    className: 'invite',
                    benefit: InviteFriend,
                    title: i18next.t("MyActic.InviteFriend"),
                    inactive: false,
                };
                if (
                    myactic.loyaltyInviteOnceAvailable >= 0 &&
                    myactic.loyaltyInviteOnceAvailable <=
                        myactic.loyaltyInviteOnceUsed
                ) {
                    benefitObject.inactive = true;
                }
                benefits.push(benefitObject);
            } else if (myactic.loyaltyInviteWeekAvailable !== 0) {
                let benefitObject = {
                    className: 'invite',
                    benefit: InvitePlus,
                    title: i18next.t("MyActic.InvitePlus"),
                    inactive: false,
                };
                if (
                    myactic.loyaltyInviteWeekAvailable >= 0 &&
                    myactic.loyaltyInviteWeekAvailable <=
                        myactic.loyaltyInviteWeekUsed
                ) {
                    benefitObject.inactive = true;
                }
                benefits.push(benefitObject);
            } else if (myactic.loyaltyInviteMonthAvailable !== 0) {
                let benefitObject = {
                    className: 'invite',
                    benefit: InviteMonth,
                    title: i18next.t("MyActic.InviteMonth"),
                    inactive: false,
                };
                if (
                    myactic.loyaltyInviteMonthAvailable >= 0 &&
                    myactic.loyaltyInviteMonthAvailable <=
                        myactic.loyaltyInviteMonthUsed
                ) {
                    benefitObject.inactive = true;
                }
                benefits.push(benefitObject);
            }

            // Bring friend
            /* Removed 2023-10-14
            if (myactic.loyaltyBringFriendAvailable !== 0) {
                benefits.push({
                    className: 'bringfriend',
                    benefit: BringFriend,
                    title: i18next.t("MyActic.BringFriend"),
                    inactive: false,
                });
            }
            */

            if (myactic.loyaltyBiaAvailable !== 0) {
                let benefitObject = {
                    className: 'bia',
                    benefit: Bia,
                    title: i18next.t("MyActic.Bia"),
                    inactive: false,
                };
                if (
                    (myactic.loyaltyBiaAvailable >= 0 &&
                        myactic.loyaltyBiaAvailable <=
                            myactic.loyaltyBiaBooked) ||
                    (myactic.loyaltyBiaExpires > 0 &&
                        myactic.loyaltyBiaExpires < now)
                ) {
                    benefitObject.inactive = true;
                }
                benefits.push(benefitObject);
            }

            if (myactic.loyaltyFreezeAvailable !== 0) {
                let benefitObject = {
                    className: 'freeze',
                    benefit: Freeze,
                    title: i18next.t("MyActic.Freeze"),
                    inactive: false,
                };
                benefits.push(benefitObject);
            }

            if (this.context.center.has_startpt) {
                if (myactic.loyaltyPTAvailable !== 0) {
                    let benefitObject = {
                        className: 'pt',
                        benefit: PT,
                        title: i18next.t("MyActic.PT"),
                        inactive: false,
                    };
                    if (
                        myactic.loyaltyPTBooked >= myactic.loyaltyPTAvailable ||
                        (myactic.loyaltyPTExpires > 0 &&
                            myactic.loyaltyPTExpires < now)
                    ) {
                        benefitObject.inactive = true;
                    }
                    benefits.push(benefitObject);
                }
            } else if (
                this.context.center.has_startinstructor &&
                myactic.loyaltyInstructorAvailable > 0
            ) {
                if (myactic.loyaltyInstructorAvailable !== 0) {
                    let benefitObject = {
                        className: 'instructor',
                        benefit: Instructor,
                        title: i18next.t("MyActic.Instructor"),
                        inactive: false,
                    };
                    if (
                        (myactic.loyaltyInstructorAvailable >= 0 &&
                            myactic.loyaltyInstructorAvailable <=
                                myactic.loyaltyInstructorBooked) ||
                        (myactic.loyaltyInstructorExpires > 0 &&
                            myactic.loyaltyInstructorExpires < now)
                    ) {
                        benefitObject.inactive = true;
                    }
                    benefits.push(benefitObject);
                }
            }

            if (this.context.center.has_max_subscription) {
                if (myactic.loyaltyLevel === 3) {
                    if (myactic.loyaltyUpgradeMaxEliteAvailable > 0) {
                        let benefitObject = {
                            className: 'max',
                            benefit: Max,
                            title: i18next.t("MyActic.Max"),
                            inactive: false,
                        };
                        if (
                            myactic.loyaltyUpgradeMaxEliteAvailable <=
                            myactic.loyaltyUpgradeMaxEliteUsed
                        ) {
                            benefitObject.inactive = true;
                        }
                        benefits.push(benefitObject);
                    }
                } else if (myactic.loyaltyLevel === 4) {
                    if (myactic.loyaltyUpgradeMaxVIPAvailable > 0) {
                        let benefitObject = {
                            className: 'max',
                            benefit: Max,
                            title: i18next.t("MyActic.Max"),
                            inactive: false,
                        };
                        if (
                            myactic.loyaltyUpgradeMaxVIPAvailable <=
                            myactic.loyaltyUpgradeMaxVIPUsed
                        ) {
                            benefitObject.inactive = true;
                        }
                        benefits.push(benefitObject);
                    }
                }
            }

            if (
                this.context.center.has_startpt &&
                myactic.loyaltyPTExtraAvailable > 0
            ) {
                let benefitObject = {
                    className: 'ptextra',
                    benefit: PTExtra,
                    title: i18next.t("MyActic.PTextra"),
                    inactive: false,
                };
                if (
                    myactic.loyaltyPTExtraAvailable <=
                        myactic.loyaltyPTExtraBooked ||
                    (myactic.loyaltyPTExtraExpires > 0 &&
                        myactic.loyaltyPTExtraExpires < now)
                ) {
                    benefitObject.inactive = true;
                }
                benefits.push(benefitObject);
            }

            // Discounts
            //if (typeof myactic.loyaltyShopDiscountAvailable !== 'undefined' && myactic.loyaltyShopDiscountAvailable <> 0) {
            //    let benefitObject = { benefit: 'discount', title: i18next.t('MyActic.InviteFriend'), inactive: false };
            //    benefits.push(benefitObject);
            //}

            // Partner
            if (
                typeof myactic.loyaltyPartnerOffers !== "undefined" &&
                myactic.loyaltyPartnerOffers
            ) {
                let benefitObject = {
                    className: 'partner',
                    benefit: PartnerOffers,
                    title: i18next.t("MyActic.PartnerOffers"),
                    inactive: false,
                };
                benefits.push(benefitObject);
            }

            this.setState({ benefits });
        }
    };

    render() {
        //console.log("benefits", this.state.benefits);
        return (
            <Translation>
                {(t) => {
                    return (
                        <div className="myactic-benefits">
                            {this.props.loadingMyActic ? (
                                <div className="d-flex justify-content-center mt-5"><Loading>{t("MyActic.LoadingMyActicBenefits")}</Loading></div>
                            ) : (
                                <ul className="list list-toggle">
                                    {this.state.benefits.map(
                                        (benefit, index) => {
                                            return (
                                                <BenefitItem
                                                    key={index}
                                                    myactic={
                                                        this.context.myactic
                                                    }
                                                    {...benefit}
                                                />
                                            );
                                        }
                                    )}
                                    {/* <BenefitItem myactic={this.context.myactic} title="Återställ förmåner för test" benefit={Reset} /> */}
                                </ul>
                            )}
                        </div>
                    );
                }}
            </Translation>
        );
    }
}

function BenefitItem(props) {
    let [open, setOpen] = useState(false);
    let BenefitComponent = props.benefit;
    return (
        <li className={(props.inactive ? 'inactive ' : '' ) + props.className}>
            <div onClick={() => {
                    if (!props.inactive) {
                        setOpen(!open);
                    }
            }}>
                <div>
                    <span className="body-text-regular color-black benefit-title">{props.title}</span>
                </div>
                {
                    !props.inactive
                    ? (
                        <div className="icon toggle-down">
                            <span></span>
                        </div>
                    )
                    : null
                }
                
            </div>
            {open && !props.inactive ? (
                <div>
                    <BenefitComponent {...props} />
                </div>
            ) : null}
        </li>
    );
}
