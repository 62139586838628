import React from 'react';
import { CommingCourses } from '../components/settings/CommingCourses.js';
import { Container } from '../components/Components.js';
import { TopContent } from '../components/TopContent.js';
import { Translation } from 'react-i18next';
import { NavTop } from '../components/Nav.js';
import Breadcrumbs from '../components/Breadcrumb.js';

export const PageCommingCourses = () => {

    return (
      <Translation>
        {(t) => (
          <>
            <NavTop />
            <Breadcrumbs/>
            <TopContent h2="Kurser med förtur"/>
            <Container>
              <CommingCourses/>
            </Container>
          </>
        )}
      </Translation>
  );
};
