import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import PersonContext from "../../PersonContext";
import { SmallList } from "../SmallList.js";
import { SmallListItem } from "../SmallListItem.js";

export const ChangeCompletedScreen = ({newSubscription}) => {
  const { t } = useTranslation();
  const personContext = useContext(PersonContext);


  return (
    <React.Fragment>
      <div>
        <h2>{
          newSubscription 
            && typeof newSubscription.startDate !== 'undefined' 
            && newSubscription.startDate
            && newSubscription.startDate !== new Date().toISOString().split('T')[0]
          ? t("ChangeSubscription.ChangeSuccessHeadlineDate", {startDate: newSubscription.startDate})
          : t("ChangeSubscription.ChangeSuccessHeadline")
        }</h2>
      </div>

      <div className="">
        <SmallList headline={t("Subscriptions.PersonType")} className="list-small-xs">
          <SmallListItem
            text={t("PersonTypeLabels." + personContext.person.personType)}
          />
        </SmallList>
        <SmallList headline={t("MemberInfo.Center")} className="list-small-xs">
          <SmallListItem text={personContext.center.name} />
        </SmallList>
        <SmallList
          headline={t("Subscriptions.AccessLevel")}
          className="list-small-xs"
        >
          <SmallListItem text={t(newSubscription.cardArea)} />
        </SmallList>
        <SmallList headline={t("MemberInfo.Membership")} className="list-small-xs">
          {newSubscription.cardIncludes.map((feature, index) => {
            return <SmallListItem key={index} text={feature} />;
          })}
        </SmallList>
      </div>

    </React.Fragment>
  );
};
