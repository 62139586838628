import React from 'react';
import { Contribution } from '../components/settings/Contribution.js';
import { Container } from '../components/Components.js';
import { TopContent } from '../components/TopContent.js';
import { Translation } from 'react-i18next';
import Breadcrumbs from '../components/Breadcrumb.js';
import { NavTop } from '../components/Nav.js';


  export const PageContribution = () => {

    return (
      <Translation>
        {(t) => (
          <>
            <NavTop />
            <Breadcrumbs/>
            <TopContent h2={t("Settings.ContributionHeadline")}/>
            <Container>
              <Contribution />
            </Container>
          </>
        )}
      </Translation>
  );
};

