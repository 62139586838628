import React from "react";
import { Translation } from "react-i18next";
import i18next from 'i18next';
import { api_post, handleError } from "../../API";
import {Alert} from "../Alert";
import { Loading } from '../Components.js';

export class LoginForm extends React.Component {

  constructor(props) {
    super();

    this.state = {
      isSubmitting: false,
      username: '',
      password: '',
      invalid: {}
    }
  }
  onChange = (e) => {

    if (e.key === 'Enter') {
      this.submitForm();
      return;
    }

    let newState = {};
    newState.invalid = this.state.invalid;
    if (typeof newState.invalid[e.target.name] !== 'undefined') {
      newState.invalid[e.target.name] = false;
    }
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  }
  submitForm = () => {

    let invalid = {};
    if (this.state.username === '') {
      invalid["username"] = true;
    }
    if (this.state.password === '') {
      invalid["password"] = true;
    }

    if (Object.keys(invalid).length) {
      this.setState({invalid});
      return;
    }


    this.setState({isSubmitting: true, invalid}, () => {
      api_post("/login", {
        username: this.state.username,
        password: this.state.password,
      })
        .then((resp) => {
          if (resp.success) {
            this.props.handleSignInSuccess(resp);
          } else {
            Alert(i18next.t('Errors.LoginFailed'));
            this.setState({isSubmitting: false});
          }
        })
        .catch((resp) => {
          handleError(resp);
          this.setState({isSubmitting: false});
      });
    });
    
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <div className={this.state.isSubmitting ? 'form-disabled' : ''}>

            <div className="input-group">
              <input 
                type="text"
                className={this.state.invalid.username ? 'invalid' : ''}
                name="username"
                disabled={this.state.isSubmitting ? true : false}
                onChange={this.onChange}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    this.submitForm();
                    return;
                  }
                }}
                placeholder={t("Login.Username")}
                />
            </div>
            
            <div className="input-group mb-small">
              <input 
                type="password"
                className={this.state.invalid.password ? 'invalid' : ''}
                name="password"
                disabled={this.state.isSubmitting ? true : false}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    this.submitForm();
                    return;
                  }
                }}
                onChange={this.onChange}
                placeholder={t("Login.Password")}
                />
            </div>
            
            {
              this.state.isSubmitting
              ? (
                <div className="d-flex justify-content-center mt-4 mb-4"><Loading>{t('Login.LoggingIn')}</Loading></div> 
              )
              : (
                <div className="text-center">
                  <button 
                    className="btn primary-btn" 
                    disabled={this.state.isSubmitting?true:false}
                    onClick={this.submitForm}
                  >{t("Login.LogIn")}</button>
                </div>
              )
            }
            

          </div>
        )}
      </Translation>
    );
  }
}
