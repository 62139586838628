import React from "react";
import { Password } from "../components/settings/Password.js";
import { Container } from "../components/Components.js";
import { TopContent } from "../components/TopContent.js";
import { Translation } from "react-i18next";
import { NavTop } from "../components/Nav.js";
import Breadcrumbs from "../components/Breadcrumb.js";

export const PagePassword = () => {
  return (
    <Translation>
      {(t) => (
        <>
          <NavTop />
          <Breadcrumbs/>
          <TopContent h2={t("Settings.PasswordHeadline")} />
          <Container>
            <Password />
          </Container>
        </>
      )}
    </Translation>
  );
};
