import React from 'react';
import { Translation } from 'react-i18next';
import myacticConfig from '../../config/myactic.json';

const PointsRow = (props) => {
  let description = props.description.replace('%POINTS%', props.defPoints);
  return (
    <li>
      <div className="points-row">
        <div className="points-table-dot">
          <div className={'dot ' + props.colorClass} />
        </div>
        <div className="points-table-name">
          <span className="body-text-regular color-black">{props.name}</span>
          <span className="body-text-regular color-rock">{description}</span>
        </div>
        <div className="points-table-nr text-end">
          <span className="d-flex justify-content-end align-items-center mb-2">
            <p className="h4 m-0 pe-1">{props.points}</p>
            <p className="body-text m-0">p</p>
          </span>
          <span className="body-text-small color-rock">
            {props.count} {props.t('MyActic.st')}
          </span>
        </div>
      </div>
    </li>
  );
};

export const MyActicPoints = (props) => {
  if (!props.myactic) {
    return null;
  }

  let myactic = props.myactic;
  return (
    <Translation>
      {(t) => (
        <ul className="list big points-table">
          <PointsRow
            t={t}
            colorClass="background-myactic-blue"
            name={t('MyActic.NamePointsBySubscription')}
            description={t('MyActic.DescriptionPointsBySubscription')}
            count={myactic.loyaltyPointsBySubscriptionCount}
            points={myactic.loyaltyPointsBySubscription}
            defPoints={myacticConfig.loyalty_points_subscription}
          />

          <PointsRow
            t={t}
            colorClass="background-myactic-green"
            name={t('MyActic.NamePointsByClipcards')}
            description={t('MyActic.DescriptionPointsByClipcards')}
            count={myactic.loyaltyPointsByClipcardsCount}
            points={myactic.loyaltyPointsByClipcards}
            defPoints={myacticConfig.loyalty_points_clipcards}
          />
          <PointsRow
            t={t}
            colorClass="background-myactic-pink"
            name={t('MyActic.NamePointsByParticipations')}
            description={t('MyActic.DescriptionPointsByParticipations')}
            count={myactic.loyaltyPointsByParticipationsCount}
            points={myactic.loyaltyPointsByParticipations}
            defPoints={myacticConfig.loyalty_points_participations}
          />

          <PointsRow
            t={t}
            colorClass="background-myactic-turquoise"
            name={t('MyActic.NamePointsByCheckins')}
            description={t('MyActic.DescriptionPointsByCheckins')}
            count={myactic.loyaltyPointsByCheckinsCount}
            points={myactic.loyaltyPointsByCheckins}
            defPoints={myacticConfig.loyalty_points_checkins}
          />

          <PointsRow
            t={t}
            colorClass="background-myactic-yellow"
            name={t('MyActic.NamePointsByOther')}
            description={t('MyActic.DescriptionPointsByOther')}
            count={myactic.loyaltyPointsByOtherCount}
            points={myactic.loyaltyPointsByOther}
            defPoints={myacticConfig.loyalty_points_other}
          />

          <li className="points-total">
            <div>
              <div>
                <span className="body-text color-black">Total</span>
              </div>
            </div>
            <div>
              <span className="d-flex justify-content-end align-items-center mb-2">
                <p className="h4 color-black m-0 pe-1">{myactic.loyaltyPoints}</p>
                <p className="body-text-small color-black m-0">p</p>
              </span>
            </div>
          </li>
        </ul>
      )}
    </Translation>
  );
};
