import React, { useContext, useState } from 'react';
//import { Link } from "react-router-dom";
import { Translation } from 'react-i18next';
import i18next from 'i18next';
import PersonContext from '../../../PersonContext.js';
import { api_post, handleError } from '../../../API.js';
import { Alert } from '../../Alert.js';

export const Max = (props) => {
  let context = useContext(PersonContext);

  let apiUrl = '/persons/' + context.person.personId.externalId + '/myactic/benefits/max';
  let formDataDefault = {
    start: new Date().toISOString().split('T')[0]
  };
  let successMessage = { headline: i18next.t('MyActic.Max'), message: i18next.t('MyActic.MaxWillBeUpgraded') };
  let now = Math.floor(Date.now() / 1000);


  let [ isSubmitting, setSubmitting ] = useState(false);
  let [ invalid, setInvalid ] = useState({});
  let [ data, setData ] = useState(formDataDefault);
  let [ used, setUsed ] = useState(false);
  //let [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const onChange = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
      return;
    }

    if (typeof invalid[e.target.name] !== 'undefined') {
      setInvalid({ ...invalid, [e.target.name]: false });
    }

    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    if (typeof e !== 'undefined' && typeof e.preventDefault !== 'undefined') {
      e.preventDefault();
    }

    let invalid = {};
    for (let keyName of Object.keys(data)) {
      if (typeof data[keyName] === 'undefined' || data[keyName] === '') {
        invalid[keyName] = true;
      }
    }

    let start = new Date(data['start']);
    let first_allowed = new Date();
    first_allowed.setHours(0,0,0,0);

    //console.log("start", start);
    //console.log("slut", first_allowed);

    if (start < first_allowed) {
      Alert(i18next.t('Errors.MyActicMax.to-early'));
      return false;
    }

    if (Object.keys(invalid).length) {
      setInvalid(invalid);
      return false;
    } else {
      setInvalid(false);
    }

    setSubmitting(true);
    api_post(apiUrl, data)
      .then((resp) => {
        setSubmitting(false);
        if (parseInt(resp.result) === 1) {
            setUsed(true);
            setData(formDataDefault);
            Alert(successMessage);
            //setShowSuccessMessage(true);
            if (typeof resp.myactic !== 'undefined') {
              context.setMyActic(resp.myactic);
            }
            if (resp.subscriptions) {
              context.setSubscriptions(resp.subscriptions);
            }
        } else {
          if (typeof resp.reason !== 'undefined') {
            handleError({error: "MyActicMax." + resp.reason + (typeof resp.param!=='undefined' && resp.param ? '-'+resp.param : '')});
        }
        }
      })
      .catch((err) => {
        handleError(err);
        setSubmitting(false);
      });
  };

  
  return (
    <Translation>
      {(t) => (
        <div className={"benefit-wrapper " + (isSubmitting ? 'form-disabled' : '')}>
          <p>{t('MyActic.MaxText', { LevelName: context.myactic.loyaltyLevelName })}</p>
          {
            context.myactic.loyaltyUpgradeMaxUsed &&
              now <= context.myactic.loyaltyUpgradeMaxUsedAt &&
              now >= context.myactic.loyaltyUpgradeMaxUsedEnds &&
              now <= context.myactic.loyaltyUpgradeMaxUsedEnds
              ? <p>{t('MyActic.MaxUpgradedTo', {date: new Date(context.myactic.loyaltyUpgradeMaxUsedEnds).toISOString().split("T")[0]})}</p> : null
          }
          {
            context.myactic.loyaltyUpgradeMaxUsed > 0 && now < context.myactic.loyaltyUpgradeMaxUsedAt
            ? <p>{t('MyActic.MaxDateSpan', {startdate: new Date(context.myactic.loyaltyUpgradeMaxUsedAt).toISOString().split("T")[0], enddate: new Date(context.myactic.loyaltyUpgradeMaxUsedEnds).toISOString().split("T")[0]})}</p> : null
          }
          {
            (context.myactic.loyaltyUpgradeMaxUsedEnds > 0 && context.myactic.loyaltyUpgradeMaxUsedEnds < now) || props.inactive
            ? <p>{t('MyActic.MaxSorry')}</p> : null
          }

          {used || props.inactive ? null : (
            <div>
              <label className="h4 mb-3">{t('MyActic.MaxStart')}</label>
              <div className="input-group">
                <input
                  type="date"
                  className={invalid.start ? 'invalid' : ''}
                  name="start"
                  value={data.start}
                  disabled={isSubmitting ? true : false}
                  onChange={onChange}
                  placeholder={t('MyActic.MaxStart')}
                />
              </div>
              <div className="text-center mb-small mt-small">
                <button className="btn secondary-btn color-secondary grow-small" onClick={handleSubmit} disabled={isSubmitting ? true : false}>
                  {t('MyActic.MaxUpgrade')}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </Translation>
  );
};
