import React, { useState, useContext } from 'react';
import { Translation } from 'react-i18next';
import i18next from 'i18next';
import { api_post, api_delete, handleError } from '../../API.js';
import PersonContext from '../../PersonContext.js';
import { Loading, DateFormat } from '../Components.js';
import { Alert } from '../Alert.js';
import { CardLink } from '../Components.js';

export const StopSubscription = (props) => {
  let context = useContext(PersonContext);
  let [ isSubmitting, setSubmitting] = useState(false);

  //useEffect(() => {
    // Fetch data
  //}, []); // eslint-disable-line

  //let now = new Date();
  //let lastDayNextMonth = new Date(now.getFullYear(), now.getMonth()+2, 0);
  //let calculatedEndDate = lastDayNextMonth;

  const stopSubscription = (subscription) => { 
    if (isSubmitting) {
      return false;
    }
    setSubmitting(true);
    api_post('/persons/' + context.person.personId.externalId + '/subscriptions/'+subscription.subscriptionId.center+'p'+subscription.subscriptionId.id+'/stop', {})
      .then((resp) => {
        setSubmitting(false);
        Alert(i18next.t("Settings.StopSuccessMessage", {lastTrainingDay : resp.stopinfo.lastTrainingDay}))
        context.setSubscriptions(resp.subscriptions);
      })
      .catch((resp) => {
        setSubmitting(false);
        handleError(resp);
      });


  }
  const cancelStopSubscription = (subscription) => { 
    if (isSubmitting) {
      return false;
    }
    //console.log("cancelStopSubscription", subscription)
    setSubmitting(true);
    
    api_delete('/persons/' + context.person.personId.externalId + '/subscriptions/'+subscription.subscriptionId.center+'p'+subscription.subscriptionId.id+'/stop')
      .then((resp) => {
        setSubmitting(false);
        Alert(i18next.t("Settings.CancelStopSuccessMessage"))
        context.setSubscriptions(resp.subscriptions);
      })
      .catch((resp) => {
        setSubmitting(false);
        handleError(resp);
      });


  }

  //console.log("subscriptions", context.subscriptions);
  return (
    <Translation>
      {(t) => (
        !context.subscriptions.length  
        ? <div className="d-flex justify-content-center mt-4 mb-4"><p>{t('Settings.StopNoSubscription')}</p></div> 
        : (
          <React.Fragment>
            
          <ul className="list list-medium">
            {
              context.subscriptions.map((subscription, index) => {
               return <StopSubscriptionItem 
                key={index} 
                isSubmitting={isSubmitting} 
                subscription={subscription} 
                stopSubscription={stopSubscription} 
                cancelStopSubscription={cancelStopSubscription} 
                />
              })
            }
          </ul>

          <p className="body-text-small mt-3 mb-3">{t("Settings.StopInfo")}</p>
          </React.Fragment>
        )
      )}
    </Translation>
    );
};

const StopSubscriptionItem = (props) => {
  //let context = useContext(PersonContext);
  let [isOpen, setIsOpen] = useState(false);
  let subscription = props.subscription;
  let subscriptionBindingEnd = (typeof subscription.bindingEndDate !== 'undefined' && subscription.bindingEndDate!=='') ? new Date(subscription.bindingEndDate) : false;
  let subscriptionEndDate = (typeof subscription.endDate !== 'undefined' && subscription.endDate!=='') ? new Date(subscription.endDate) : false;

  return (
    props.isSubmitting
    ? <div className="d-flex justify-content-center mt-4 mb-4"><Loading /></div>
    : (
      <li>
        <div className='align-items-start d-flex flex-column' onClick={() => { setIsOpen(!isOpen) }}>
          <div className='mb-3'>
            <span className="body-text">{subscription.product.name}</span>
          </div>
            {
              subscriptionEndDate
              ? (
                <div className="text-end d-flex flex-row justify-content-between w-100">
                  <span className="body-text-small color-rock">{i18next.t("Settings.StopLastDay")}</span>
                  <span className="body-text-small">{DateFormat(subscriptionEndDate)}</span>
                </div>
              ) : null
            }
            {
              !subscriptionEndDate && subscriptionBindingEnd
              ? (
                <div className="text-end d-flex flex-row justify-content-between w-100">
                  <span className="body-text-small color-rock">{i18next.t("Settings.StopBindingTo")}</span>
                  <span className="body-text-small">{DateFormat(subscriptionBindingEnd)}</span>
                </div>
              ) : null
            }
      
        </div>
        <div className="more-info mb-3 mt-5">
              {
                (subscription.product.type === 'EFT' || subscription.product.type === 'RECURRING_CLIPCARD')
                ? (
                  subscriptionEndDate 
                  ? (
                    <div>
                      <button className="btn secondary-btn color-secondary mt-5 mb-3 col-12 col-lg-6 mx-auto" onClick={(e) => {
                        e.preventDefault();
                        props.cancelStopSubscription(subscription);
                      }}>{i18next.t("Settings.StopUndoStop")}
                      </button>
                    </div>
                    )
                  : (
                    <div>
                      {
                        subscriptionBindingEnd && subscriptionBindingEnd > new Date()
                        ? (
                          <p className="body-text">{i18next.t("Settings.StopUndoStopWithBindingInfo", { enddate: DateFormat(subscription.possibleStopDate) })}</p>
                        )
                        : (
                          <p className="body-text">{i18next.t("Settings.StopUndoStopWithoutBindingInfo", { enddate: DateFormat(subscription.possibleStopDate) })}</p>
                        )
                      }
                      <div className="text-center mb-small mt-5">
                        <button className="btn secondary-btn color-secondary" onClick={(e) => {
                          e.preventDefault();
                          props.stopSubscription(subscription);
                        }}>{i18next.t("Settings.StopStop")}</button>
                      </div>
                    </div>
                    )
                )
                : (
                  <p className="body-text">{i18next.t("Settings.StopContactSupport")}</p>
                )
              }
            </div>
      </li>
    )
  );
}