import React from 'react';
import { Autogiro } from '../components/settings/Autogiro.js';
import { Container } from '../components/Components.js';
import { TopContent } from '../components/TopContent.js';
import { Translation } from 'react-i18next';
import { NavTop } from '../components/Nav.js';
import Breadcrumbs from '../components/Breadcrumb.js';

export const PageAuto = (props) => {
  return (
    <Translation>
      {(t) => (
        <>
          <NavTop />
          <Breadcrumbs/>
          <TopContent h2={t("Settings.AutogiroHeadline")} />
          <Container>
            <Autogiro {...props} />
          </Container>
        </>
      )}
    </Translation>
  );
};
