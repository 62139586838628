import React from "react";
import { Translation } from "react-i18next";
import PersonContext from "../../PersonContext.js";
import { Loading } from "../Components.js";
import { MyActicBar } from "./MyActicBar.js";
import { MyActicPoints } from "./MyActicPoints.js";

export class MyActic extends React.Component {
  static contextType = PersonContext;

  render() {
    return (
      <Translation>
        {(t) => {
        return (
        <React.Fragment>

            <div className="myactic-points">

              {this.props.loadingMyActic ? (
                <div className="d-flex justify-content-center mt-5"><Loading>{t("MyActic.LoadingMyActic")}</Loading></div>
              ) : (
                <div>
                  <MyActicBar myactic={this.context.myactic} />
                  <h4 className="d-none">{t("MyActic.Details")}</h4>
                  <MyActicPoints myactic={this.context.myactic} />
                </div>
              )}

            </div>
          </React.Fragment>
          )
        }
        }
      </Translation>
    );
  }
}